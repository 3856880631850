export const ERRORS = "ERRORS";
export const CLEAR_RESPONSE = "CLEAR_RESPONSE";

export const CREATE_FEE_REQUEST = "CREATE_FEE_REQUEST";
export const CREATE_FEE_SUCCESS = "CREATE_FEE_SUCCESS";
export const CREATE_FEE_FAILURE = "CREATE_FEE_FAILURE";

export const UPDATE_FEE_REQUEST = "UPDATE_FEE_REQUEST";
export const UPDATE_FEE_SUCCESS = "UPDATE_FEE_SUCCESS";
export const UPDATE_FEE_FAILURE = "UPDATE_FEE_FAILURE";

export const REMOVE_FEE_REQUEST = "REMOVE_FEE_REQUEST";
export const REMOVE_FEE_SUCCESS = "REMOVE_FEE_SUCCESS";
export const REMOVE_FEE_FAILURE = "REMOVE_FEE_FAILURE";

export const TOGGLE_FEE_SUCCESS = "TOGGLE_FEE_SUCCESS";
export const TOGGLE_FEE_REQUEST = "TOGGLE_FEE_REQUEST";
export const TOGGLE_FEE_FAILURE = "TOGGLE_FEE_FAILURE";

export const GET_FEE_SUCCESS = "GET_FEE_SUCCESS";
export const GET_FEE_REQUEST = "GET_FEE_REQUEST";
export const GET_FEE_FAILURE = "GET_FEE_FAILURE";
export const GET_COUNTRY_FEE_SUCCESS = "GET_COUNTRY_FEE_SUCCESS";
export const GET_COUNTRY_FEE_REQUEST = "GET_COUNTRY_FEE_REQUEST";
export const GET_COUNTRY_FEE_FAILURE = "GET_COUNTRY_FEE_FAILURE";
export const UPDATE_COUNTRY_FEE_SUCCESS = "UPDATE_COUNTRY_FEE_SUCCESS";
export const UPDATE_COUNTRY_FEE_REQUEST = "UPDATE_COUNTRY_FEE_REQUEST";
export const UPDATE_COUNTRY_FEE_FAILURE = "UPDATE_COUNTRY_FEE_FAILURE";

export const DELETE_COUNTRY_FEE_SUCCESS = "DELETE_COUNTRY_FEE_SUCCESS";
export const DELETE_COUNTRY_FEE_REQUEST = "DELETE_COUNTRY_FEE_REQUEST";
export const DELETE_COUNTRY_FEE_FAILURE = "DELETE_COUNTRY_FEE_FAILURE";
