import axios from "axios";
import { apiUrl } from "../../config";

export const create = (data) =>
	axios
		.post(`${apiUrl}/admin/store/shipping/method/`, data)
		.then((response) => response)
		.catch((err) => err.response);

export const update = (data, _id) =>
	axios
		.put(`${apiUrl}/admin/store/shipping/method/${data._id}`, data)
		.then((response) => response)
		.catch((err) => err.response);

export const toggle = (data) =>
	axios
		.patch(`${apiUrl}/admin/store/shipping/method/toggle/${data._id}`)
		.then((response) => response)
		.catch((err) => err.response);

export const remove = (data) =>
	axios
		.delete(`${apiUrl}/admin/store/fee/${data._id}`)
		.then((response) => response)
		.catch((err) => err.response);

export const getFee = (data) =>
	axios
		.get(`${apiUrl}/admin/store/fee`)
		.then((response) => response)
		.catch((err) => err.response);

export const getCountryFee = (data) =>
	axios
		.get(`${apiUrl}/admin/store/fee/country/all`)
		.then((response) => response)
		.catch((err) => err.response);

export const updateCountryFeeService = (data) =>
	axios
		.put(`${apiUrl}/admin/store/fee/country/${data._id}`, data)
		.then((response) => response)
		.catch((err) => err.response);

export const deleteCountryFeeService = (data) =>
	axios
		.delete(`${apiUrl}/admin/store/fee/country/${data._id}`)
		.then((response) => response)
		.catch((err) => err.response);
