import React, { useEffect, useState, useRef, useMemo } from "react";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Helmet } from "react-helmet";
import {
	Card,
	CardBody,
	Col,
	Container,
	Row,
	Modal,
	ModalHeader,
	ModalBody,
	FormFeedback,
	Form,
	Label,
	Input,
	CardTitle,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Dropzone from "react-dropzone";
//redux
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import HasAnyPermission from "../../common/Permission";
import hasPermission from "../../common/HasPermission";
import ReactDataTable from "../../common/ReactDataTable";
import isEmpty from "../../utils/isEmpty";
import { apiUrl } from "../../config";
import {
	create,
	update,
	remove,
	toggle,
	clearResponse,
} from "../../store/productOption/actions";
import Swal from "sweetalert2";

const ProductOption = (props) => {
	const dispatch = useDispatch();
	const refreshTableData = useRef(null);
	const { response, auth } = useSelector((state) => ({
		response: state.productOption.response,
		auth: state.auth,
	}));

	const [isOpenAddEditModal, toggleAddEditModal] = useState(false);
	const [details, handleformData] = useState({
		name: "",
		options: [],
	});

	useEffect(() => {
		if (response && response.code === "200") {
			refreshTableData.current();
			if (isOpenAddEditModal) toggleAddEditModal(!isOpenAddEditModal);
			toast.success(response.msg, {
				onOpen: () => {
					dispatch(clearResponse());
				},
			});
		} else if (response && response?.msg) {
			refreshTableData.current();
			toast.error(response.msg, {
				onOpen: () => {
					dispatch(clearResponse());
				},
			});
		}
	}, [dispatch, response]);

	const removeItem = (id) => {
		if (!isEmpty(id)) {
			Swal.fire({
				title: "Are you sure?",
				text: "Do you really want to delete?",
				icon: "warning",
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				cancelButtonColor: "#d33",
				confirmButtonText: "Confirm",
			}).then((result) => {
				if (result.value) {
					dispatch(remove(id));
				}
			});
		}
	};

	const toggleItem = (id) => {
		if (!isEmpty(id)) {
			dispatch(toggle(id));
		}
	};

	let validationSchema = {
		name: Yup.string().required("Please enter name."),
	};

	const useFormikOptions = {
		enableReinitialize: true,
		initialValues: {
			name: details && details.name ? details.name : "",
			options: details && details.options ? details.options : "",
		},
		validationSchema: Yup.object(validationSchema),
		onSubmit: (values) => {
			if (details._id) {
				dispatch(update({ ...details, ...values }));
			} else {
				dispatch(create({ ...details, ...values }));
			}
		},
	};

	const validation = useFormik(useFormikOptions);

	const handleAddEditModal = (data = null) => {
		if (!isEmpty(data) && data?._id) {
			handleformData({ ...data, options: data.values });
		} else {
			handleformData({
				name: "",
				options: [],
			});
			validation.resetForm();
		}
		toggleAddEditModal(!isOpenAddEditModal);
	};

	const handleChange = (e) => {
		details[e.target.name] = e.target.value;
		handleformData({ ...details });
	};

	const handleChangeData = (name, index) => (e) => {
		let detailData = [...details.options];
		detailData[index][name] = e.target.value;
		handleformData({
			...details,
			options: detailData,
		});
	};

	const addField = (e) => {
		e.preventDefault();
		handleformData({
			...details,
			options: [
				...details.options,
				{
					name: "",
					active: true,
				},
			],
		});
	};

	const toggleActiveInactive = (e, index) => {
		e.preventDefault();
		details.options[index].active = !details.options[index].active;
		handleformData({
			...details,
			options: [...details.options],
		});
	};

	const removeField = (e, index) => {
		e.preventDefault();
		details.options.splice(index, 1);
		handleformData({
			...details,
			options: [...details.options],
		});
	};

	const columns = () => [
		{
			label: "Name",
			name: "name",
			options: {
				filter: false,
				sort: false,
			},
		},

		{
			label: "Slug",
			name: "slug",
			options: {
				filter: false,
				sort: false,
			},
		},

		{
			label: "Options (value)",
			name: "values",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (data) => {
					return data.length
						? data.map((item) => item.name)?.join(", ")
						: "";
				},
			},
		},

		{
			label: "Actions",
			name: "action",
			options: {
				filter: false,
				sort: false,
				empty: true,
				download: false,
				display: hasPermission(
					["product option update", "product option delete", "product option view"],
					auth.currentUserRolePermissions
				),
				viewColumns: hasPermission(
					["product option update", "product option delete", "product option view"],
					auth.currentUserRolePermissions
				),
				customBodyRender: (data) => {
					return (
						<div className="text-center">
							<HasAnyPermission
								permission={["product option update", "product option view"]}
							>
								<button
									onClick={(e) => handleAddEditModal(data)}
									type="button"
									className="btn btn-soft-primary waves-effect waves-light"
								>
									<i className="bx bx-edit-alt font-size-16 align-middle"></i>
								</button>
							</HasAnyPermission>
							&nbsp;
							{/* <HasAnyPermission
								permission={["product option delete"]}
							>
								<button
									onClick={(e) => removeItem(data._id)}
									type="button"
									className="btn btn-soft-danger waves-effect waves-light"
								>
									<i className="bx bx-trash font-size-16 align-middle"></i>
								</button>
							</HasAnyPermission> */}
						</div>
					);
				},
			},
		},
	];

	const resultFormatter = (result) => {
		return result.docs.map((item) => {
			return {
				...item,
				active: item,
				action: item,
			};
		});
	};

	const couldHaveAddUpdatePermission = () => {
		const isUpdatePermission = hasPermission(
			["product option update"],
			auth.currentUserRolePermissions
		);
		const isAddPermission = hasPermission(
			["product option add"],
			auth.currentUserRolePermissions
		);
		if (isUpdatePermission && isAddPermission) return true;
		else if (isUpdatePermission && !isEmpty(details._id)) return true;
		else if (isAddPermission && isEmpty(details._id)) return true;
		else return false;
	};

	return (
		<React.Fragment>
			<div className="page-content">
				<Helmet>
					<title>LYOTECHLABS | Product Option</title>
				</Helmet>
				<Container fluid>
					{/* Render Breadcrumbs */}
					<Breadcrumbs title="Product Option" breadcrumbItem="List" />
					<Row>
						<Col lg="12">
							<Card>
								<CardBody>
									<Row>
										<Col xl="12">
											<div className="table-rep-plugin">
												<div className="table-responsive">
													<HasAnyPermission
														permission={[
															"product option list",
														]}
													>
														<ReactDataTable
															url={`${apiUrl}/admin/store/product-option/pagination`}
															columns={columns()}
															resultFormatter={
																resultFormatter
															}
															setRefresh={
																refreshTableData
															}
															disableFilterIcon={
																true
															}
															disableSearchIcon={
																true
															}
															origin={
																<div className="row">
																	<div className="col-auto h4">
																		Product
																		Option
																		&nbsp;
																		<HasAnyPermission
																			permission={[
																				"product option add",
																			]}
																		>
																			<button
																				onClick={() => {
																					handleAddEditModal();
																				}}
																				type="button"
																				className="btn btn-primary waves-effect waves-light"
																			>
																				<i className="bx bx-plus-medical font-size-16 align-middle"></i>
																			</button>
																		</HasAnyPermission>
																	</div>
																</div>
															}
															rowsPerPage={10}
														/>
													</HasAnyPermission>

													<Modal
														isOpen={
															isOpenAddEditModal
														}
														toggle={
															handleAddEditModal
														}
														size="lg"
														centered={true}
													>
														<ModalHeader
															toggle={
																handleAddEditModal
															}
															tag="h4"
														>
															{details?._id
																? "Edit Option"
																: "Add Option"}
														</ModalHeader>
														<ModalBody>
															<fieldset
																disabled={
																	!couldHaveAddUpdatePermission()
																}
															>
																<Form
																	onSubmit={(
																		e
																	) => {
																		e.preventDefault();
																		validation.handleSubmit();
																		return false;
																	}}
																>
																	<Row>
																		<Col
																			xs={
																				12
																			}
																		>
																			<div className="mb-3">
																				<Label className="form-label">
																					Name
																				</Label>
																				<Input
																					name="name"
																					type="text"
																					onChange={
																						handleChange
																					}
																					value={
																						details.name ||
																						""
																					}
																				/>
																			</div>
																		</Col>
																	</Row>

																	<>
																		<Row>
																			<Col
																				xs={
																					6
																				}
																			>
																				<div>
																					<div className="mb-3">
																						<div className="col-auto">
																							{`Options`}
																							<button
																								type="button"
																								className="btn btn-soft-primary waves-effect waves-light m-1"
																								onClick={
																									addField
																								}
																							>
																								<i className="bx bx-plus font-size-16 align-middle"></i>
																							</button>
																						</div>
																					</div>
																				</div>
																			</Col>
																		</Row>
																		<Row>
																			{details?.options &&
																				details.options?.map(
																					(
																						detail,
																						index
																					) => (
																						<Col
																							xs={
																								6
																							}
																							key={`data${index}`}
																						>
																							<Card>
																								<CardBody>
																									<div className="d-flex flex-wrap align-items-center mb-4">
																										<h5 className="card-title me-2">
																											{/* {`${
																													index +
																													1
																												}   `} */}
																											<button
																												type="button"
																												className="btn btn-soft-primary waves-effect waves-light"
																												onClick={(
																													e
																												) =>
																													removeField(
																														e,
																														index
																													)
																												}
																											>
																												<i className="bx bx-minus font-size-16 align-middle"></i>
																											</button>
																										</h5>

																										<div className="ms-auto">
																											<div className="mb-3">
																												<select
																													className="form-control"
																													name="active"
																													value={
																														detail.active
																															? true
																															: false
																													}
																													onChange={(
																														e
																													) => {
																														toggleActiveInactive(
																															e,
																															index
																														);
																													}}
																												>
																													<option
																														value={
																															true
																														}
																													>
																														Active
																													</option>
																													<option
																														value={
																															false
																														}
																													>
																														Inactive
																													</option>
																												</select>
																											</div>
																										</div>
																									</div>
																									<div>
																										<div className="mb-3">
																											<Input
																												placeholder="Option Name"
																												name="name"
																												type="text"
																												value={
																													detail.name
																												}
																												onChange={handleChangeData(
																													"name",
																													index
																												)}
																												required
																											/>
																										</div>
																									</div>
																								</CardBody>
																							</Card>
																						</Col>
																					)
																				)}
																		</Row>
																	</>

																	<Row>
																		<Col>
																			<div className="text-end mt-3">
																				<button
																					type="submit"
																					className="btn btn-success save-user"
																				>
																					Save
																				</button>
																			</div>
																		</Col>
																	</Row>
																</Form>
															</fieldset>
														</ModalBody>
													</Modal>
												</div>
											</div>
										</Col>
									</Row>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default ProductOption;
