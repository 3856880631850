import { all, fork } from "redux-saga/effects";

//public
import AuthSaga from "./auth/saga";
import LayoutSaga from "./layout/saga";
import adminSaga from "./admin/saga";
import dashboardSaga from "./dashboard/saga";
import userSaga from "./user/saga";
import softwarePackageSaga from "./softwarePackage/saga";
import productSaga from "./product/saga";
import orderSaga from "./order/saga";
import voucherCodePackageSaga from "./voucherCodePackage/saga";
import productOptionSaga from "./productOption/saga";
import shippingSaga from "./shipping/saga";
import taxSaga from "./tax/saga";
import feeSaga from "./fee/saga";

export default function* rootSaga() {
	yield all([
		fork(AuthSaga),
		fork(productSaga),
		fork(orderSaga),
		fork(LayoutSaga),
		fork(adminSaga),
		fork(dashboardSaga),
		fork(userSaga),
		fork(softwarePackageSaga),
		fork(voucherCodePackageSaga),
		fork(productOptionSaga),
		fork(shippingSaga),
		fork(taxSaga),
		fork(feeSaga),
	]);
}
