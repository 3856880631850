import React, { useEffect, useState, useRef, useMemo } from "react";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Helmet } from "react-helmet";
import {
	Card,
	CardBody,
	Col,
	Container,
	Row,
	Modal,
	ModalHeader,
	ModalBody,
	FormFeedback,
	Form,
	Label,
	Input,
} from "reactstrap";
//redux
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import HasAnyPermission from "../../common/Permission";
import hasPermission from "../../common/HasPermission";
import ReactDataTable from "../../common/ReactDataTable";
import isEmpty from "../../utils/isEmpty";
import { apiUrl } from "../../config";
import Swal from "sweetalert2";
import moment from "moment";
import Flatpickr from "react-flatpickr";
import {
	addressTypes,
	performedActionsOptions,
} from "../../common/FilterOptions";

const AddressLog = (props) => {
	const dispatch = useDispatch();
	const refreshTableData = useRef(null);
	const { auth } = useSelector((state) => ({
		auth: state.auth,
	}));

	const redirectUserDetails = (data) => {};

	const columns = () => [
		{
			label: "Order ID",
			name: "order",
			options: {
				filter: false,
				sort: false,
			},
		},
		{
			label: "WP Order Id",
			name: "wpOrderId",
			options: {
				filter: false,
				sort: false,
			},
		},
		{
			label: "Customer Email",
			name: "customerEmail",
			options: {
				filter: false,
				sort: false,
			},
		},
		{
			label: "Address Type",
			name: "type",
			options: {
				sort: false,
				customBodyRender: (type) => {
					return (
						<>
							<span className="text-capitalize">{type}</span>
						</>
					);
				},
				customFilterListOptions: {
					render: (v) =>
						v.map((l) => l.charAt(0).toUpperCase() + l.slice(1)),
				},
				filterType: "custom",
				filterOptions: {
					names: [],
					display: (filterList, onChange, index, column) => {
						return (
							<div className="auto">
								{/* <label
									htmlFor="currency-transaction"
									className="form-label font-size-13 text-muted"
								></label> */}
								<select
									value={filterList[index][0] || ""}
									onChange={(event) => {
										filterList[index][0] =
											event.target.value;
										onChange(
											filterList[index],
											index,
											column
										);
									}}
									className="form-control"
									name="currency-transaction"
								>
									<option disabled value="">
										Address Type
									</option>
									{Object.entries(addressTypes).map(
										([key, value]) => (
											<option key={key} value={key}>
												{value}
											</option>
										)
									)}
								</select>
							</div>
						);
					},
				},
			},
		},
		{
			label: "Updated By",
			name: "createdBy",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (createdBy) => {
					return createdBy ? (
						<>
							<span>{createdBy?.name}</span>
							<br />
							<span>{createdBy?.email}</span>
						</>
					) : (
						<div className="text-center">-</div>
					);
				},
			},
		},
		{
			label: "Old Address",
			name: "oldAddress",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (address) => {
					return address ? (
						<>
							<span>{address?.firstName} </span>
							<span>{address?.lastName}</span>
							<br />
							<span>{address?.address}</span>
							<br />
							<span>{address?.city}</span>, &nbsp;
							<span>
								{address?.country}
								, <br />
								<span>
									{" "}
									Postal Code: &nbsp;
									{address?.postalCode}
								</span>
							</span>
							<br />
							<span>{address?.email}</span>
							<br />
							<span>
								{!address?.countryCode?.includes("+")
									? "+"
									: ""}
								{address?.countryCode} {address?.phone}
							</span>
						</>
					) : (
						<div className="text-center">-</div>
					);
				},
			},
		},
		{
			label: "New Address",
			name: "newAddress",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (address) => {
					return address ? (
						<>
							<span>{address?.firstName} </span>
							<span>{address?.lastName}</span>
							<br />
							<span>{address?.address}</span>
							<br />
							<span>{address?.city}</span>, &nbsp;
							<span>
								{address?.country}
								, <br />
								<span>
									{" "}
									Postal Code: &nbsp;
									{address?.postalCode}
								</span>
							</span>
							<br />
							<span>{address?.email}</span>
							<br />
							<span>
								{!address?.countryCode?.includes("+")
									? "+"
									: ""}
								{address?.countryCode} {address?.phone}
							</span>
						</>
					) : (
						<div className="text-center">-</div>
					);
				},
			},
		},
		{
			label: "Performed Actions",
			name: "performedActions",
			options: {
				customBodyRender: (performedActions) => {
					return (
						<>
							<span>{performedActions?.join(", ")} updated</span>
						</>
					);
				},

				customFilterListOptions: {
					render: (v) =>
						v.map((l) => l.charAt(0).toUpperCase() + l.slice(1)),
				},
				filterType: "custom",
				filterOptions: {
					names: [],
					display: (filterList, onChange, index, column) => {
						return (
							<div className="auto">
								{/* <label
									htmlFor="currency-transaction"
									className="form-label font-size-13 text-muted"
								></label> */}
								<select
									value={filterList[index][0] || ""}
									onChange={(event) => {
										filterList[index][0] =
											event.target.value;
										onChange(
											filterList[index],
											index,
											column
										);
									}}
									className="form-control"
									name="currency-transaction"
								>
									<option disabled value="">
										Performed Action
									</option>
									{Object.entries(
										performedActionsOptions
									).map(([key, value]) => (
										<option key={key} value={key}>
											{value}
										</option>
									))}
								</select>
							</div>
						);
					},
				},
				sort: false,
			},
		},
		{
			label: "Email Actions",
			name: "emailSendActions",
			options: {
				customBodyRender: (emailSendActions) => {
					return (
						<>
							{!isEmpty(emailSendActions) ? (
								<span>
									Email sent for{" "}
									{emailSendActions?.join(", ")} update
								</span>
							) : (
								"-"
							)}
						</>
					);
				},

				filter: false,
				sort: false,
			},
		},
		{
			label: "Date",
			name: "createdAt",
			options: {
				customBodyRender: (date) => {
					return moment(date).format("LLL");
				},
				filterType: "custom",
				customFilterListOptions: {
					render: (v) => {
						if (v?.[0])
							return `From Date : ${moment(v[0]).format(
								"MM/DD/YYYY"
							)} - To Date : ${moment(v[1]).format(
								"MM/DD/YYYY"
							)}`;
						return [];
					},
					update: (filterList, filterPos, index) => {
						filterList[index] = [];
						return filterList;
					},
				},
				filterOptions: {
					display: (filterList, onChange, index, column) => {
						return (
							<div className="auto">
								<div className="input-group w-100">
									<Flatpickr
										className="form-control d-block"
										placeholder="Date Range"
										options={{
											mode: "range",
											dateFormat: "m/d/Y",
										}}
										value={
											filterList[index] || [
												new Date(),
												new Date(),
											]
										}
										onChange={(date) => {
											filterList[index] = date;
											onChange(
												filterList[index],
												index,
												column
											);
										}}
									/>
								</div>
							</div>
						);
					},
				},
				sort: false,
			},
		},
	];

	const resultFormatter = (result) => {
		return result.docs.map((item) => {
			return {
				...item,
				order: item.order?.order?._id,
				wpOrderId: item.order?.order?.wpOrderId
					? item.order?.order?.wpOrderId
					: "-",
				customerEmail: item.order?.order?.userId?.email
					? item.order?.order?.userId?.email
					: "-",
				type: item?.type,
				createdBy: item?.createdBy,
				oldAddress:
					item?.type === "shipping"
						? item?.oldShippingAddress
						: item?.oldBillingAddress,
				newAddress:
					item?.type === "shipping"
						? item?.shippingAddress
						: item?.billingAddress,
				emailSendActions: item?.emailSendActions,
				performedActions: item?.actions,
				createdAt: item?.createdAt,
			};
		});
	};
	return (
		<React.Fragment>
			<div className="page-content">
				<Helmet>
					<title>User Address Log | LYOTECHLABS</title>
				</Helmet>
				<Container fluid>
					{/* Render Breadcrumbs */}
					<Breadcrumbs title="Admin" breadcrumbItem="Log" />
					<Row>
						<Col lg="12">
							<Card>
								<CardBody>
									<Row>
										<Col xl="12">
											<div className="table-rep-plugin">
												<div className="table-responsive">
													<HasAnyPermission
														permission={[
															"customer shipping address log list",
														]}
													>
														<ReactDataTable
															url={`${apiUrl}/admin/store/orders/get-shipping-address-change-activity`}
															columns={columns()}
															resultFormatter={
																resultFormatter
															}
															setRefresh={
																refreshTableData
															}
															disableFilterIcon={
																false
															}
															disableSearchIcon={
																false
															}
															disableDownloadIcon={
																true
															}
															origin={
																<div className="row">
																	<div className="col-auto h4">
																		Customer
																		Address
																		& Name
																		change
																		log
																		&nbsp;
																		<HasAnyPermission
																			permission={[
																				"admin add",
																			]}
																		>
																			{/* <button
																				onClick={() => {
																					handleAddEditModal();
																				}}
																				type="button"
																				className="btn btn-primary waves-effect waves-light"
																			>
																				<i className="bx bx-plus-medical font-size-16 align-middle"></i>
																			</button> */}
																		</HasAnyPermission>
																	</div>
																</div>
															}
															rowsPerPage={10}
														/>
													</HasAnyPermission>
												</div>
											</div>
										</Col>
									</Row>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default AddressLog;
