import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
	CREATE_FEE_REQUEST,
	CREATE_FEE_SUCCESS,
	CREATE_FEE_FAILURE,
	UPDATE_FEE_SUCCESS,
	UPDATE_FEE_REQUEST,
	UPDATE_FEE_FAILURE,
	REMOVE_FEE_FAILURE,
	REMOVE_FEE_SUCCESS,
	REMOVE_FEE_REQUEST,
	TOGGLE_FEE_SUCCESS,
	TOGGLE_FEE_REQUEST,
	TOGGLE_FEE_FAILURE,
	GET_FEE_SUCCESS,
	GET_FEE_REQUEST,
	GET_FEE_FAILURE,
	GET_COUNTRY_FEE_SUCCESS,
	GET_COUNTRY_FEE_REQUEST,
	GET_COUNTRY_FEE_FAILURE,
	UPDATE_COUNTRY_FEE_SUCCESS,
	UPDATE_COUNTRY_FEE_REQUEST,
	UPDATE_COUNTRY_FEE_FAILURE,
	DELETE_COUNTRY_FEE_SUCCESS,
	DELETE_COUNTRY_FEE_REQUEST,
	DELETE_COUNTRY_FEE_FAILURE,
} from "./actionTypes";

import {
	create,
	update,
	remove,
	toggle,
	getFee,
	getCountryFee,
	updateCountryFeeService,
	deleteCountryFeeService,
} from "./services";
function* createFee({ payload }) {
	try {
		const response = yield call(create, payload);
		if (response.status === 200) {
			yield put({
				type: CREATE_FEE_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: CREATE_FEE_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: CREATE_FEE_FAILURE,
			payload: response.data,
		});
	}
}

function* updateFee({ payload }) {
	try {
		const response = yield call(update, payload);
		if (response.status === 200) {
			yield put({
				type: UPDATE_FEE_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: UPDATE_FEE_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: UPDATE_FEE_FAILURE,
			payload: {},
		});
	}
}

function* toggleFee({ payload }) {
	try {
		const response = yield call(toggle, payload);
		if (response.status === 200) {
			yield put({
				type: TOGGLE_FEE_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: TOGGLE_FEE_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: TOGGLE_FEE_FAILURE,
			payload: response.data,
		});
	}
}

function* removeFee({ payload }) {
	try {
		const response = yield call(remove, payload);
		if (response.status === 200) {
			yield put({
				type: REMOVE_FEE_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: REMOVE_FEE_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: REMOVE_FEE_FAILURE,
			payload: response.data,
		});
	}
}

function* getAllFee({ payload }) {
	try {
		const response = yield call(getFee, payload);
		if (response.status === 200) {
			yield put({
				type: GET_FEE_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: GET_FEE_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: GET_FEE_FAILURE,
			payload: {},
		});
	}
}

function* getAllCountryFee({ payload }) {
	try {
		const response = yield call(getCountryFee, payload);
		if (response.status === 200) {
			yield put({
				type: GET_COUNTRY_FEE_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: GET_COUNTRY_FEE_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: GET_COUNTRY_FEE_FAILURE,
			payload: {},
		});
	}
}
function* updateCountryFee({ payload }) {
	try {
		const response = yield call(updateCountryFeeService, payload);
		if (response.status === 200) {
			yield put({
				type: UPDATE_COUNTRY_FEE_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: UPDATE_COUNTRY_FEE_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: UPDATE_COUNTRY_FEE_FAILURE,
			payload: {},
		});
	}
}
function* deleteCountryFee({ payload }) {
	try {
		const response = yield call(deleteCountryFeeService, payload);
		if (response.status === 200) {
			yield put({
				type: DELETE_COUNTRY_FEE_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: DELETE_COUNTRY_FEE_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: DELETE_COUNTRY_FEE_FAILURE,
			payload: {},
		});
	}
}

function* saga() {
	yield takeEvery(CREATE_FEE_REQUEST, createFee);
	yield takeEvery(UPDATE_FEE_REQUEST, updateFee);
	yield takeEvery(REMOVE_FEE_REQUEST, removeFee);
	yield takeEvery(TOGGLE_FEE_REQUEST, toggleFee);
	yield takeEvery(GET_FEE_REQUEST, getAllFee);
	yield takeEvery(GET_COUNTRY_FEE_REQUEST, getAllCountryFee);
	yield takeEvery(UPDATE_COUNTRY_FEE_REQUEST, updateCountryFee);
	yield takeEvery(DELETE_COUNTRY_FEE_REQUEST, deleteCountryFee);
}

export default saga;
