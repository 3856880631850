import React, {
	useEffect,
	useState,
	useCallback,
	useRef,
	useMemo,
} from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import CountUp from "react-countup";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import {
	getProduct,
	// getTotalCartOrderStatuswise,
	getTotalCartOrderStatuswise as dispatchedTotalOrder,
} from "../../store/dashboard/actions";
import { useSelector, useDispatch } from "react-redux";
import isEmpty from "../../utils/isEmpty";

const TotalOrderStatusWiseCloudx = ({
	formatValue,
	getStringToDateRange,
	getTotalYear,
	setDateFilter,
	toggleResetFilter,
	// totalCartOrderStatuswise,
}) => {
	const [details, setDetails] = useState({
		filters: {
			date: [null, null],
			time: {
				years: "",
				months: "",
				days: "",
				hours: "",
				last: "",
			},
		},
		search: "",
		sort: "",
		sortColumn: "",
	});

	const dispatch = useDispatch();
	const { dashboard } = useSelector((state) => ({
		dashboard: state.dashboard,
	}));
	let { totalCartOrderStatuswise, products, productCategories } = dashboard;

	totalCartOrderStatuswise = totalCartOrderStatuswise?.details;
	productCategories = productCategories?.details;

	const getTotalOrder = () => {
		const filters = JSON.stringify(setDateFilter(details.filters));
		dispatch(
			dispatchedTotalOrder({
				...details,
				filters: filters,
			})
		);
	};
	useEffect(() => {
		getTotalOrder();
	}, [details, setDetails]);

	useEffect(() => {
		dispatch(getProduct());
	}, []);

	const onChangeFilter = (event) => {
		const filters = { ...details.filters };
		filters[event.target.name] = event.target.value;
		setDetails({
			...details,
			filters: filters,
		});
	};

	const onChangeDateFilter = (date) => {
		const filters = { ...details.filters };
		setDetails({
			...details,
			filters: {
				...toggleResetFilter(filters, "date"),
				date: date,
			},
		});
	};

	const selectTime = (event) => {
		const filters = { ...details.filters };
		setDetails({
			...details,
			filters: {
				...toggleResetFilter(filters, "time"),
				time: {
					years: "",
					months: "",
					days: "",
					hours: "",
					last:
						event.target.name === filters["time"]["last"]
							? ""
							: event.target.name,
					[event.target.name.includes("years")
						? "years"
						: event.target.name]:
						event.target.name !== filters["time"]["last"]
							? event.target.value
							: "",
				},
			},
		});
	};

	const getStatusColor = (status) => {
		if (status === "failed") {
			return "danger";
		}
		if (status === "pending") {
			return "warning";
		}
		if (status === "shipped") {
			return "success";
		}
		if (status === "self-pickup") {
			return "info";
		}
		if (status === "used") {
			return "danger";
		}
		if (status === "processing") {
			return "warning";
		}
		if (status === "cancelled") {
			return "danger";
		}
		if (status === "onhold") {
			return "secondary";
		}
		if (status === "used") {
			return "danger";
		}
		if (status === "C_Not_Paid") {
			return "info";
		}
		if (status === "refunded") {
			return "danger";
		} else {
			return "dark";
		}
	};
	return (
		<React.Fragment>
			{/* <Col xl={6}> */}
			<Card className="card-h-100">
				<CardBody>
					<div className="d-flex flex-wrap align-items-center mb-4">
						<h5 className="card-title me-2">CloudX Orders</h5>
					</div>

					<Row className="m-0 mt-4">
						<Col xl="12">
							<div className="">
								<ul
									role="tablist"
									className="nav-tabs-custom rounded card-header-tabs nav mb-3"
								>
									<li className="nav-item">
										<div className="ms-auto ms-auto m-1">
											<Flatpickr
												className="form-control form-control-sm d-block"
												placeholder="Date Range"
												options={{
													mode: "range",
													dateFormat: "m/d/Y",
													maxDate: new Date(),
												}}
												maxdate={new Date()}
												name="date"
												value={details.filters.date}
												onChange={onChangeDateFilter}
											/>
										</div>
									</li>

									{/* <li className="nav-item">
											<div className="ms-auto ms-auto m-1">
												<button
													type="button"
													name="today"
													value={"today"}
													className={`btn ${
														details.filters.time
															.today
															? "btn-soft-primary"
															: "btn-soft-secondary"
													} btn-sm`}
													onClick={selectTime}
												>
													TODAY
												</button>
											</div>
										</li>

										<li className="nav-item">
											<div className="ms-auto ms-auto m-1">
												<button
													type="button"
													name="hours"
													value={24}
													className={`btn ${
														details.filters.time
															.hours == 24
															? "btn-soft-primary"
															: "btn-soft-secondary"
													} btn-sm`}
													onClick={selectTime}
												>
													LAST 24H
												</button>
											</div>
										</li>
										<li className="nav-item">
											<div className="ms-auto m-1">
												<button
													name="months"
													value="1"
													type="button"
													className={`btn ${
														details.filters.time
															.months
															? "btn-soft-primary"
															: "btn-soft-secondary"
													} btn-sm`}
													onClick={selectTime}
												>
													1M
												</button>
											</div>
										</li> */}

									{getTotalYear().map((year) => (
										<li key={year} className="nav-item">
											<div className="ms-auto m-1">
												<button
													key={year}
													name={`years-${year}`}
													value={year}
													type="button"
													className={`btn ${
														!isEmpty(
															details.filters.time
																.years
														)
															? parseInt(
																	details
																		.filters
																		.time
																		.years
															  ) === year
																? "btn-soft-primary"
																: "btn-soft-secondary"
															: "btn-soft-secondary"
													} btn-sm`}
													onClick={selectTime}
												>
													{`${year}`}
												</button>
											</div>
										</li>
									))}

									{/* <li className="nav-item">
											<div className="ms-auto m-1">
												<select
													name="status"
													className="form-select form-select-sm"
													value={
														details.filters.status
													}
													onChange={onChangeFilter}
												>
													<option
														key="select_status"
														value=""
													>
														Status
													</option>
													{[
														"pending",
														"processing",
													].map((item) => (
														<option
															key={item}
															value={item}
														>
															{item}
														</option>
													))}
												</select>
											</div>
										</li> */}

									<li className="nav-item">
										<div className="ms-auto m-1">
											<select
												name="payment_method"
												className="form-select form-select-sm"
												value={
													details.filters
														.payment_method
												}
												onChange={onChangeFilter}
											>
												<option
													key="select_payment_method"
													value=""
												>
													Payment Method
												</option>
												{[
													{
														name: "Wallet",
														value: "wallet",
													},
													{
														name: "Mastercard",
														value: "mastercard",
													},
												].map((item) => (
													<option
														key={item.value}
														value={item.value}
													>
														{item.name}
													</option>
												))}
											</select>
										</div>
									</li>
									<li className="nav-item">
										<div className="ms-auto m-1">
											<select
												name="productId"
												className="form-select form-select-sm"
												value={
													details.filters.productId
												}
												onChange={onChangeFilter}
											>
												<option
													key="select_product"
													value=""
												>
													Select Product Category
												</option>
												{Array.isArray(
													productCategories?.categories
												) &&
													productCategories?.categories?.map(
														(item) => (
															<option
																key={item.code}
																value={
																	item.code
																}
															>
																{item.name}
															</option>
														)
													)}
											</select>
										</div>
									</li>
								</ul>
							</div>
						</Col>
					</Row>

					<Row className="align-items-center">
						<div className="col-sm align-self-center">
							<div className="mt-4 mt-sm-0">
								{totalCartOrderStatuswise &&
									totalCartOrderStatuswise?.length > 0 &&
									totalCartOrderStatuswise?.map(
										(orderStatus, index) => (
											<div
												className="mt-1 pt-2"
												key={index}
											>
												<p className="mb-2">
													<i
														className={`mdi mdi-circle align-middle font-size-10 me-2 text-${getStatusColor(
															orderStatus?.status
														)}`}
													></i>{" "}
													<span className="text-capitalize">
														{
															orderStatus?.displayName
														}
													</span>
													{"    "}
													<span
														className={`badge badge-soft-${getStatusColor(
															orderStatus?.status
														)} font-size-15 rounded-pill bg-soft-${getStatusColor(
															orderStatus?.status
														)} float-end`}
													>
														{orderStatus?.total}
													</span>
												</p>
											</div>
										)
									)}
							</div>
						</div>
					</Row>
				</CardBody>
			</Card>
			{/* </Col> */}
			{/* <Col xl={6}>
				<Card className="card-h-100">
					<CardBody>
						<div className="d-flex flex-wrap align-items-center mb-4">
							<h5 className="card-title me-2">CloudX Orders</h5>
						</div>						

						<Row className="align-items-center">
							<div className="col-sm align-self-center">
								<div className="mt-4 mt-sm-0">
									{totalCartOrderStatuswise &&
										totalCartOrderStatuswise?.length > 0 &&
										totalCartOrderStatuswise?.map(
											(orderStatus, index) => (
												<div
													className="mt-1 pt-2"
													key={index}
												>
													<p className="mb-2">
														<i
															className={`mdi mdi-circle align-middle font-size-10 me-2 text-${getStatusColor(
																orderStatus?.status
															)}`}
														></i>{" "}
														<span className="text-capitalize">
															{
																orderStatus?.displayName
															}
														</span>
														{"    "}
														<span
															className={`badge badge-soft-${getStatusColor(
																orderStatus?.status
															)} font-size-15 rounded-pill bg-soft-${getStatusColor(
																orderStatus?.status
															)} float-end`}
														>
															{orderStatus?.total}
														</span>
													</p>
												</div>
											)
										)}
								</div>
							</div>
						</Row>
					</CardBody>
				</Card>
			</Col> */}
		</React.Fragment>
	);
};

export default TotalOrderStatusWiseCloudx;
