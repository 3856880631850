import React, { useEffect, useState, useRef, useMemo } from "react";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
	Card,
	CardBody,
	CardHeader,
	Col,
	Container,
	Row,
	Modal,
	ModalHeader,
	ModalBody,
	Button,
	FormFeedback,
	Form,
	Label,
	Input,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
//redux
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import HasAnyPermission from "../../common/Permission";
import hasPermission from "../../common/HasPermission";
import ReactDataTable from "../../common/ReactDataTable";
import { apiUrl } from "../../config";
import Swal from "sweetalert2";
import isEmpty from "../../utils/isEmpty";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import {
	create,
	update,
	remove,
	toggle,
	getOrders,
	clearResponse,
	clearNotification,
	getMacAddress,
	getInvoice,
	getUpgradeProduct,
	upgradeOrderProduct,
	clearInvoice,
	getExportOrderCSV,
} from "../../store/order/actions";
import Select from "react-select";

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import {
	paymentMethodoOptions,
	orderStatusOptions,
	orderType,
} from "../../common/FilterOptions";
import { getCountries } from "../../store/shipping/actions";
import makeAnimated from "react-select/animated";

let editor;
const Order = (props) => {
	const dispatch = useDispatch();
	const animatedComponents = makeAnimated();
	const refreshTableData = useRef(null);
	const { auth, order, countries } = useSelector((state) => ({
		order: state.order,
		auth: state.auth,
		countries: state.shipping.countries,
	}));

	const [dispatchedDetails, setDispatchedDetails] = useState([{}]);
	const [availableMacAddresses, setAvailableMacAddresses] = useState([{}]);

	const {
		response,
		notification,
		orders,
		isLoading,
		macAddresses,
		invoice,
		upradeProduct,
		exportOrder,
	} = order;
	const [isOpenAddEditModal, toggleAddEditModal] = useState(false);
	const [isOpenInvoiceModal, toggleInvoiceModal] = useState(false);
	const [isOpenUpgradeOrderModal, toggleUpgradeOrderModal] = useState(false);
	const [isOpenUpdateAddressOrderModal, toggleUpdateAddressOrderModal] =
		useState(false);
	const [selectedOrder, setSelectedOrder] = useState({});
	const [details, handleformData] = useState({});
	useEffect(() => {
		dispatch(getCountries());
		dispatch(getUpgradeProduct());
		dispatch(getMacAddress());
	}, []);

	const getOrGenerateInvoice = (order) => {
		Swal.fire({
			title: "Please wait...",
			html: "Please wait...",
			allowEscapeKey: false,
			allowOutsideClick: false,
			didOpen: () => {
				Swal.showLoading();
			},
		});
		dispatch(getInvoice({ id: order._id }));
	};

	const handleOrderInvoice = () => {
		if (isOpenInvoiceModal === true) {
			dispatch(clearInvoice());
		}
		toggleInvoiceModal(!isOpenInvoiceModal);
	};

	useEffect(() => {
		Swal?.close();
		if (invoice?.link) {
			toggleInvoiceModal(true);
		}
	}, [invoice]);

	useEffect(() => {
		if (exportOrder?.isLoading === false) {
			Swal?.close();
		}
		if (exportOrder.details?.link) {
			const link = document.createElement("a");
			link.href = exportOrder.details.link;
			link.download = `orders.csv`;
			link.dispatchEvent(new MouseEvent("click"));
			toast.success("CSV file has been downloaded");
		}
	}, [exportOrder]);

	useEffect(() => {
		if (
			notification &&
			(notification.status === 200 || notification.code === "200")
		) {
			refreshTableData.current();
			toast.success(notification.msg, {
				onOpen: () => {
					dispatch(clearNotification());
				},
			});
		} else if (notification && notification?.msg) {
			// refreshTableData.current();
			toast.error(notification.msg, {
				onOpen: () => {
					dispatch(clearNotification());
				},
			});
		}
		Swal?.close();
	}, [dispatch, notification]);

	useEffect(() => {
		if (response && response.status === 200) {
			if (response?.data?.upgradeDetails) {
				handleUpgradeOrderModal();
			}
		}
	}, [dispatch, response]);

	const countryOptionDropdown = useMemo(() => {
		const result = countries?.map((item) => ({
			label: `${item.name}`,
			value: item._id,
		}));
		return result ?? [];
	}, [countries]);

	const handleUpgradeOrderModal = () => {
		if (isOpenUpgradeOrderModal === true) {
			dispatch(clearResponse());
		}
		toggleUpgradeOrderModal(!isOpenUpgradeOrderModal);
	};

	const handleUpdateOrder = (data) => {
		setSelectedOrder(data);
		handleUpdateAddressOrderModal();
	};

	const handleUpdateAddressOrderModal = () => {
		if (isOpenUpdateAddressOrderModal === true) {
			// dispatch(clearResponse());
		}
		toggleUpdateAddressOrderModal(!isOpenUpdateAddressOrderModal);
	};

	const dispatchOrder = () => {
		if (!isEmpty(details?._id)) {
			Swal.fire({
				title: "Are you sure?",
				text: "Do you really want to dispatch?",
				icon: "warning",
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				cancelButtonColor: "#d33",
				confirmButtonText: "Confirm",
			}).then((result) => {
				if (result.value) {
					// dispatch(remove(data));
				}
			});
		}
	};

	const updateOrder = (order) => {
		if (!isEmpty(order)) {
			Swal.fire({
				title: "Are you sure?",
				text: "Do you really want to change status processing and send callback?",
				icon: "warning",
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				cancelButtonColor: "#d33",
				confirmButtonText: "Confirm",
			}).then((result) => {
				if (result.value) {
					Swal.fire({
						title: "Updating",
						html: "Please wait...",
						allowEscapeKey: false,
						allowOutsideClick: false,
						didOpen: () => {
							Swal.showLoading();
						},
					});
					dispatch(update({ _id: order._id }));
				}
			});
		}
	};

	const generateCSV = () => {
		Swal.fire({
			title: "Are you sure?",
			text: "Do you really want to export csv?",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Confirm",
		}).then((result) => {
			if (result.value) {
				Swal.fire({
					title: "Updating",
					html: "Please wait...",
					allowEscapeKey: false,
					allowOutsideClick: false,
					didOpen: () => {
						Swal.showLoading();
					},
				});
				dispatch(getExportOrderCSV());
			}
		});
	};

	const handleTooltipOpen = () => {
		toast.success("Copied to clipboard.");
	};

	const useFormikOptions = {
		enableReinitialize: true,
		initialValues: {},
		validationSchema: Yup.object({
			name: Yup.string().required(),
		}),
		onSubmit: (values) => {
			Swal.fire({
				title: "Updating",
				html: "Please wait...",
				allowEscapeKey: false,
				allowOutsideClick: false,
				didOpen: () => {
					Swal.showLoading();
				},
			});
			if (details.id) {
				dispatch(update({ ...values, id: details.id }));
			} else {
				dispatch(create(values));
			}
		},
	};

	const validation = useFormik(useFormikOptions);

	// const formatOrderProduct = (products) => {
	// 	return products.map((product) => product[Object.keys(product)[0]]);
	// };

	const handleAddEditModal = (data = null) => {
		if (!isEmpty(data) && data?.id) {
			// const products = formatOrderProduct(data.details.line_items);
			setDispatchedDetails({});
			handleformData({
				...data,
				// details: { ...data.details, line_items: products },
			});
		} else {
			handleformData({});
			validation.resetForm();
		}
		toggleAddEditModal(!isOpenAddEditModal);
	};

	useEffect(() => {
		setAvailableMacAddresses(macAddresses);
	}, [macAddresses]);

	const getMacAddressOptions = useMemo(() => {
		const result = availableMacAddresses?.map((item, index) => ({
			label: `${item.macAddress}`,
			index: index,
			value: item._id,
			_id: item._id,
			macAddress: item.macAddress,
		}));
		return result ?? [];
	}, [availableMacAddresses]);

	const handleChangeMacAddress = (index, assignDetails) => {
		let updatedMacAddress = availableMacAddresses;
		if (dispatchedDetails?.[index]) {
			updatedMacAddress = [
				...updatedMacAddress,
				dispatchedDetails[index],
			];
			setAvailableMacAddresses([
				...availableMacAddresses,
				dispatchedDetails[index],
			]);
		} else {
			availableMacAddresses.splice(assignDetails.index, 1);
			setAvailableMacAddresses(availableMacAddresses);
		}
		setDispatchedDetails({
			...dispatchedDetails,
			[index]: assignDetails,
		});
	};

	const handleChange = (index) => (e) => {
		setDispatchedDetails({
			...dispatchedDetails,
			[index]: { imei: e.target.value },
		});
	};

	const randomAssignMacAddress = (index, productId) => {
		const macAddress =
			availableMacAddresses[
				Math.floor(Math.random() * availableMacAddresses.length)
			];
		handleChangeMacAddress(index, {
			...macAddress,
			label: macAddress.macAddress,
			value: macAddress._id,
			productId: productId,
		});
	};

	const columns = () => [
		{
			label: "OrderID",
			name: "_id",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (rowData) => {
					return `#${rowData}`;
				},
			},
		},
		{
			label: "Order Type",
			name: "orderType",
			options: {
				customBodyRender: (rowData) => {
					return `${rowData?.partner ? "CLOUDX" : "WEWE"}`;
				},
				customFilterListOptions: {
					render: (v) => v.map((l) => orderType[l]),
				},
				filterType: "custom",
				filterOptions: {
					names: [],
					display: (filterList, onChange, index, column) => {
						return (
							<div className="auto">
								{/* <label
									htmlFor="currency-transaction"
									className="form-label font-size-13 text-muted"
								></label> */}
								<select
									value={filterList[index][0] || ""}
									onChange={(event) => {
										filterList[index][0] =
											event.target.value;
										onChange(
											filterList[index],
											index,
											column
										);
									}}
									className="form-control"
									name="currency-transaction"
								>
									<option disabled value="">
										Order Type
									</option>

									{Object.entries(orderType).map(
										([key, value]) => (
											<option key={key} value={key}>
												{value}
											</option>
										)
									)}
								</select>
							</div>
						);
					},
				},
				sort: false,
			},
		},
		{
			label: "Name",
			name: "name",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (rowData) => {
					return rowData ? (
						rowData
					) : (
						<div className="text-center">-</div>
					);
				},
				download: false,
			},
		},
		{
			label: "Email",
			name: "email",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (rowData) => {
					return rowData ? (
						rowData
					) : (
						<div className="text-center">-</div>
					);
				},
				download: false,
			},
		},
		// {
		// 	label: "Product Name",
		// 	name: "productName",
		// 	options: {
		// 		filter: false,
		// 		sort: false,
		// 		customBodyRender: (rowData) => {
		// 			return rowData ? (
		// 				rowData
		// 			) : (
		// 				<div className="text-center">-</div>
		// 			);
		// 		},
		// 		download: false,
		// 	},
		// },
		// {
		// 	label: "LIC Number",
		// 	name: "licNumber",
		// 	options: {
		// 		filter: false,
		// 		sort: false,
		// 		customBodyRender: (rowData) => {
		// 			return rowData ? (
		// 				rowData
		// 			) : (
		// 				<div className="text-center">-</div>
		// 			);
		// 		},
		// 	},
		// },
		{
			label: "Order Amount",
			name: "totalAmount",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (rowData) => {
					return `${rowData}`;
				},
			},
		},
		{
			label: "Paymet Method",
			name: "paymentMethod",
			options: {
				customFilterListOptions: {
					render: (v) => v.map((l) => paymentMethodoOptions[l]),
				},
				filterType: "custom",
				filterOptions: {
					names: [],
					display: (filterList, onChange, index, column) => {
						return (
							<div className="auto">
								{/* <label
									htmlFor="currency-transaction"
									className="form-label font-size-13 text-muted"
								></label> */}
								<select
									value={filterList[index][0] || ""}
									onChange={(event) => {
										filterList[index][0] =
											event.target.value;
										onChange(
											filterList[index],
											index,
											column
										);
									}}
									className="form-control"
									name="currency-transaction"
								>
									<option disabled value="">
										Payment Method
									</option>

									{Object.entries(paymentMethodoOptions).map(
										([key, value]) => (
											<option key={key} value={key}>
												{value}
											</option>
										)
									)}
								</select>
							</div>
						);
					},
				},
				sort: false,
			},
		},
		// {
		// 	label: "Currency",
		// 	name: "currency",
		// 	options: {
		// 		filter: false,
		// 		sort: false,
		// 		download: false,
		// 		customBodyRender: (rowData) => {
		// 			return `${rowData}`;
		// 		},
		// 	},
		// },

		{
			name: "status",
			label: "Status",
			options: {
				customFilterListOptions: {
					render: (v) =>
						v.map((l) => l.charAt(0).toUpperCase() + l.slice(1)),
				},
				filterType: "custom",
				filterOptions: {
					names: [],
					display: (filterList, onChange, index, column) => {
						return (
							<div className="auto">
								{/* <label
									htmlFor="currency-transaction"
									className="form-label font-size-13 text-muted"
								></label> */}
								<select
									value={filterList[index][0] || ""}
									onChange={(event) => {
										filterList[index][0] =
											event.target.value;
										onChange(
											filterList[index],
											index,
											column
										);
									}}
									className="form-control"
									name="currency-transaction"
								>
									<option disabled value="">
										Status
									</option>
									{Object.entries(orderStatusOptions).map(
										([key, value]) => (
											<option key={key} value={key}>
												{value}
											</option>
										)
									)}
								</select>
							</div>
						);
					},
				},
				sort: false,
				customBodyRender: (status) => {
					return status === "processing" ? (
						<div className="badge badge-soft-primary font-size-12">
							Processing
						</div>
					) : status === "pending" ? (
						<div className="badge badge-soft-warning font-size-12">
							Pending
						</div>
					) : status === "shipped" ? (
						<div className="badge badge-soft-success font-size-12">
							Delivered
						</div>
					) : (
						<div className="badge badge-soft-danger font-size-12">
							{status}
						</div>
					);
				},
			},
		},

		{
			label: "Date",
			name: "createdAt",
			options: {
				customBodyRender: (date) => {
					return moment(date).format("LLL");
				},
				filterType: "custom",
				customFilterListOptions: {
					render: (v) => {
						if (v?.[0])
							return `From Date : ${moment(v[0]).format(
								"MM/DD/YYYY"
							)} - To Date : ${moment(v[1]).format(
								"MM/DD/YYYY"
							)}`;
						return [];
					},
					update: (filterList, filterPos, index) => {
						filterList[index] = [];
						return filterList;
					},
				},
				filterOptions: {
					display: (filterList, onChange, index, column) => {
						return (
							<div className="auto">
								{/* <label
									htmlFor="currency-transaction"
									className="form-label font-size-13 text-muted"
								></label> */}
								<div className="input-group">
									<Flatpickr
										className="form-control d-block"
										placeholder="Date Range"
										options={{
											mode: "range",
											dateFormat: "m/d/Y",
										}}
										value={
											filterList[index] || [
												new Date(),
												new Date(),
											]
										}
										onChange={(date) => {
											filterList[index] = date;
											onChange(
												filterList[index],
												index,
												column
											);
										}}
									/>
								</div>
							</div>
						);
					},
				},
				sort: true,
			},
		},

		{
			label: "Actions",
			name: "action",
			options: {
				filter: false,
				sort: false,
				empty: true,
				download: false,
				display: hasPermission(
					["partner update", "partner delete", "partner view"],
					auth.currentUserRolePermissions
				),
				viewColumns: hasPermission(
					["partner update", "partner delete", "partner view"],
					auth.currentUserRolePermissions
				),
				customBodyRender: (data) => {
					return (
						<div className="text-center">
							<HasAnyPermission
								permission={["partner update", "partner view"]}
							>
								<Link
									to={`/shop/order/${data._id}`}
									className="btn m-1 btn-soft-primary waves-effect waves-light"
								>
									View
								</Link>
							</HasAnyPermission>

							{data.status === "processing" ? (
								<HasAnyPermission
									permission={[
										"partner update",
										"partner view",
									]}
								>
									<button
										onClick={(e) => {
											e.preventDefault();
											handleUpdateOrder(data);
										}}
										type="button"
										className="btn m-1 btn-soft-success waves-effect waves-light"
									>
										Update Address
									</button>
								</HasAnyPermission>
							) : null}

							<HasAnyPermission
								permission={["partner update", "partner view"]}
							>
								<button
									onClick={(e) => {
										e.preventDefault();
										// if (data.status === "processing") {
										getOrGenerateInvoice(data);
										// }
									}}
									type="button"
									className="btn m-1 btn-soft-primary waves-effect waves-light"
								>
									Download
								</button>
							</HasAnyPermission>
						</div>
					);
				},
			},
		},
	];

	const resultFormatter = (result) => {
		console.log(
			"🚀 ~ file: index.js:783 ~ resultFormatter ~ result:",
			result
		);
		return result.docs.map((item) => {
			return {
				...item,
				name: `${
					item.userId?.firstName ? item.userId.firstName : "-"
				} ${item.userId?.lastName ? item.userId.lastName : ""}`,
				email: `${item.userId?.email ? item.userId?.email : "-"}`,
				licNumber: `${
					item.userId?.licNumber ? item.userId.licNumber : "-"
				}`,
				// productName: `${
				// 	item.products?.[0]?.productId?.name
				// 		? item.products[0].productId.name
				// 		: "-"
				// }`,
				paymentMethod: item.paymentMethod?.name,
				action: item,
				orderType: { _id: item?._id, partner: item?.partner ?? null },
			};
		});
	};
	let paymentLink = null;
	if (response?.data?.upgradeDetails?.[0]) {
		paymentLink = response?.data?.upgradeDetails[0].paymentLink;
	}

	return (
		<React.Fragment>
			<div className="page-content">
				<Helmet>
					<title>Order | LYOTECHLABS</title>
				</Helmet>
				<Container fluid>
					{/* Render Breadcrumbs */}
					<Breadcrumbs title="Order" breadcrumbItem="List" />
					<Row>
						<Col lg="12">
							<Card>
								<CardBody>
									<Row>
										<Col xl="12">
											<div className="table-rep-plugin">
												<div className="table-responsive">
													<ReactDataTable
														url={`${apiUrl}/admin/store/orders/get-cart-orders`}
														columns={columns()}
														resultFormatter={
															resultFormatter
														}
														setRefresh={
															refreshTableData
														}
														disableFilterIcon={
															false
														}
														disableSearchIcon={
															false
														}
														origin={
															<div className="row">
																<div className="col-auto h4">
																	Orders
																	{/* &nbsp;
																	<button
																		onClick={() => {
																			generateCSV();
																		}}
																		type="button"
																		className="btn btn-primary waves-effect waves-light"
																	>
																		Export
																		Orders
																	</button> */}
																</div>
															</div>
														}
														rowsPerPage={10}
													/>
												</div>
											</div>
										</Col>
									</Row>
								</CardBody>
							</Card>
							<Modal
								isOpen={isOpenAddEditModal}
								toggle={handleAddEditModal}
								size="lg"
								centered={true}
							>
								<ModalHeader
									toggle={handleAddEditModal}
									tag="h4"
								>
									Assign details
								</ModalHeader>
								<ModalBody>
									<Form name="assignForm">
										<Row>
											{details?.details?.line_items
												? details.details.line_items.map(
														(product, index) => (
															<Col md="6">
																<Card
																	key={
																		product
																			.data
																			.product_id
																	}
																>
																	<CardHeader>
																		<h5 className="card-title">
																			{
																				product
																					.data
																					.name
																			}
																		</h5>
																		<p className="card-title-desc">
																			{`#${product.data.product_id}`}
																		</p>
																	</CardHeader>
																	<CardBody>
																		{product
																			.categories
																			.slug ===
																		"phone" ? (
																			<Row>
																				<div className="m-2">
																					<Label className="form-label">
																						IMEI
																					</Label>
																					<Input
																						name="imei"
																						type="text"
																						onChange={handleChange(
																							index
																						)}
																						value={
																							dispatchedDetails?.[
																								index
																							]
																								?.imei ||
																							""
																						}
																						required
																					/>
																				</div>
																			</Row>
																		) : (
																			<Row>
																				<div className="m-1">
																					<Select
																						name="macAddress"
																						value={
																							dispatchedDetails?.[
																								index
																							] ||
																							""
																						}
																						placeholder="Select mac address"
																						onChange={(
																							value,
																							action
																						) => {
																							handleChangeMacAddress(
																								index,
																								{
																									...value,
																									productId:
																										product
																											.data
																											.product_id,
																								}
																							);
																						}}
																						options={
																							getMacAddressOptions
																						}
																						classNamePrefix="select2-selection"
																						onSelectResetsInput={
																							true
																						}
																						closeMenuOnSelect={
																							true
																						}
																						required
																					/>
																				</div>

																				<div className="m-1">
																					<Button
																						type={
																							"submit"
																						}
																						className="btn-soft-primary waves-effect"
																						onClick={() => {
																							randomAssignMacAddress(
																								index,
																								product.product_id
																							);
																						}}
																					>
																						Random
																					</Button>
																				</div>
																			</Row>
																		)}
																	</CardBody>
																</Card>
															</Col>
														)
												  )
												: null}
										</Row>

										<Row className="text-sm-end">
											<Col>
												<Button
													className="btn-primary waves-effect"
													onClick={() => {
														dispatchOrder();
													}}
												>
													Dispatch
												</Button>
											</Col>
										</Row>
									</Form>
								</ModalBody>
							</Modal>
							<Modal
								isOpen={isOpenInvoiceModal}
								toggle={() => handleOrderInvoice()}
								size="xl"
								centered={true}
							>
								<ModalHeader
									toggle={() => handleOrderInvoice()}
									tag="h4"
								>
									Invoice Details
								</ModalHeader>
								<ModalBody style={{ height: "80vh" }}>
									<Row>
										<Col xl="12" md="12" lg="12">
											{invoice?.link && (
												<iframe
													src={invoice.link}
													title="PDF"
													style={{
														height: "75vh",
														width: "100%",
													}}
												/>
											)}
										</Col>
									</Row>
								</ModalBody>
							</Modal>

							<Modal
								isOpen={isOpenUpdateAddressOrderModal}
								toggle={() => handleUpdateAddressOrderModal()}
								size="md"
								centered={true}
							>
								<ModalHeader
									toggle={() =>
										handleUpdateAddressOrderModal()
									}
									tag="h4"
								>
									Update Shipping Address
								</ModalHeader>
								<ModalBody>
									<Row>
										<fieldset
										// disabled={
										// 	!couldHaveAddUpdatePermission()
										// }
										>
											<Form
												onSubmit={(e) => {
													e.preventDefault();
													validation.handleSubmit();
													return false;
												}}
											>
												<Row form={"true"}>
													<Col xs={12}>
														<div className="mb-3">
															<Label className="form-label">
																Name
															</Label>
															<Input
																name="name"
																type="text"
																onChange={
																	validation.handleChange
																}
																onBlur={
																	validation.handleBlur
																}
																value={
																	validation
																		.values
																		.name ||
																	""
																}
																invalid={
																	validation
																		.touched
																		.name &&
																	validation
																		.errors
																		.name
																		? true
																		: false
																}
															/>
															{validation.touched
																.name &&
															validation.errors
																.name ? (
																<FormFeedback type="invalid">
																	{
																		validation
																			.errors
																			.name
																	}
																</FormFeedback>
															) : null}
														</div>
													</Col>
												</Row>

												<Row form={"true"}>
													<Col xs={12}>
														<div className="mb-3">
															<Label className="form-label">
																Address
															</Label>
															<Input
																name="address"
																type="textarea"
																onChange={
																	validation.handleChange
																}
																onBlur={
																	validation.handleBlur
																}
																value={
																	validation
																		.values
																		.address ||
																	""
																}
																invalid={
																	validation
																		.touched
																		.address &&
																	validation
																		.errors
																		.address
																		? true
																		: false
																}
															/>
															{validation.touched
																.address &&
															validation.errors
																.address ? (
																<FormFeedback type="invalid">
																	{
																		validation
																			.errors
																			.address
																	}
																</FormFeedback>
															) : null}
														</div>
													</Col>
												</Row>

												<Row form={"true"}>
													<Col xs={12}>
														<div className="mb-3">
															<Label className="form-label">
																City
															</Label>
															<Input
																name="city"
																type="text"
																onChange={
																	validation.handleChange
																}
																onBlur={
																	validation.handleBlur
																}
																value={
																	validation
																		.values
																		.city ||
																	""
																}
																invalid={
																	validation
																		.touched
																		.city &&
																	validation
																		.errors
																		.city
																		? true
																		: false
																}
															/>
															{validation.touched
																.city &&
															validation.errors
																.city ? (
																<FormFeedback type="invalid">
																	{
																		validation
																			.errors
																			.city
																	}
																</FormFeedback>
															) : null}
														</div>
													</Col>
												</Row>

												<Row form={"true"}>
													<Col xs={12}>
														<div className="mb-3">
															<Label className="form-label">
																Select country
															</Label>
															<Select
																name="country"
																isDisabled={
																	details?._id
																		? true
																		: false
																}
																value={
																	validation
																		.values
																		.country ||
																	""
																}
																placeholder="Select country"
																onChange={(
																	value,
																	action
																) => {
																	validation.setFieldValue(
																		"country",
																		value
																	);
																}}
																options={
																	countryOptionDropdown
																}
																classNamePrefix="select2-selection"
																onSelectResetsInput={
																	true
																}
																closeMenuOnSelect={
																	true
																}
																components={
																	animatedComponents
																}
																required
															/>
															{validation.touched
																.country &&
															validation.errors
																.country ? (
																<FormFeedback
																	className="error"
																	type="invalid"
																>
																	{
																		validation
																			.errors
																			.country
																	}
																</FormFeedback>
															) : null}
														</div>
													</Col>
												</Row>

												<Row>
													<Col>
														<div className="text-end mt-3">
															<button
																type="submit"
																className="btn btn-success save-user"
															>
																Save
															</button>
														</div>
													</Col>
												</Row>
											</Form>
										</fieldset>
									</Row>
								</ModalBody>
							</Modal>

							<Modal
								isOpen={isOpenUpgradeOrderModal}
								toggle={() => handleUpgradeOrderModal()}
								size="lg"
								centered={true}
							>
								<ModalHeader
									toggle={() => handleUpgradeOrderModal()}
									tag="h4"
								>
									Payment Link
								</ModalHeader>
								<ModalBody>
									<Row>
										{paymentLink && (
											<div className="text-nowrap">
												<CopyToClipboard
													data-tooltip-content={
														paymentLink
													}
													id={"lastNineChara"}
													text={paymentLink}
													style={{
														cursor: "pointer",
													}}
													onCopy={() =>
														handleTooltipOpen()
													}
												>
													<h5
														style={{
															cursor: "pointer",
															texSize: "50",
														}}
													>
														{`${paymentLink}       `}
														<i className="mdi mdi-content-copy"></i>
													</h5>
												</CopyToClipboard>

												<ReactTooltip
													anchorId={"lastNineChara"}
													variant="info"
													place="bottom"
													style={{
														zIndex: 2147483647,
													}}
												/>
											</div>
										)}
									</Row>
								</ModalBody>
							</Modal>
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default Order;
