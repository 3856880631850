import React, { useEffect, useState, useRef, useMemo } from "react";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Helmet } from "react-helmet";
import {
	Card,
	CardBody,
	Col,
	Container,
	Row,
	Modal,
	ModalHeader,
	ModalBody,
	FormFeedback,
	Form,
	Label,
	Input,
} from "reactstrap";
//redux
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import HasAnyPermission from "../../common/Permission";
import hasPermission from "../../common/HasPermission";
import ReactDataTable from "../../common/ReactDataTable";
import isEmpty from "../../utils/isEmpty";
import { apiUrl } from "../../config";
import Swal from "sweetalert2";
import moment from "moment";
import Flatpickr from "react-flatpickr";

const User = (props) => {
	const dispatch = useDispatch();
	const refreshTableData = useRef(null);
	const { auth } = useSelector((state) => ({
		auth: state.auth,
	}));

	const redirectUserDetails = (data) => {};

	const columns = () => [
		{
			label: "IDENTIFIER",
			name: "licNumber",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (rowData) => {
					return rowData ? (
						rowData
					) : (
						<div className="text-center">-</div>
					);
				},
			},
		},
		{
			label: "First Name",
			name: "firstName",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (rowData) => {
					return rowData ? (
						rowData
					) : (
						<div className="text-center">-</div>
					);
				},
			},
		},
		{
			label: "Last Name",
			name: "lastName",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (rowData) => {
					return rowData ? (
						rowData
					) : (
						<div className="text-center">-</div>
					);
				},
			},
		},
		{
			label: "Email",
			name: "email",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (rowData) => {
					return rowData ? (
						rowData
					) : (
						<div className="text-center">-</div>
					);
				},
			},
		},

		// {
		// 	name: "status",
		// 	label: "Status",
		// 	options: {
		// 		filter: true,
		// 		sort: false,
		// 		download: false,
		// 		customBodyRender: (status) => {
		// 			return status === "active" ? (
		// 				<div className="badge badge-soft-primary font-size-12">
		// 					Active
		// 				</div>
		// 			) : (
		// 				<div className="badge badge-soft-danger font-size-12">
		// 					Inactive
		// 				</div>
		// 			);
		// 		},
		// 		// filterType: "custom",
		// 		// filterOptions: {
		// 		// 	names: [],
		// 		// 	display: (filterList, onChange, index, column) => {
		// 		// 		const optionValues = ["Active", "Inactive"];
		// 		// 		return (
		// 		// 			<div className="auto">
		// 		// 				<label
		// 		// 					htmlFor="user-status"
		// 		// 					className="form-label font-size-13 text-muted"
		// 		// 				>
		// 		// 					{" "}
		// 		// 					Status
		// 		// 				</label>
		// 		// 				<select
		// 		// 					value={filterList[index][0] || ""}
		// 		// 					onChange={(event) => {
		// 		// 						filterList[index][0] =
		// 		// 							event.target.value;
		// 		// 						onChange(
		// 		// 							filterList[index],
		// 		// 							index,
		// 		// 							column
		// 		// 						);
		// 		// 					}}
		// 		// 					className="form-control"
		// 		// 					name="user-status"
		// 		// 				>
		// 		// 					<option disabled value="">
		// 		// 						Status
		// 		// 					</option>
		// 		// 					{optionValues.map((item) => (
		// 		// 						<option
		// 		// 							key={item}
		// 		// 							value={
		// 		// 								item === "Active"
		// 		// 									? "active"
		// 		// 									: "inactive"
		// 		// 							}
		// 		// 						>
		// 		// 							{item}
		// 		// 						</option>
		// 		// 					))}
		// 		// 				</select>
		// 		// 			</div>
		// 		// 		);
		// 		// 	},
		// 		// },
		// 	},
		// },

		// {
		// 	label: "Created At",
		// 	name: "createdAt",
		// 	options: {
		// 		customBodyRender: (rowData) => {
		// 			return moment(rowData).format("LLL");
		// 		},
		// 		// filter: false,
		// 		// sort: false,
		// 		// download: false,
		// 		// filterType: "custom",
		// 		// customFilterListOptions: {
		// 		// 	render: (v) => {
		// 		// 		if (v?.[0])
		// 		// 			return `From Date : ${moment(v[0]).format(
		// 		// 				"MM/DD/YYYY"
		// 		// 			)} - To Date : ${moment(v[1]).format(
		// 		// 				"MM/DD/YYYY"
		// 		// 			)}`;
		// 		// 		return [];
		// 		// 	},
		// 		// 	update: (filterList, filterPos, index) => {
		// 		// 		filterList[index] = [];
		// 		// 		return filterList;
		// 		// 	},
		// 		// },
		// 		// filterOptions: {
		// 		// 	display: (filterList, onChange, index, column) => {
		// 		// 		return (
		// 		// 			<div className="auto">
		// 		// 				<label
		// 		// 					htmlFor="currency-transaction"
		// 		// 					className="form-label font-size-13 text-muted"
		// 		// 				>
		// 		// 					Created At
		// 		// 				</label>
		// 		// 				<div className="input-group">
		// 		// 					<Flatpickr
		// 		// 						className="form-control d-block"
		// 		// 						placeholder="Date Range"
		// 		// 						options={{
		// 		// 							mode: "range",
		// 		// 							dateFormat: "m/d/Y",
		// 		// 						}}
		// 		// 						// readOnly={false}
		// 		// 						value={
		// 		// 							filterList[index] || [
		// 		// 								new Date(),
		// 		// 								new Date(),
		// 		// 							]
		// 		// 						}
		// 		// 						onChange={(date) => {
		// 		// 							filterList[index] = date;
		// 		// 							onChange(
		// 		// 								filterList[index],
		// 		// 								index,
		// 		// 								column
		// 		// 							);
		// 		// 						}}
		// 		// 					/>
		// 		// 				</div>
		// 		// 			</div>
		// 		// 		);
		// 		// 	},
		// 		// },
		// 	},
		// },

		// {
		// 	label: "Actions",
		// 	name: "action",
		// 	options: {
		// 		filter: false,
		// 		sort: false,
		// 		empty: true,
		// 		download: false,
		// 		display: hasPermission(
		// 			["user update", "user delete", "user view"],
		// 			auth.currentUserRolePermissions
		// 		),
		// 		viewColumns: hasPermission(
		// 			["user update", "user delete", "user view"],
		// 			auth.currentUserRolePermissions
		// 		),
		// 		customBodyRender: (data) => {
		// 			return (
		// 				<div className="text-center">
		// 					<HasAnyPermission
		// 						permission={["user update", "user view"]}
		// 					>
		// 						<button
		// 							onClick={(e) => {
		// 								e.preventDefault();
		// 								props.history.push(`/user/${data._id}`);
		// 							}}
		// 							type="button"
		// 							className="btn btn-soft-primary waves-effect waves-light"
		// 						>
		// 							View
		// 						</button>
		// 					</HasAnyPermission>
		// 				</div>
		// 			);
		// 		},
		// 	},
		// },
	];

	const resultFormatter = (result) => {
		return result.docs.map((item) => {
			return {
				...item,
				action: item,
			};
		});
	};
	return (
		<React.Fragment>
			<div className="page-content">
				<Helmet>
					<title>Users | LYOTECHLABS</title>
				</Helmet>
				<Container fluid>
					{/* Render Breadcrumbs */}
					<Breadcrumbs title="Admin" breadcrumbItem="List" />
					<Row>
						<Col lg="12">
							<Card>
								<CardBody>
									<Row>
										<Col xl="12">
											<div className="table-rep-plugin">
												<div className="table-responsive">
													<HasAnyPermission
														permission={[
															"user list",
														]}
													>
														<ReactDataTable
															url={`${apiUrl}/admin/store/orders/user/pagination`}
															columns={columns()}
															resultFormatter={
																resultFormatter
															}
															setRefresh={
																refreshTableData
															}
															disableFilterIcon={
																false
															}
															disableSearchIcon={
																false
															}
															disableDownloadIcon={
																false
															}
															origin={
																<div className="row">
																	<div className="col-auto h4">
																		User
																		&nbsp;
																		<HasAnyPermission
																			permission={[
																				"user add",
																			]}
																		>
																			{/* <button
																				onClick={() => {
																					handleAddEditModal();
																				}}
																				type="button"
																				className="btn btn-primary waves-effect waves-light"
																			>
																				<i className="bx bx-plus-medical font-size-16 align-middle"></i>
																			</button> */}
																		</HasAnyPermission>
																	</div>
																</div>
															}
															rowsPerPage={10}
														/>
													</HasAnyPermission>
												</div>
											</div>
										</Col>
									</Row>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default User;
