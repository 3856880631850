import {
	ERRORS,
	CLEAR_RESPONSE,
	CREATE_FEE_SUCCESS,
	CREATE_FEE_FAILURE,
	UPDATE_FEE_SUCCESS,
	UPDATE_FEE_FAILURE,
	REMOVE_FEE_FAILURE,
	REMOVE_FEE_SUCCESS,
	TOGGLE_FEE_SUCCESS,
	TOGGLE_FEE_FAILURE,
	GET_FEE_SUCCESS,
	GET_FEE_REQUEST,
	GET_FEE_FAILURE,
	GET_COUNTRY_FEE_SUCCESS,
	GET_COUNTRY_FEE_REQUEST,
	GET_COUNTRY_FEE_FAILURE,
	UPDATE_COUNTRY_FEE_REQUEST,
	UPDATE_COUNTRY_FEE_SUCCESS,
	UPDATE_COUNTRY_FEE_FAILURE,
	DELETE_COUNTRY_FEE_SUCCESS,
	DELETE_COUNTRY_FEE_REQUEST,
	DELETE_COUNTRY_FEE_FAILURE,
} from "./actionTypes";

const initialState = {
	errors: {},
	response: {},
	countryFeees: [],
	fees: [],
	loader: false,
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case ERRORS:
			return {
				...state,
				errors: action.payload,
				loading: false,
			};
		case CLEAR_RESPONSE:
			return {
				...state,
				errors: {},
				response: {},
			};
		case CREATE_FEE_SUCCESS:
		case CREATE_FEE_FAILURE:
		case UPDATE_FEE_SUCCESS:
		case UPDATE_FEE_FAILURE:
		case REMOVE_FEE_FAILURE:
		case REMOVE_FEE_SUCCESS:
		case TOGGLE_FEE_SUCCESS:
		case TOGGLE_FEE_FAILURE:
		case UPDATE_COUNTRY_FEE_SUCCESS:
		case UPDATE_COUNTRY_FEE_FAILURE:
		case DELETE_COUNTRY_FEE_SUCCESS:
		case DELETE_COUNTRY_FEE_FAILURE:
			return {
				...state,
				response: action.payload,
				errors: {},
			};

		case GET_FEE_SUCCESS:
			return {
				...state,
				fees: action.payload?.data,
			};
		case GET_FEE_FAILURE:
			return {
				...state,
				fees: [],
				errors: action.payload,
			};
		case GET_COUNTRY_FEE_SUCCESS:
			return {
				...state,
				countryFeees: action.payload?.data,
			};
		case GET_COUNTRY_FEE_FAILURE:
			return {
				...state,
				countryFeees: [],
				errors: action.payload,
			};
		default:
			return state;
	}
};

export default reducer;
