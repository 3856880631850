import {
	ERRORS,
	CLEAR_RESPONSE,
	CREATE_FEE_REQUEST,
	UPDATE_FEE_REQUEST,
	REMOVE_FEE_REQUEST,
	TOGGLE_FEE_REQUEST,
	GET_FEE_SUCCESS,
	GET_FEE_REQUEST,
	GET_FEE_FAILURE,
	GET_COUNTRY_FEE_SUCCESS,
	GET_COUNTRY_FEE_REQUEST,
	GET_COUNTRY_FEE_FAILURE,
	UPDATE_COUNTRY_FEE_SUCCESS,
	UPDATE_COUNTRY_FEE_REQUEST,
	UPDATE_COUNTRY_FEE_FAILURE,
	DELETE_COUNTRY_FEE_SUCCESS,
	DELETE_COUNTRY_FEE_REQUEST,
	DELETE_COUNTRY_FEE_FAILURE,
} from "./actionTypes";

export const clearResponse = () => {
	return {
		type: CLEAR_RESPONSE,
		payload: {},
	};
};

export const getAllFee = (data) => {
	return {
		type: GET_FEE_REQUEST,
		payload: data,
	};
};

export const create = (data) => {
	return {
		type: CREATE_FEE_REQUEST,
		payload: data,
	};
};

export const update = (data) => {
	return {
		type: UPDATE_FEE_REQUEST,
		payload: data,
	};
};

export const toggle = (data) => {
	return {
		type: TOGGLE_FEE_REQUEST,
		payload: data,
	};
};

export const remove = (data) => {
	return {
		type: REMOVE_FEE_REQUEST,
		payload: data,
	};
};

export const getAllCountryFee = (data) => {
	return {
		type: GET_COUNTRY_FEE_REQUEST,
		payload: data,
	};
};

export const updateCountryFee = (data) => {
	return {
		type: UPDATE_COUNTRY_FEE_REQUEST,
		payload: data,
	};
};

export const deleteCountryFee = (data) => {
	return {
		type: DELETE_COUNTRY_FEE_REQUEST,
		payload: data,
	};
};
