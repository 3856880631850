import React, {
	useEffect,
	useState,
	useCallback,
	useRef,
	useMemo,
} from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import { useSelector, useDispatch } from "react-redux";
import { getTotalOrderStatuswise } from "../../store/dashboard/actions";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

// ChartJS.register(ArcElement, Tooltip, Legend);

const OrdersChart = ({ chartData }) => {
	const [graphdata, setGraphData] = useState(null);

	const pieChartData = {
		labels: [],
		datasets: [
			{
				label: "# of Votes",
				data: [],
				backgroundColor: [
					// "rgba(255, 99, 132, 0.5)",
					// "rgba(54, 162, 235, 0.5)",
					// "rgba(255, 206, 86, 0.5)",
					// "rgba(75, 192, 192, 0.5)",
					// "rgba(153, 102, 255, 0.5)",
					// "rgba(255, 159, 64, 0.5)",
					// "rgba(255, 99, 132, 0.5)",
					// "rgba(54, 162, 235, 0.5)",
					// "rgba(255, 206, 86, 0.5)",
					// "rgba(75, 192, 192, 0.5)",
					// "rgba(153, 102, 255, 0.5)",
					// "rgba(255, 159, 64, 0.5)",
				],
				borderWidth: 1,
			},
		],
	};

	const getStatusColor = (status) => {
		if (status === "failed") {
			return "rgba(255, 99, 132, 0.5)";
		}
		if (status === "pending") {
			return "rgba(255, 159, 64, 0.5)";
		}
		if (status === "shipped") {
			return "rgba(42, 181, 125, 0.5)";
		}
		if (status === "self-pickup") {
			return "rgba(54, 162, 235, 0.5)";
		}
		if (status === "used") {
			return "rgba(253, 98, 94, 0.5)";
		}
		if (status === "processing") {
			return "rgba(255, 206, 86, 0.5)";
		}
		if (status === "cancelled") {
			return "rgba(253, 98, 94, 0.5)";
		}
		if (status === "onhold") {
			return "rgba(116, 120, 141, 0.5)";
		}
		if (status === "C_Not_Paid") {
			return "rgba(54, 162, 235, 0.5)";
		}
		if (status === "refunded") {
			return "rgba(253, 98, 94, 0.5)";
		} else {
			return "rgba(52, 58, 64, 0.5)";
		}
	};

	useEffect(() => {
		if (chartData && chartData?.length > 0) {
			for (let i = 0; i < chartData?.length; i++) {
				pieChartData.datasets[0].data.push(chartData[i].total);
				pieChartData.labels.push(chartData[i].displayName);
				pieChartData.datasets[0].backgroundColor.push(
					getStatusColor(chartData[i].status)
				);
			}

			setGraphData(pieChartData);
		}
	}, [chartData]);

	return (
		<React.Fragment>
			<Card className="card-h-100">
				<CardBody>
					<div className="d-flex flex-wrap align-items-center mb-4">
						<h5 className="card-title me-2"></h5>
					</div>

					{/* <Row className="align-items-center">
						<div className="col-sm">
							<div id="wallet-balance" className="apex-charts">
								{totalOrderStatuswise &&
									chartData?.length >
										0 && (
										<ReactApexChart
											options={getGraphOptions}
											series={getGraphDetails}
											type="pie"
											height="350"
										/>
									)}
							</div>
						</div>
					</Row> */}
					{graphdata && <Pie data={graphdata} />}
				</CardBody>
			</Card>
		</React.Fragment>
	);
};

export default OrdersChart;
