import React, {
	useEffect,
	useState,
	useRef,
	useMemo,
	useCallback,
} from "react";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Helmet } from "react-helmet";
import {
	Card,
	CardBody,
	Col,
	Container,
	Row,
	Modal,
	ModalHeader,
	ModalBody,
	FormFeedback,
	Form,
	Label,
	Input,
} from "reactstrap";
//redux
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import HasAnyPermission from "../../common/Permission";
import hasPermission from "../../common/HasPermission";
import ReactDataTable from "../../common/ReactDataTable";
import isEmpty from "../../utils/isEmpty";
import { apiUrl } from "../../config";
import Swal from "sweetalert2";
import moment from "moment";
import Flatpickr from "react-flatpickr";
import {
	addressTypes,
	performedActionsOptions,
	presaleShippingStatusOptions,
} from "../../common/FilterOptions";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
	clearResponse,
	clearNotification,
	sendOrderToUps,
	assignOrderToUps,
	getAvailableUpsOrders,
	getAvailableShippingAdmin,
} from "../../store/order/actions";
import ReactDOMServer from "react-dom/server";
import AddressChangeRequestLog from "./../AddressChangeRequestLog/index";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const OrderUPSLog = (props) => {
	const dispatch = useDispatch();
	const animatedComponents = makeAnimated();
	const refreshTableData = useRef(null);
	const {
		notification,
		response,
		upsOrders,
		shippingAdmins,
		sentToUPS,
		assignOrders,
	} = useSelector((state) => ({
		notification: state.order.notification,
		response: state.order.response,
		upsOrders: state.order.upsOrders,
		shippingAdmins: state.order.shippingAdmins,
		sentToUPS: state.order.sentToUPS,
		assignOrders: state.order.assignOrders,
	}));

	const [isOpenAddEditModal, toggleAddEditModal] = useState(false);
	const [isOpenAssignModal, toggleAssignModal] = useState(false);
	const [isOpenOrderResponseModal, toggleOrderResponsModal] = useState(false);
	const [isOpenAssignResponseModal, toggleAssignResponsModal] =
		useState(false);
	const [ordersData, setOrdersData] = useState([]);
	const [assignedOrdersData, setAssignedOrdersData] = useState([]);
	const [details, handleFormData] = useState({
		active: true,
	});
	const [assignDetails, handleAssignFormData] = useState({
		active: true,
	});

	useEffect(() => {
		dispatch(getAvailableUpsOrders());
		dispatch(getAvailableShippingAdmin());
	}, []);

	useEffect(() => {
		if (sentToUPS?.data && sentToUPS.code === "200") {
			setOrdersData(sentToUPS?.data);
			handleOrderResponseModal();
		} else {
			setOrdersData([]);
		}
	}, [sentToUPS]);

	useEffect(() => {
		if (assignOrders?.data && assignOrders.code === "200") {
			setAssignedOrdersData(assignOrders?.data);
			handleAssignResponseModal();
		} else {
			setAssignedOrdersData([]);
		}
	}, [assignOrders]);

	useEffect(() => {
		if (notification && notification.code === "200") {
			refreshTableData.current();
			Swal.close();
			if (isOpenAddEditModal) toggleAddEditModal(!isOpenAddEditModal);
			if (isOpenAssignModal) toggleAssignModal(!isOpenAssignModal);
			toast.success(notification.msg, {
				onOpen: () => {
					dispatch(clearNotification());
				},
			});
		} else if (notification && notification?.msg) {
			refreshTableData.current();
			Swal.close();
			toast.error(notification.msg, {
				onOpen: () => {
					dispatch(clearNotification());
				},
			});
		}
	}, [dispatch, notification]);

	const columns = () => [
		{
			label: "Order ID",
			name: "order",
			options: {
				filter: false,
				sort: false,
			},
		},
		{
			label: "WP Order Id",
			name: "wpOrderId",
			options: {
				filter: false,
				sort: false,
			},
		},
		{
			label: "Invoice Number",
			name: "invoiceNumber",
			options: {
				filter: false,
				sort: false,
			},
		},
		{
			label: "Customer Email",
			name: "customerEmail",
			options: {
				filter: false,
				sort: false,
			},
		},
		{
			name: "status",
			label: "Order Status",
			options: {
				filterType: "custom",
				filterOptions: {
					names: [],
					display: (filterList, onChange, index, column) => {
						return (
							<div className="auto">
								{/* <label
									htmlFor="currency-transaction"
									className="form-label font-size-13 text-muted"
								></label> */}
								<select
									value={filterList[index][0] || ""}
									onChange={(event) => {
										filterList[index][0] =
											event.target.value;
										onChange(
											filterList[index],
											index,
											column
										);
									}}
									className="form-control"
									name="currency-transaction"
								>
									<option disabled value="">
										Status
									</option>
									{Object.entries(
										presaleShippingStatusOptions
									).map(([key, value]) => (
										<option key={key} value={key}>
											{value}
										</option>
									))}
								</select>
							</div>
						);
					},
				},
				sort: false,
				customBodyRender: (status) => {
					return status === "processing" ? (
						<div className="badge badge-soft-primary font-size-12">
							Processing
						</div>
					) : status === "shipped" ? (
						<div className="badge badge-soft-success font-size-12">
							Shipped
						</div>
					) : status === "hold" ? (
						<div className="badge badge-soft-warning font-size-12">
							Hold
						</div>
					) : (
						<div className="badge badge-soft-secondary font-size-12 text-capitalize">
							{status}
						</div>
					);
				},
			},
		},
		{
			label: "UPS Sent at",
			name: "sendToUPSAt",
			options: {
				customBodyRender: (date) => {
					return moment(date).format("LL");
				},
				filterType: "custom",
				customFilterListOptions: {
					render: (v) => {
						if (v?.[0])
							return `From Date : ${moment(v[0]).format(
								"MM/DD/YYYY"
							)} - To Date : ${moment(v[1]).format(
								"MM/DD/YYYY"
							)}`;
						return [];
					},
					update: (filterList, filterPos, index) => {
						filterList[index] = [];
						return filterList;
					},
				},
				filterOptions: {
					display: (filterList, onChange, index, column) => {
						return (
							<div className="auto">
								<div className="input-group w-100">
									<Flatpickr
										className="form-control d-block"
										placeholder="Date Range"
										options={{
											mode: "range",
											dateFormat: "m/d/Y",
										}}
										value={
											filterList[index] || [
												new Date(),
												new Date(),
											]
										}
										onChange={(date) => {
											filterList[index] = date;
											onChange(
												filterList[index],
												index,
												column
											);
										}}
									/>
								</div>
							</div>
						);
					},
				},
				sort: false,
			},
		},
	];

	const resultFormatter = (result) => {
		return result.docs.map((item) => {
			return {
				...item,
				order: item?.order?._id,
				wpOrderId: item?.order?.wpOrderId
					? item?.order?.wpOrderId
					: "-",
				invoiceNumber: item?.order
					?.preOrderShippingInvoiceSequenceNumber
					? item?.order?.preOrderShippingInvoiceSequenceNumber
					: "-",
				customerEmail: item?.order?.userId?.email
					? item?.order?.userId?.email
					: "-",
				status: item?.order?.status,
				sendToUPSAt: item?.sendToUPSAt,
			};
		});
	};

	const handleOrderResponseModal = () => {
		toggleOrderResponsModal(!isOpenOrderResponseModal);
	};

	const handleAssignResponseModal = () => {
		toggleAssignResponsModal(!isOpenAssignResponseModal);
	};

	const handleAddModal = (data = null) => {
		handleFormData({
			active: true,
		});
		validation.resetForm();

		toggleAddEditModal(!isOpenAddEditModal);
	};

	let validationSchema = {
		orders: Yup.string().required("Please enter order ID."),
		sendToUPSAt: Yup.date().required("Please select date."),
	};

	const useFormikOptions = {
		initialValues: {
			orders: details && details.orders ? details.orders : "",
			sendToUPSAt:
				details && details.sendToUPSAt
					? details.sendToUPSAt
					: new Date(),
		},
		validationSchema: Yup.object(validationSchema),
		onSubmit: (values) => {
			const orders = values.orders?.split(",");
			dispatch(
				sendOrderToUps({
					...values,
					orders: orders.map((order) => order.trim()),
				})
			);
		},
	};

	const validation = useFormik(useFormikOptions);

	const handleAssignModal = (data = null) => {
		{
			handleAssignFormData({
				active: true,
			});
			validationAssignForm.resetForm();
		}
		toggleAssignModal(!isOpenAssignModal);
	};

	const useFormikOptionsAssignForm = {
		initialValues: {
			email:
				assignDetails && assignDetails.email ? assignDetails.email : "",
			_id: assignDetails && assignDetails._id ? assignDetails._id : "",
		},
		validationSchema: Yup.object({
			email: Yup.object().required("Please Select Email."),
			// _id: Yup.object().required("Please Select Order IDs").nullable(),
		}),
		onSubmit: (values) => {
			dispatch(
				assignOrderToUps({
					...values,
					orders: values._id.map((order) => order.label),
					admin: values.email,
				})
			);
		},
	};

	const validationAssignForm = useFormik(useFormikOptionsAssignForm);

	const orderOptionDropdown = useMemo(() => {
		let result;
		if (upsOrders?.length > 0) {
			result = upsOrders?.map((item) => ({
				label: `${item.order}`,
				value: item._id,
				name: item.order,
			}));
		}
		return result ?? [];
	}, [upsOrders]);

	const shippingAdminsOptionDropdown = useMemo(() => {
		let result;
		if (shippingAdmins?.length > 0) {
			result = shippingAdmins?.map((item) => ({
				label: `${item.email}`,
				value: item._id,
				name: item.email,
			}));
		}
		return result ?? [];
	}, [shippingAdmins]);

	return (
		<React.Fragment>
			<div className="page-content">
				<Helmet>
					<title>UPS Sent Log | LYOTECHLABS</title>
				</Helmet>
				<Container fluid>
					{/* Render Breadcrumbs */}
					<Breadcrumbs title="Admin" breadcrumbItem="Log" />
					<Row>
						<Col lg="12">
							<Card>
								<CardBody>
									<Row>
										<Col xl="12">
											<div className="table-rep-plugin">
												<div className="table-responsive">
													<HasAnyPermission
														permission={[
															"customer shipping address log list",
														]}
													>
														<ReactDataTable
															url={`${apiUrl}/admin/store/orders/get-order-assign-to-ups-activities`}
															columns={columns()}
															resultFormatter={
																resultFormatter
															}
															setRefresh={
																refreshTableData
															}
															disableFilterIcon={
																false
															}
															disableSearchIcon={
																false
															}
															disableDownloadIcon={
																true
															}
															origin={
																<div className="row">
																	<div className="col-auto h4">
																		UPS Sent
																		Log
																		&nbsp;
																		{/* <HasAnyPermission
																			permission={[
																				"send order to UPS",
																			]}
																		> */}
																		<button
																			onClick={() => {
																				handleAddModal();
																			}}
																			type="button"
																			className="btn btn-primary waves-effect waves-light m-2"
																		>
																			Send
																			Order
																			to
																			UPS
																		</button>
																		{/* </HasAnyPermission> */}
																		{/* <HasAnyPermission
																			permission={[
																				"assign order to UPS",
																			]}
																		> */}
																		<button
																			onClick={() => {
																				handleAssignModal();
																			}}
																			type="button"
																			className="btn btn-primary waves-effect waves-light"
																		>
																			Assign
																			Order
																			to
																			Shipping
																			User
																			(UPS)
																		</button>
																		{/* </HasAnyPermission> */}
																	</div>
																</div>
															}
															rowsPerPage={10}
														/>
													</HasAnyPermission>

													<Modal
														isOpen={
															isOpenAddEditModal
														}
														toggle={handleAddModal}
														backdrop={"static"}
														size="lg"
														centered={true}
													>
														<ModalHeader
															toggle={
																handleAddModal
															}
															tag="h4"
														>
															Send Orders to UPS
														</ModalHeader>
														<ModalBody>
															<Form
																// form={true}
																// onSubmit={
																// 	validation.handleSubmit
																// }
																disabled
																onSubmit={(
																	e
																) => {
																	e.preventDefault();
																	validation.handleSubmit();
																	return false;
																}}
															>
																<fieldset>
																	<Row
																		form={
																			"true"
																		}
																	>
																		<Col
																			xs={
																				12
																			}
																		>
																			<div className="mb-3">
																				<Label className="form-label">
																					Order
																					IDs
																				</Label>
																				<Input
																					name="orders"
																					type="textarea"
																					onChange={
																						validation.handleChange
																					}
																					value={
																						validation
																							.values
																							.orders ||
																						""
																					}
																					onBlur={
																						validation.handleBlur
																					}
																					invalid={
																						validation
																							.touched
																							.orders &&
																						validation
																							.errors
																							.orders
																							? true
																							: false
																					}
																				/>
																				{validation
																					.touched
																					.orders &&
																				validation
																					.errors
																					.orders ? (
																					<FormFeedback type="invalid">
																						{
																							validation
																								.errors
																								.orders
																						}
																					</FormFeedback>
																				) : null}
																			</div>
																		</Col>
																	</Row>

																	<Row
																		form={
																			"true"
																		}
																	>
																		<Col
																			xs={
																				12
																			}
																		>
																			<div className="mb-3">
																				<Label className="form-label">
																					Send
																					to
																					UPS
																					Date
																				</Label>
																				<div className="input-group">
																					<Flatpickr
																						className="form-control d-block"
																						placeholder="Pickup Date"
																						name="sendToUPSAt"
																						options={{
																							dateFormat:
																								"m/d/Y",
																							altInput: true,
																							mode: "single",
																						}}
																						// value={
																						// 	validation
																						// 		.values
																						// 		.sendToUPSAt ||
																						// 	null
																						// }
																						value={
																							validation
																								.values
																								.sendToUPSAt ||
																							""
																						}
																						onChange={(
																							date
																						) => {
																							validation.setFieldValue(
																								"sendToUPSAt",
																								date?.[0] ??
																									null
																							);
																						}}
																					/>
																				</div>
																				{validation
																					.touched
																					.sendToUPSAt &&
																				validation
																					.errors
																					.sendToUPSAt ? (
																					<FormFeedback type="invalid">
																						{
																							validation
																								.errors
																								.sendToUPSAt
																						}
																					</FormFeedback>
																				) : null}
																			</div>
																		</Col>
																	</Row>
																	<Row>
																		<Col>
																			<div className="text-end mt-3">
																				<button
																					type="submit"
																					className="btn btn-success save-user"
																				>
																					Save
																				</button>
																			</div>
																		</Col>
																	</Row>
																</fieldset>
															</Form>
														</ModalBody>
													</Modal>

													<Modal
														isOpen={
															isOpenOrderResponseModal
														}
														toggle={
															handleOrderResponseModal
														}
														backdrop={"static"}
														size="lg"
														centered={true}
													>
														<ModalHeader
															toggle={
																handleOrderResponseModal
															}
															tag="h4"
														>
															Orders sent to UPS
														</ModalHeader>
														<ModalBody>
															{ordersData && (
																<div>
																	{ordersData
																		?.alreadyShippedOrders
																		?.length >
																		0 && (
																		<>
																			<h6>
																				Already
																				Shipped
																				Orders
																			</h6>
																			<ul>
																				{ordersData?.alreadyShippedOrders.map(
																					(
																						order,
																						index
																					) => (
																						<li
																							key={
																								index
																							}
																						>
																							{
																								order
																							}
																						</li>
																					)
																				)}
																			</ul>
																		</>
																	)}

																	{ordersData
																		?.assignOrders
																		?.length >
																		0 && (
																		<>
																			<h6>
																				Assign
																				Orders
																			</h6>
																			<ul>
																				{ordersData?.assignOrders.map(
																					(
																						order,
																						index
																					) => (
																						<li
																							key={
																								index
																							}
																						>
																							{
																								order
																							}
																						</li>
																					)
																				)}
																			</ul>
																		</>
																	)}

																	{ordersData
																		?.alreadyAssignOrders
																		?.length >
																		0 && (
																		<>
																			<h6>
																				Already
																				Assign
																				Orders
																			</h6>
																			<ul>
																				{ordersData?.alreadyAssignOrders.map(
																					(
																						order,
																						index
																					) => (
																						<li
																							key={
																								index
																							}
																						>
																							{
																								order
																							}
																						</li>
																					)
																				)}
																			</ul>
																		</>
																	)}

																	{ordersData
																		?.notFindOrders
																		?.length >
																		0 && (
																		<>
																			<h6>
																				Invalid
																				Orders
																			</h6>
																			<ul>
																				{ordersData?.notFindOrders.map(
																					(
																						order,
																						index
																					) => (
																						<li
																							key={
																								index
																							}
																						>
																							{
																								order
																							}
																						</li>
																					)
																				)}
																			</ul>
																		</>
																	)}

																	{ordersData
																		?.invalidOrders
																		?.length >
																		0 && (
																		<>
																			<h6>
																				Invalid
																				status
																				Orders
																			</h6>
																			<ul>
																				{ordersData?.invalidOrders.map(
																					(
																						order,
																						index
																					) => (
																						<li
																							key={
																								index
																							}
																						>
																							{
																								order
																							}
																						</li>
																					)
																				)}
																			</ul>
																		</>
																	)}
																</div>
															)}
														</ModalBody>
													</Modal>

													<Modal
														isOpen={
															isOpenAssignModal
														}
														toggle={
															handleAssignModal
														}
														backdrop={"static"}
														size="lg"
														centered={true}
													>
														<ModalHeader
															toggle={
																handleAssignModal
															}
															tag="h4"
														>
															Assign Orders to UPS
														</ModalHeader>
														<ModalBody>
															<Form
																// form={true}
																// onSubmit={
																// 	validation.handleSubmit
																// }
																disabled
																onSubmit={(
																	e
																) => {
																	e.preventDefault();
																	validationAssignForm.handleSubmit();
																	return false;
																}}
															>
																<fieldset>
																	<Row
																		form={
																			"true"
																		}
																	>
																		<Col
																			xs={
																				12
																			}
																		>
																			<div className="mb-3">
																				<Label className="form-label">
																					Select
																					Orders
																				</Label>
																				<Select
																					name="_id"
																					placeholder="Select Oders"
																					value={
																						validationAssignForm
																							.values
																							._id ||
																						""
																					}
																					onChange={(
																						value,
																						action
																					) => {
																						validationAssignForm.setFieldValue(
																							"_id",
																							value
																						);
																					}}
																					isMulti={
																						true
																					}
																					options={
																						orderOptionDropdown
																					}
																					classNamePrefix="select2-selection"
																					onSelectResetsInput={
																						true
																					}
																					closeMenuOnSelect={
																						true
																					}
																					components={
																						animatedComponents
																					}
																					required
																				/>
																				{validationAssignForm
																					.touched
																					._id &&
																				validationAssignForm
																					.errors
																					._id ? (
																					<FormFeedback
																						className="error"
																						type="invalid"
																					>
																						{
																							validationAssignForm
																								.errors
																								._id
																						}
																					</FormFeedback>
																				) : null}
																			</div>
																		</Col>
																	</Row>

																	<Row
																		form={
																			"true"
																		}
																	>
																		<Col
																			xs={
																				12
																			}
																		>
																			<div className="mb-3">
																				<Label className="form-label">
																					Email
																					Address
																				</Label>
																				<Select
																					name="email"
																					value={
																						validationAssignForm
																							.values
																							.email ||
																						""
																					}
																					onChange={(
																						value,
																						action
																					) => {
																						validationAssignForm.setFieldValue(
																							"email",
																							value
																						);
																					}}
																					isMulti={
																						false
																					}
																					options={
																						shippingAdminsOptionDropdown
																					}
																					classNamePrefix="select2-selection"
																					onSelectResetsInput={
																						true
																					}
																					closeMenuOnSelect={
																						true
																					}
																					components={
																						animatedComponents
																					}
																					required
																				/>
																				{validationAssignForm
																					.touched
																					.email &&
																				validationAssignForm
																					.errors
																					.email ? (
																					<FormFeedback type="invalid">
																						{
																							validationAssignForm
																								.errors
																								.email
																						}
																					</FormFeedback>
																				) : null}
																			</div>
																		</Col>
																	</Row>
																	<Row>
																		<Col>
																			<div className="text-end mt-3">
																				<button
																					type="submit"
																					className="btn btn-success save-user"
																				>
																					Save
																				</button>
																			</div>
																		</Col>
																	</Row>
																</fieldset>
															</Form>
														</ModalBody>
													</Modal>

													<Modal
														isOpen={
															isOpenAssignResponseModal
														}
														toggle={
															handleAssignResponseModal
														}
														backdrop={"static"}
														size="lg"
														centered={true}
													>
														<ModalHeader
															toggle={
																handleAssignResponseModal
															}
															tag="h4"
														>
															Assign Order to UPS
														</ModalHeader>
														<ModalBody>
															{assignedOrdersData && (
																<div>
																	{assignedOrdersData
																		?.alreadyShippedOrders
																		?.length >
																		0 && (
																		<>
																			<h6>
																				Already
																				Shipped
																				Orders
																			</h6>
																			<ul>
																				{assignedOrdersData?.alreadyShippedOrders.map(
																					(
																						order,
																						index
																					) => (
																						<li
																							key={
																								index
																							}
																						>
																							{
																								order
																							}
																						</li>
																					)
																				)}
																			</ul>
																		</>
																	)}

																	{assignedOrdersData
																		?.assignOrders
																		?.length >
																		0 && (
																		<>
																			<h6>
																				Assign
																				Orders
																			</h6>
																			<ul>
																				{assignedOrdersData?.assignOrders.map(
																					(
																						order,
																						index
																					) => (
																						<li
																							key={
																								index
																							}
																						>
																							{
																								order
																							}
																						</li>
																					)
																				)}
																			</ul>
																		</>
																	)}

																	{assignedOrdersData
																		?.alreadyAssignOrders
																		?.length >
																		0 && (
																		<>
																			<h6>
																				Already
																				Assign
																				Orders
																			</h6>
																			<ul>
																				{assignedOrdersData?.alreadyAssignOrders.map(
																					(
																						order,
																						index
																					) => (
																						<li
																							key={
																								index
																							}
																						>
																							{
																								order
																							}
																						</li>
																					)
																				)}
																			</ul>
																		</>
																	)}

																	{assignedOrdersData
																		?.notFindOrders
																		?.length >
																		0 && (
																		<>
																			<h6>
																				Invalid
																				Orders
																			</h6>
																			<ul>
																				{assignedOrdersData?.notFindOrders.map(
																					(
																						order,
																						index
																					) => (
																						<li
																							key={
																								index
																							}
																						>
																							{
																								order
																							}
																						</li>
																					)
																				)}
																			</ul>
																		</>
																	)}
																</div>
															)}
														</ModalBody>
													</Modal>
												</div>
											</div>
										</Col>
									</Row>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default OrderUPSLog;
