import React, {
	useEffect,
	useState,
	useCallback,
	useRef,
	useMemo,
} from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Container, Card, CardBody, Col, Row } from "reactstrap";
import moment from "moment";
import TotalOrder from "./TotalOrder";
import TotalOrderStatusWise from "./TotalOrderStatusWise";
import OrdersChart from "./OrdersChart";
import { useSelector, useDispatch } from "react-redux";
import {
	getProductCategories,
	getTotalCartOrderStatuswise,
	getTotalOrderStatuswise,
} from "../../store/dashboard/actions";
import TotalOrderStatusWiseCloudx from "./TotalOrderStatusWiseCloudx";

const Dashboard = () => {
	document.title = "LYOTECHLABS | Admin Dashboard";
	const dispatch = useDispatch();
	const {
		totalOrderStatuswise,
		totalCartOrderStatuswise,
		productCategories,
	} = useSelector((state) => ({
		totalOrderStatuswise: state.dashboard.totalOrderStatuswise,
		totalCartOrderStatuswise: state.dashboard.totalCartOrderStatuswise,
		productCategories: state.dashboard.productCategories,
	}));

	useEffect(() => {
		dispatch(getTotalOrderStatuswise());
		dispatch(getTotalCartOrderStatuswise());
		dispatch(getProductCategories());
	}, []);

	const formatValue = (val, formatter = 2) => {
		return isNaN(parseFloat(val))
			? 0.0
			: parseFloat(val).toFixed(formatter);
	};
	const getStringToDateRange = (filters) => {
		let { time } = filters;
		const timeValue = [];
		const key = time.last.includes("years") ? "years" : time.last;
		const value = time[key];
		if (key === "years") {
			const startYearDate = moment([parseInt(value)])
				// .add({ days: 1 })
				.toDate();
			const endYearDate = moment([parseInt(value)])
				.endOf("year")
				.toDate();
			timeValue[0] = startYearDate;
			timeValue[1] = endYearDate;
		} else if (key === "months") {
			timeValue[0] = moment().subtract(value, "months").toDate();
			timeValue[1] = moment().toDate();
		} else if (key === "hours") {
			timeValue[0] = moment().subtract(value, "hours").toDate();
			timeValue[1] = moment().toDate();
		} else if (key === "today") {
			timeValue[0] = moment().startOf("day").toDate();
			timeValue[1] = moment().endOf("day").toDate();
		}
		return timeValue;
	};

	const getTotalYear = () => {
		const numberofYear = [];
		const currentYear = new Date().getFullYear();
		for (let i = 2023; i <= parseInt(currentYear); i++) {
			numberofYear.push(i);
		}
		return numberofYear;
	};

	const setDateFilter = (filters) => {
		const { time, ...rest } = filters;
		if (filters.date?.[0]) return rest;
		let newTimeValue = getStringToDateRange(filters);
		filters = { ...rest, date: newTimeValue };
		return filters;
	};

	const toggleResetFilter = (filters, set) => {
		if (set === "date") {
			return {
				...filters,
				time: {
					years: "",
					months: "",
					days: "",
					hours: "",
					last: "",
				},
			};
		}
		return { ...filters, date: [null, null] };
	};

	return (
		<React.Fragment>
			<div className="page-content">
				<Container fluid>
					{/* Render Breadcrumbs */}
					<Breadcrumbs title="Dashboard" breadcrumbItem="Dashboard" />
					<Row>
						<Col xs="6">
							<TotalOrderStatusWise
								formatValue={formatValue}
								getStringToDateRange={getStringToDateRange}
								getTotalYear={getTotalYear}
								setDateFilter={setDateFilter}
								toggleResetFilter={toggleResetFilter}
								totalOrderStatuswise={
									totalOrderStatuswise?.details
								}
							/>
						</Col>

						<Col xs="6">
							<TotalOrderStatusWiseCloudx
								formatValue={formatValue}
								getStringToDateRange={getStringToDateRange}
								getTotalYear={getTotalYear}
								setDateFilter={setDateFilter}
								toggleResetFilter={toggleResetFilter}
								totalOrderStatuswise={
									totalCartOrderStatuswise.details
								}
							/>
						</Col>

						{/* <TotalOrder
							formatValue={formatValue}
							getStringToDateRange={getStringToDateRange}
							getTotalYear={getTotalYear}
							setDateFilter={setDateFilter}
							toggleResetFilter={toggleResetFilter}
						/> */}
					</Row>
					<Row>
						<Col xl={6}>
							<OrdersChart
								chartData={totalOrderStatuswise?.details}
							/>
						</Col>
						<Col xl={6}>
							<OrdersChart
								chartData={totalCartOrderStatuswise?.details}
							/>
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default Dashboard;
