import { combineReducers } from "redux";
// Front
import Layout from "./layout/reducer";
// Authentication
import auth from "./auth/reducer";
import admin from "./admin/reducer";
import dashboard from "./dashboard/reducer";
import user from "./user/reducer";
import softwarePackage from "./softwarePackage/reducer";
import product from "./product/reducer";
import order from "./order/reducer";
import voucherCodePackage from "./voucherCodePackage/reducer";
import productOption from "./productOption/reducer";
import shipping from "./shipping/reducer";
import tax from "./tax/reducer";
import fee from "./fee/reducer";

const rootReducer = combineReducers({
	Layout,
	auth,
	admin,
	dashboard,
	user,
	softwarePackage,
	product,
	order,
	voucherCodePackage,
	productOption,
	shipping,
	tax,
	fee,
});

export default rootReducer;
