import {
	ERRORS,
	CLEAR_RESPONSE,
	CREATE_ORDER_SUCCESS,
	CREATE_ORDER_FAILURE,
	UPDATE_ORDER_SUCCESS,
	UPDATE_ORDER_FAILURE,
	REMOVE_ORDER_FAILURE,
	REMOVE_ORDER_SUCCESS,
	TOGGLE_ORDER_SUCCESS,
	TOGGLE_ORDER_FAILURE,
	GET_ORDER_REQUEST,
	GET_ORDER_SUCCESS,
	GET_ORDER_FAILURE,
	GET_ORDER_WITH_ID_REQUEST,
	GET_ORDER_WITH_ID_SUCCESS,
	GET_ORDER_WITH_ID_FAILURE,
	GET_MAC_ADDRESS_REQUEST,
	GET_MAC_ADDRESS_SUCCESS,
	GET_MAC_ADDRESS_FAILURE,
	GET_INVOICE_REQUEST,
	GET_INVOICE_SUCCESS,
	GET_INVOICE_FAILURE,
	CLEAR_INVOICE_RESPONSE,
	GET_UPGRADE_PRODUCT_REQUEST,
	GET_UPGRADE_PRODUCT_SUCCESS,
	GET_UPGRADE_PRODUCT_FAILURE,
	GET_UPGRADE_ORDER_PRODUCT_REQUEST,
	GET_UPGRADE_ORDER_PRODUCT_SUCCESS,
	GET_UPGRADE_ORDER_PRODUCT_FAILURE,
	CLEAR_NOTIFICATION,
	EXPORT_ORDER_REQUEST,
	EXPORT_ORDER_SUCCESS,
	EXPORT_ORDER_FAILURE,
	GET_ORDER_MERCHANT_INVOICE_REQUEST,
	GET_ORDER_MERCHANT_INVOICE_SUCCESS,
	GET_ORDER_MERCHANT_INVOICE_FAILURE,
	UPDATE_ORDER_CHANGE_ADDRESS_SUCCESS,
	UPDATE_ORDER_CHANGE_ADDRESS_REQUEST,
	UPDATE_ORDER_CHANGE_ADDRESS_FAILURE,
	UPDATE_ORDER_CHANGE_METHOD_SUCCESS,
	UPDATE_ORDER_CHANGE_METHOD_FAILURE,
	UPDATE_ORDER_CHANGE_METHOD_REQUEST,
	UPDATE_ORDER_STATUS_REQUEST,
	UPDATE_ORDER_STATUS_SUCCESS,
	UPDATE_ORDER_STATUS_FAILURE,
	UPDATE_SHIPPING_DETAILS_REQUEST,
	UPDATE_SHIPPING_DETAILS_SUCCESS,
	UPDATE_SHIPPING_DETAILS_FAILURE,
	UPDATE_BILLING_ADDRESS_SUCCESS,
	UPDATE_BILLING_ADDRESS_FAILURE,
	CREATE_SEND_ORDER_TO_UPS_REQUEST,
	CREATE_SEND_ORDER_TO_UPS_SUCCESS,
	CREATE_SEND_ORDER_TO_UPS_FAILURE,
	CREATE_ASSIGN_ORDER_TO_UPS_SUCCESS,
	CREATE_ASSIGN_ORDER_TO_UPS_FAILURE,
	GET_AVAILABLE_UPS_ORDER_REQUEST,
	GET_AVAILABLE_UPS_ORDER_SUCCESS,
	GET_AVAILABLE_UPS_ORDER_FAILURE,
	GET_AVAILABLE_SHIPPING_ADMIN_REQUEST,
	GET_AVAILABLE_SHIPPING_ADMIN_SUCCESS,
	GET_AVAILABLE_SHIPPING_ADMIN_FAILURE,
	EXPORT_ORDER_INVOICE_REQUEST,
	EXPORT_ORDER_INVOICE_SUCCESS,
	EXPORT_ORDER_INVOICE_FAILURE,
} from "./actionTypes";

const initialState = {
	errors: {},
	response: {},
	notification: {},
	isLoading: false,
	orders: [],
	order: {},
	upradeProduct: {},
	macAddresses: [],
	invoice: {},
	invoice: {},
	exportOrder: {
		isLoading: false,
		details: {},
	},

	transactions: {
		isLoading: false,
		details: [],
	},
	upsOrders: {},
	shippingAdmins: {},
	assignOrders: {},
	sentToUPS: {},
	orderInvoice: {},
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case ERRORS:
			return {
				...state,
				errors: action.payload,
				isLoading: false,
			};
		case CLEAR_RESPONSE:
			return {
				...state,
				errors: {},
				response: {},
			};
		case CLEAR_NOTIFICATION:
			return {
				...state,
				errors: {},
				notification: {},
			};
		case GET_UPGRADE_ORDER_PRODUCT_REQUEST:
			return {
				...state,
				isLoading: true,
			};
		case GET_UPGRADE_ORDER_PRODUCT_SUCCESS:
		case GET_UPGRADE_ORDER_PRODUCT_FAILURE:
		case UPDATE_ORDER_CHANGE_ADDRESS_SUCCESS:
		case UPDATE_ORDER_CHANGE_ADDRESS_FAILURE:
		case UPDATE_BILLING_ADDRESS_SUCCESS:
		case UPDATE_BILLING_ADDRESS_FAILURE:
		case UPDATE_ORDER_CHANGE_METHOD_SUCCESS:
		case UPDATE_ORDER_CHANGE_METHOD_FAILURE:
			return {
				...state,
				response: action.payload,
				notification: action.payload,
				errors: {},
			};
		case UPDATE_ORDER_SUCCESS:
		case UPDATE_ORDER_FAILURE:
			return {
				...state,
				response: action.payload,
				notification: action.payload,
				errors: {},
			};

		case GET_ORDER_REQUEST:
			return {
				...state,
				isLoading: true,
			};

		case GET_ORDER_SUCCESS:
			return {
				...state,
				isLoading: false,
				orders: action.payload?.data?.orders,
			};
		case GET_ORDER_FAILURE:
			return {
				...state,
				isLoading: false,
				errors: {},
				orders: [],
			};
		case GET_AVAILABLE_UPS_ORDER_SUCCESS:
			return {
				...state,
				isLoading: false,
				upsOrders: action.payload?.data,
			};
		case GET_AVAILABLE_UPS_ORDER_FAILURE:
			return {
				...state,
				isLoading: false,
				upsOrders: [],
			};
		case GET_AVAILABLE_SHIPPING_ADMIN_SUCCESS:
			return {
				...state,
				isLoading: false,
				shippingAdmins: action.payload?.data,
			};
		case GET_AVAILABLE_SHIPPING_ADMIN_FAILURE:
			return {
				...state,
				isLoading: false,
				shippingAdmins: [],
			};

		case CREATE_SEND_ORDER_TO_UPS_SUCCESS:
			return {
				...state,
				isLoading: false,
				sentToUPS: action.payload,
				notification: action.payload,
			};
		case CREATE_SEND_ORDER_TO_UPS_FAILURE:
			return {
				...state,
				isLoading: false,
				sentToUPS: [],
				notification: action.payload,
			};
		case CREATE_ASSIGN_ORDER_TO_UPS_SUCCESS:
			return {
				...state,
				isLoading: false,
				assignOrders: action.payload,
				notification: action.payload,
			};
		case CREATE_ASSIGN_ORDER_TO_UPS_FAILURE:
			return {
				...state,
				isLoading: false,
				assignOrders: [],
				notification: action.payload,
			};

		case GET_MAC_ADDRESS_SUCCESS:
			return {
				...state,
				macAddresses: action.payload?.data,
			};
		case GET_MAC_ADDRESS_FAILURE:
			return {
				...state,
				macAddresses: [],
			};

		case GET_ORDER_WITH_ID_REQUEST:
			return {
				...state,
				isLoading: true,
			};

		case GET_ORDER_WITH_ID_SUCCESS:
			return {
				...state,
				isLoading: false,
				order: action.payload?.data,
			};
		case GET_ORDER_WITH_ID_FAILURE:
			return {
				...state,
				isLoading: false,
				errors: {},
				order: {},
			};

		case GET_INVOICE_REQUEST:
			return {
				...state,
				isLoading: true,
			};

		case GET_INVOICE_SUCCESS:
			return {
				...state,
				isLoading: false,
				invoice: action.payload?.data,
			};

		case GET_INVOICE_FAILURE:
			return {
				...state,
				isLoading: false,
				errors: {},
				invoice: {},
			};
		case GET_UPGRADE_PRODUCT_REQUEST:
			return {
				...state,
				isLoading: true,
			};

		case GET_UPGRADE_PRODUCT_SUCCESS:
			return {
				...state,
				isLoading: false,
				upradeProduct: action.payload?.data,
			};

		case GET_UPGRADE_PRODUCT_FAILURE:
			return {
				...state,
				isLoading: false,
				errors: {},
				upradeProduct: {},
			};

		case CLEAR_INVOICE_RESPONSE:
			return {
				...state,
				isLoading: false,
				invoice: {},
			};

		case EXPORT_ORDER_SUCCESS:
			return {
				...state,
				exportOrder: {
					isLoading: false,
					details: action.payload?.data,
				},
			};

		case EXPORT_ORDER_FAILURE:
			return {
				...state,
				exportOrder: {
					isLoading: false,
					details: action.payload?.data,
				},
			};

		case EXPORT_ORDER_INVOICE_SUCCESS:
			return {
				...state,
				isLoading: false,
				orderInvoice: action.payload?.data,
			};

		case EXPORT_ORDER_INVOICE_FAILURE:
			return {
				...state,
				isLoading: false,
				orderInvoice: {},
			};

		case GET_ORDER_MERCHANT_INVOICE_REQUEST:
			return {
				...state,
				transactions: {
					...state.transactions,
					isLoading: true,
				},
			};
		case GET_ORDER_MERCHANT_INVOICE_SUCCESS:
			return {
				...state,
				transactions: {
					isLoading: false,
					details: action.payload?.data,
				},
			};

		case GET_ORDER_MERCHANT_INVOICE_FAILURE:
			return {
				...state,
				transactions: {
					isLoading: false,
					details: action.payload?.data,
				},
			};

		case UPDATE_ORDER_STATUS_REQUEST:
			return {
				...state,
				isLoading: true,
			};
		case UPDATE_ORDER_STATUS_SUCCESS:
			return {
				...state,
				isLoading: false,
				response: action.payload,
				notification: action.payload,
			};
		case UPDATE_ORDER_STATUS_FAILURE:
			return {
				...state,
				isLoading: false,
				response: action.payload,
				notification: action.payload,
				errors: {},
			};
		case UPDATE_SHIPPING_DETAILS_REQUEST:
			return {
				...state,
				isLoading: true,
			};
		case UPDATE_SHIPPING_DETAILS_SUCCESS:
			return {
				...state,
				isLoading: false,
				response: action.payload,
				notification: action.payload,
			};
		case UPDATE_SHIPPING_DETAILS_FAILURE:
			return {
				...state,
				isLoading: false,
				response: action.payload,
				notification: action.payload,
				errors: {},
			};

		default:
			return state;
	}
};

export default reducer;
