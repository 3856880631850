import React, { useEffect, useState, useRef, useMemo } from "react";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Helmet } from "react-helmet";
import {
	Card,
	CardBody,
	Col,
	Container,
	Row,
	Modal,
	ModalHeader,
	ModalBody,
	FormFeedback,
	Form,
	Label,
	Input,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
//redux
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import HasAnyPermission from "../../common/Permission";
import hasPermission from "../../common/HasPermission";
import ReactDataTable from "../../common/ReactDataTable";
import isEmpty from "../../utils/isEmpty";
import { apiUrl } from "../../config";
import {
	create,
	update,
	remove,
	toggle,
	clearResponse,
} from "../../store/tax/actions";
import Swal from "sweetalert2";
import { CopyToClipboard } from "react-copy-to-clipboard";

const Tax = (props) => {
	const dispatch = useDispatch();
	const refreshTableData = useRef(null);
	const { response, auth } = useSelector((state) => ({
		response: state.tax.response,
		auth: state.auth,
	}));

	const [isOpenAddEditModal, toggleAddEditModal] = useState(false);

	const [details, handleformData] = useState({});

	useEffect(() => {
		if (response && response.code === "200") {
			refreshTableData.current();
			Swal.close();
			if (isOpenAddEditModal) toggleAddEditModal(!isOpenAddEditModal);
			toast.success(response.msg, {
				onOpen: () => {
					dispatch(clearResponse());
				},
			});
		} else if (response && response?.msg) {
			refreshTableData.current();
			Swal.close();
			toast.error(response.msg, {
				onOpen: () => {
					dispatch(clearResponse());
				},
			});
		}
	}, [dispatch, response]);

	const removeItem = (data) => {
		if (!isEmpty(data?._id)) {
			Swal.fire({
				title: "Are you sure?",
				text: "Do you really want to delete?",
				icon: "warning",
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				cancelButtonColor: "#d33",
				confirmButtonText: "Confirm",
			}).then((result) => {
				if (result.value) {
					dispatch(remove(data));
				}
			});
		}
	};

	const handleToggle = (data) => {
		Swal.fire({
			title: "Are you sure?",
			text: "Do you really want to update?",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Confirm",
		}).then((result) => {
			if (result.value) {
				Swal.fire({
					title: "Updating",
					html: "Please wait...",
					allowEscapeKey: false,
					allowOutsideClick: false,
					didOpen: () => {
						Swal.showLoading();
					},
				});
				dispatch(toggle(data));
			}
		});
	};

	let validationSchema = {
		name: Yup.string().required(),
		value: Yup.string().required("tax is a required field."),
	};

	const useFormikOptions = {
		enableReinitialize: true,
		initialValues: {
			value: details && details.value ? details.value : "",
			name: details && details.name ? details.name : "",
		},
		validationSchema: Yup.object(validationSchema),
		onSubmit: (values) => {
			if (details._id) {
				dispatch(update({ ...values, _id: details._id }));
			} else {
				dispatch(create(values));
			}
		},
	};

	const validation = useFormik(useFormikOptions);

	const handleAddEditModal = (data = null) => {
		if (!isEmpty(data) && data?._id) {
			handleformData(data);
		} else {
			handleformData({});
			validation.resetForm();
		}
		toggleAddEditModal(!isOpenAddEditModal);
	};

	const columns = () => [
		{
			label: "Name",
			name: "name",
			options: {
				filter: false,
				sort: false,
			},
		},
		{
			label: "Tax (%)",
			name: "value",
			options: {
				filter: false,
				sort: false,
			},
		},

		{
			label: "Active",
			name: "active",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (active) => {
					return (
						<div className="d-flex flex-wrap gap-2 text-center">
							<div className="square-switch">
								<Input
									type="checkbox"
									id={`active-partner-${active._id}`}
									switch="none"
									checked={active.active}
									onClick={() => {
										handleToggle(active);
									}}
									onChange={() => {
										handleToggle(active);
									}}
								/>
								<Label
									htmlFor={`active-partner-${active._id}`}
									data-on-label="Yes"
									data-off-label="No"
								></Label>
							</div>
						</div>
					);
				},
			},
		},

		{
			label: "Actions",
			name: "action",
			options: {
				filter: false,
				sort: false,
				empty: true,
				download: false,
				display: hasPermission(
					["tax update", "tax delete", "tax view"],
					auth.currentUserRolePermissions
				),
				viewColumns: hasPermission(
					["tax update", "tax delete", "tax view"],
					auth.currentUserRolePermissions
				),
				customBodyRender: (data) => {
					return (
						<div className="text-center">
							<HasAnyPermission
								permission={["tax update", "tax view"]}
							>
								<button
									onClick={(e) => handleAddEditModal(data)}
									type="button"
									className="btn btn-soft-primary waves-effect waves-light"
								>
									<i className="bx bx-edit-alt font-size-16 align-middle"></i>
								</button>
							</HasAnyPermission>
							&nbsp;
							<HasAnyPermission permission={["tax delete"]}>
								<button
									onClick={(e) => removeItem(data)}
									type="button"
									className="btn btn-soft-danger waves-effect waves-light"
								>
									<i className="bx bx-trash font-size-16 align-middle"></i>
								</button>
							</HasAnyPermission>
						</div>
					);
				},
			},
		},
	];

	const resultFormatter = (result) => {
		return result.docs.map((item) => {
			return {
				...item,
				action: item,
				active: { active: item.active, _id: item._id },
			};
		});
	};

	const couldHaveAddUpdatePermission = () => {
		const isUpdatePermission = hasPermission(
			["tax update"],
			auth.currentUserRolePermissions
		);
		const isAddPermission = hasPermission(
			["tax add"],
			auth.currentUserRolePermissions
		);
		if (isUpdatePermission && isAddPermission) return true;
		else if (isUpdatePermission && !isEmpty(details._id)) return true;
		else if (isAddPermission && isEmpty(details._id)) return true;
		else return false;
	};

	return (
		<React.Fragment>
			<div className="page-content">
				<Helmet>
					<title>Tax | LYOTECHLABS</title>
				</Helmet>
				<Container fluid>
					{/* Render Breadcrumbs */}
					<Breadcrumbs title="Tax" breadcrumbItem="List" />
					<Row>
						<Col lg="12">
							<Card>
								<CardBody>
									<Row>
										<Col xl="12">
											<div className="table-rep-plugin">
												<div className="table-responsive">
													<HasAnyPermission
														permission={[
															"tax list",
														]}
													>
														<ReactDataTable
															url={`${apiUrl}/admin/store/tax/pagination`}
															columns={columns()}
															resultFormatter={
																resultFormatter
															}
															setRefresh={
																refreshTableData
															}
															disableFilterIcon={
																false
															}
															disableSearchIcon={
																false
															}
															origin={
																<div className="row">
																	<div className="col-auto h4">
																		Tax
																		&nbsp;
																		<HasAnyPermission
																			permission={[
																				"tax add",
																			]}
																		>
																			<button
																				onClick={() => {
																					handleAddEditModal();
																				}}
																				type="button"
																				className="btn btn-primary waves-effect waves-light"
																			>
																				<i className="bx bx-plus-medical font-size-16 align-middle"></i>
																			</button>
																		</HasAnyPermission>
																	</div>
																</div>
															}
															rowsPerPage={10}
														/>
													</HasAnyPermission>

													<Modal
														isOpen={
															isOpenAddEditModal
														}
														toggle={
															handleAddEditModal
														}
														size="lg"
														centered={true}
													>
														<ModalHeader
															toggle={
																handleAddEditModal
															}
															tag="h4"
														>
															{details?._id
																? "Edit"
																: "Add"}
														</ModalHeader>
														<ModalBody>
															<fieldset
																disabled={
																	!couldHaveAddUpdatePermission()
																}
															>
																<Form
																	onSubmit={(
																		e
																	) => {
																		e.preventDefault();
																		validation.handleSubmit();
																		return false;
																	}}
																>
																	<Row
																		form={
																			"true"
																		}
																	>
																		<Col
																			xs={
																				12
																			}
																		>
																			<div className="mb-3">
																				<Label className="form-label">
																					Name
																				</Label>
																				<Input
																					name="name"
																					type="text"
																					onChange={
																						validation.handleChange
																					}
																					onBlur={
																						validation.handleBlur
																					}
																					value={
																						validation
																							.values
																							.name ||
																						""
																					}
																					invalid={
																						validation
																							.touched
																							.name &&
																						validation
																							.errors
																							.name
																							? true
																							: false
																					}
																				/>
																				{validation
																					.touched
																					.name &&
																				validation
																					.errors
																					.name ? (
																					<FormFeedback type="invalid">
																						{
																							validation
																								.errors
																								.name
																						}
																					</FormFeedback>
																				) : null}
																			</div>
																		</Col>
																	</Row>

																	{/* <Row
																		form={
																			"true"
																		}
																	>
																		<Col
																			xs={
																				12
																			}
																		>
																			<div className="mb-3">
																				<Label className="form-label">
																					Type
																				</Label>
																				<Input
																					name="type"
																					type="text"
																					onChange={
																						validation.handleChange
																					}
																					onBlur={
																						validation.handleBlur
																					}
																					value={
																						validation
																							.values
																							.type ||
																						""
																					}
																					invalid={
																						validation
																							.touched
																							.type &&
																						validation
																							.errors
																							.type
																							? true
																							: false
																					}
																				/>
																				{validation
																					.touched
																					.type &&
																				validation
																					.errors
																					.type ? (
																					<FormFeedback type="invalid">
																						{
																							validation
																								.errors
																								.type
																						}
																					</FormFeedback>
																				) : null}
																			</div>
																		</Col>
																	</Row> */}

																	<Row
																		form={
																			"true"
																		}
																	>
																		<Col
																			xs={
																				12
																			}
																		>
																			<div className="mb-3">
																				<Label className="form-label">
																					Tax
																					(%)
																				</Label>
																				<Input
																					name="value"
																					type="text"
																					onChange={
																						validation.handleChange
																					}
																					onBlur={
																						validation.handleBlur
																					}
																					value={
																						validation
																							.values
																							.value ||
																						""
																					}
																					invalid={
																						validation
																							.touched
																							.value &&
																						validation
																							.errors
																							.value
																							? true
																							: false
																					}
																				/>
																				{validation
																					.touched
																					.value &&
																				validation
																					.errors
																					.value ? (
																					<FormFeedback type="invalid">
																						{
																							validation
																								.errors
																								.value
																						}
																					</FormFeedback>
																				) : null}
																			</div>
																		</Col>
																	</Row>

																	<Row>
																		<Col>
																			<div className="text-end mt-3">
																				<button
																					type="submit"
																					className="btn btn-success save-user"
																				>
																					Save
																				</button>
																			</div>
																		</Col>
																	</Row>
																</Form>
															</fieldset>
														</ModalBody>
													</Modal>
												</div>
											</div>
										</Col>
									</Row>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default Tax;
