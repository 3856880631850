import React, { useEffect, useState, useRef, useMemo } from "react";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
	Card,
	CardBody,
	CardHeader,
	Col,
	Container,
	Row,
	Modal,
	ModalHeader,
	ModalBody,
	Button,
	FormFeedback,
	Form,
	Label,
	Input,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
//redux
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import HasAnyPermission from "../../common/Permission";
import hasPermission from "../../common/HasPermission";
import ReactDataTable from "../../common/ReactDataTable";
import { apiUrl } from "../../config";
import Swal from "sweetalert2";
import isEmpty from "../../utils/isEmpty";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import {
	create,
	update,
	remove,
	toggle,
	getOrders,
	clearResponse,
	clearNotification,
	getMacAddress,
	getInvoice,
	getUpgradeProduct,
	upgradeOrderProduct,
	clearInvoice,
	getExportOrderCSV,
	updateOrderChangeAddress,
	updateOrderMethod,
	updateOrderStatus,
	getOrder,
	updateShippingDetails,
	updateBillingAddress,
	getExportOrderInvoice,
} from "../../store/order/actions";
import Select from "react-select";

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import {
	paymentMethodoOptions,
	orderStatusOptions,
	orderType,
} from "../../common/FilterOptions";
import { getCountries } from "../../store/shipping/actions";
import makeAnimated from "react-select/animated";
import { FlashOnRounded } from "@mui/icons-material";

let editor;
const Order = (props) => {
	const dispatch = useDispatch();
	const animatedComponents = makeAnimated();
	const refreshTableData = useRef(null);
	const { auth, order, countries } = useSelector((state) => ({
		order: state.order,
		auth: state.auth,
		countries: state.shipping.countries,
	}));

	const [dispatchedDetails, setDispatchedDetails] = useState([{}]);
	const [availableMacAddresses, setAvailableMacAddresses] = useState([{}]);

	const {
		response,
		notification,
		orders,
		isLoading,
		macAddresses,
		invoice,
		upradeProduct,
		exportOrder,
	} = order;
	const [isOpenAddEditModal, toggleAddEditModal] = useState(false);
	const [isOpenInvoiceModal, toggleInvoiceModal] = useState(false);
	const [isOpenUpgradeOrderModal, toggleUpgradeOrderModal] = useState(false);
	const [isOpenUpdateAddressOrderModal, toggleUpdateAddressOrderModal] =
		useState(false);
	const [isOpenUpdateBillingAddressModal, toggleUpdateBillingAddressModal] =
		useState(false);
	const [isOpenUpdateMethodOrderModal, toggleUpdateMethodOrderModal] =
		useState(false);
	const [isOpenUpdateOrderStatusModal, toggleUpdateOrderStatusModal] =
		useState(false);
	const [isOpenEditShippingDetailsModal, toggleEditShippingDetailsModal] =
		useState(false);
	const [selectedOrder, setSelectedOrder] = useState({});
	const [details, handleformData] = useState({});
	const [shippingDetails, handleShippingformData] = useState({});
	const [linkedDeviceDetails, handleLinkDeviceData] = useState({});
	const { OrderDetails } = useSelector((state) => ({
		OrderDetails: state.order,
	}));

	const orderDetails = order.details;
	useEffect(() => {
		dispatch(getCountries());
		dispatch(getUpgradeProduct());
		dispatch(getMacAddress());
	}, []);

	const getOrGenerateInvoice = (order) => {
		Swal.fire({
			title: "Please wait...",
			html: "Please wait...",
			allowEscapeKey: false,
			allowOutsideClick: false,
			didOpen: () => {
				Swal.showLoading();
			},
		});
		dispatch(getInvoice({ orderId: order._id }));
	};

	const handleOrderInvoice = () => {
		if (isOpenInvoiceModal === true) {
			dispatch(clearInvoice());
		}
		toggleInvoiceModal(!isOpenInvoiceModal);
	};

	useEffect(() => {
		Swal?.close();
		if (invoice?.link) {
			toggleInvoiceModal(true);
		} else {
			document.body.style.overflow = "auto";
		}
	}, [invoice]);

	useEffect(() => {
		if (exportOrder?.isLoading === false) {
			Swal?.close();
		}
		if (exportOrder.details?.link) {
			const link = document.createElement("a");
			link.href = exportOrder.details.link;
			link.download = `orders.csv`;
			link.dispatchEvent(new MouseEvent("click"));
			toast.success("CSV file has been downloaded");
		}
	}, [exportOrder]);

	useEffect(() => {
		if (
			notification &&
			(notification.status === 200 || notification.code === "200")
		) {
			refreshTableData.current();
			isOpenUpdateAddressOrderModal && handleUpdateAddressOrderModal();
			isOpenUpdateMethodOrderModal && handleUpdateMethodOrderModal();
			isOpenUpdateOrderStatusModal && handleUpdateOrderStatusModal();
			isOpenEditShippingDetailsModal && handleEditShippingDetailsModal();
			isOpenUpdateBillingAddressModal &&
				toggleUpdateBillingAddressModal();
			toast.success(notification.msg, {
				onOpen: () => {
					dispatch(clearNotification());
				},
			});
		} else if (notification && notification?.msg) {
			// refreshTableData.current();
			toast.error(notification.msg, {
				onOpen: () => {
					dispatch(clearNotification());
				},
			});
		}
		Swal?.close();
	}, [dispatch, notification]);

	useEffect(() => {
		if (response && response.status === 200) {
			if (response?.data?.upgradeDetails) {
				handleUpgradeOrderModal();
			}
		}
	}, [dispatch, response]);

	useEffect(() => {
		if (order) {
			handleShippingformData(order?.order?.details);
			handleLinkDeviceData(order?.order?.linkedDevice);
		}
	}, [order]);

	const countryOptionDropdown = useMemo(() => {
		const result = countries?.map((item) => ({
			label: `${item.name}`,
			value: `${item.name}`,
		}));
		return result ?? [];
	}, [countries]);

	const countryCodeDropdown = useMemo(() => {
		const result = countries?.map((item) => ({
			label: `${item.name} (+ ${item.phoneCode})`,
			value: item.phoneCode,
		}));
		return result ?? [];
	}, [countries]);

	const getStatuses = useMemo(() => {
		let orderStatuses = [];
		if (selectedOrder?.status === "hold") {
			orderStatuses = ["processing"];
		}
		if (
			selectedOrder?.status === "processing" &&
			(selectedOrder?.shippingType !== "local-pickup" ||
				selectedOrder?.shippingType !== "self-pickup" ||
				selectedOrder?.shippingType !== "picked-up")
		) {
			orderStatuses = ["dispatched"];
		}
		if (
			selectedOrder?.status === "processing" &&
			(selectedOrder?.shippingType === "local-pickup" ||
				selectedOrder?.shippingType === "self-pickup" || 
				selectedOrder?.shippingType === "picked-up")
		) {
			orderStatuses = ["self-pickup", "picked-up"];
		}
		// if (
		// 	selectedOrder?.status === "shipped" &&
		// 	selectedOrder?.shippingType !== "local-pickup"
		// ) {
		// 	orderStatuses = ["dispatched", "delivered"];
		// }
		if (selectedOrder?.status === "dispatched") {
			orderStatuses = ["shipped"];
		}
		if (selectedOrder?.status === "shipped") {
			orderStatuses = ["delivered"];
		}

		const result = orderStatuses?.map((item) => ({
			label: `${item}`,
			value: item,
		}));

		return result ?? [];
	}, [selectedOrder]);

	const handleUpgradeOrderModal = () => {
		if (isOpenUpgradeOrderModal === true) {
			dispatch(clearResponse());
		}
		toggleUpgradeOrderModal(!isOpenUpgradeOrderModal);
	};

	const handleUpdateOrder = (data) => {
		setSelectedOrder(data);
		handleUpdateAddressOrderModal();
	};

	const handleUpdate = (data) => {
		setSelectedOrder(data);
		handleUpdateAddressOrderModal();
	};

	const handleUpdateBillingAddressModal = (data) => {
		setSelectedOrder(data);
		toggleUpdateBillingAddressModal(!isOpenUpdateBillingAddressModal);
	};

	const handleUpdateAddressOrderModal = () => {
		if (isOpenUpdateAddressOrderModal === true) {
			// dispatch(clearResponse());
		}
		toggleUpdateAddressOrderModal(!isOpenUpdateAddressOrderModal);
	};

	const handleUpdateMethodOrderModal = (data = null) => {
		data && setSelectedOrder(data);
		if (isOpenUpdateMethodOrderModal === true) {
			// dispatch(clearResponse());
		}
		toggleUpdateMethodOrderModal(!isOpenUpdateMethodOrderModal);
	};

	const handleUpdateOrderStatusModal = (data = null) => {
		validationChangeStatusMethod.resetForm();
		setSelectedOrder(data);
		if (isOpenUpdateOrderStatusModal === true) {
			dispatch(clearResponse());
		}
		toggleUpdateOrderStatusModal(!isOpenUpdateOrderStatusModal);
	};

	const dispatchOrder = () => {
		if (!isEmpty(details?._id)) {
			Swal.fire({
				title: "Are you sure?",
				text: "Do you really want to dispatch?",
				icon: "warning",
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				cancelButtonColor: "#d33",
				confirmButtonText: "Confirm",
			}).then((result) => {
				if (result.value) {
					// dispatch(remove(data));
				}
			});
		}
	};

	const updateOrder = (order) => {
		if (!isEmpty(order)) {
			Swal.fire({
				title: "Are you sure?",
				text: "Do you really want to change status processing and send callback?",
				icon: "warning",
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				cancelButtonColor: "#d33",
				confirmButtonText: "Confirm",
			}).then((result) => {
				if (result.value) {
					Swal.fire({
						title: "Updating",
						html: "Please wait...",
						allowEscapeKey: false,
						allowOutsideClick: false,
						didOpen: () => {
							Swal.showLoading();
						},
					});
					dispatch(update({ _id: order._id }));
				}
			});
		}
	};

	const generateCSV = () => {
		Swal.fire({
			title: "Are you sure?",
			text: "Do you really want to export csv?",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Confirm",
		}).then((result) => {
			if (result.value) {
				Swal.fire({
					title: "Updating",
					html: "Please wait...",
					allowEscapeKey: false,
					allowOutsideClick: false,
					didOpen: () => {
						Swal.showLoading();
					},
				});
				dispatch(getExportOrderCSV());
			}
		});
	};

	const handleTooltipOpen = () => {
		toast.success("Copied to clipboard.");
	};

	const getCountryName = (countryCode) => {
		return countries?.find((item) => item.phoneCode === countryCode);
	};

	const getInitialValue = useMemo(() => {
		const countryDetails = getCountryName(
			selectedOrder?.details?.shippingAddress?.countryCode
		);
		let initialValues = {
			firstName: selectedOrder?.details?.shippingAddress?.firstName ?? "",
			lastName: selectedOrder?.details?.shippingAddress?.lastName ?? "",
			city: selectedOrder?.details?.shippingAddress?.city ?? "",
			phone: selectedOrder?.details?.shippingAddress?.phone ?? "",
			// countryCode: Yup.object().required(),
			postalCode:
				selectedOrder?.details?.shippingAddress?.postalCode ?? "",
			address: selectedOrder?.details?.shippingAddress?.address ?? "",
			country: selectedOrder?.details?.shippingAddress?.country
				? {
						label: `${selectedOrder?.details?.shippingAddress?.country}`,
						value: selectedOrder?.details?.shippingAddress?.country,
				  }
				: null,
			countryCode: selectedOrder?.details?.shippingAddress?.countryCode
				? {
						label: `${countryDetails.name} (+ ${selectedOrder.details.shippingAddress.countryCode})`,
						value: selectedOrder.details.shippingAddress
							.countryCode,
				  }
				: null,
			sendEmailPhone: ["off"],
			sendEmailAddress: ["off"],
			sendEmailName: ["off"],
		};

		return initialValues;
	}, [selectedOrder?.details?.shippingAddress]);

	const getInitialValuesBillingAddress = useMemo(() => {
		const countryDetails = getCountryName(
			selectedOrder?.details?.billingAddress?.countryCode
		);
		let initialValues = {
			firstName: selectedOrder?.details?.billingAddress?.firstName ?? "",
			lastName: selectedOrder?.details?.billingAddress?.lastName ?? "",
			city: selectedOrder?.details?.billingAddress?.city ?? "",
			phone: selectedOrder?.details?.billingAddress?.phone ?? "",
			// countryCode: Yup.object().required(),
			postalCode:
				selectedOrder?.details?.billingAddress?.postalCode ?? "",
			address: selectedOrder?.details?.billingAddress?.address ?? "",
			country: selectedOrder?.details?.billingAddress?.country
				? {
						label: `${selectedOrder?.details?.billingAddress?.country}`,
						value: selectedOrder?.details?.billingAddress?.country,
				  }
				: null,
			countryCode: selectedOrder?.details?.billingAddress?.countryCode
				? {
						label: `${countryDetails.name} (+ ${selectedOrder.details.billingAddress.countryCode})`,
						value: selectedOrder.details.billingAddress.countryCode,
				  }
				: null,
			sendEmailPhone: ["off"],
			sendEmailAddress: ["off"],
			sendEmailName: ["off"],
		};

		return initialValues;
	}, [selectedOrder?.details?.billingAddress]);

	const useFormikOptions = {
		enableReinitialize: true,
		initialValues: getInitialValue,
		validationSchema: Yup.object({
			firstName: Yup.string().required(),
			lastName: Yup.string().required(),
			country: Yup.object().required(),
			city: Yup.string().required(),
			phone: Yup.string(),
			countryCode: Yup.object().required().nullable(),
			postalCode: Yup.string(),
			address: Yup.string().required(),
		}),
		onSubmit: (values) => {
			Swal.fire({
				title: "Updating",
				html: "Please wait...",
				allowEscapeKey: false,
				allowOutsideClick: false,
				didOpen: () => {
					Swal.showLoading();
				},
			});
			const emailSendActions = [];
			if (values.sendEmailAddress?.includes("on")) {
				emailSendActions.push("Address");
			}
			if (values.sendEmailPhone?.includes("on")) {
				emailSendActions.push("Phone");
			}
			if (values.sendEmailName?.includes("on")) {
				emailSendActions.push("Name");
			}
			dispatch(
				updateOrderChangeAddress({
					...values,
					emailSendActions: emailSendActions,
					country: values.country?.label,
					orderId: selectedOrder._id,
					countryCode: values.countryCode?.value,
				})
			);
		},
	};

	const validation = useFormik(useFormikOptions);

	const useFormikOptionsBillingAddress = {
		enableReinitialize: true,
		initialValues: getInitialValuesBillingAddress,
		validationSchema: Yup.object({
			firstName: Yup.string().required(),
			lastName: Yup.string().required(),
			country: Yup.object().required(),
			city: Yup.string().required(),
			phone: Yup.string(),
			countryCode: Yup.object().required().nullable(),
			postalCode: Yup.string(),
			address: Yup.string().required(),
		}),
		onSubmit: (values) => {
			Swal.fire({
				title: "Updating",
				html: "Please wait...",
				allowEscapeKey: false,
				allowOutsideClick: false,
				didOpen: () => {
					Swal.showLoading();
				},
			});
			const emailSendActions = [];
			if (values.sendEmailAddress?.includes("on")) {
				emailSendActions.push("Address");
			}
			if (values.sendEmailPhone?.includes("on")) {
				emailSendActions.push("Phone");
			}
			if (values.sendEmailName?.includes("on")) {
				emailSendActions.push("Name");
			}
			dispatch(
				updateBillingAddress({
					...values,
					emailSendActions: emailSendActions,
					country: values.country?.label,
					orderId: selectedOrder._id,
					countryCode: values.countryCode?.value,
				})
			);
		},
	};

	const validationBillingAddress = useFormik(useFormikOptionsBillingAddress);

	const useFormikOptionUpdateMethod = {
		enableReinitialize: true,
		initialValues: {
			shippingMethod: "self-pickup",
			pickupDate: null,
			pickupAddress: "",
			sendEmail: ["on"],
		},
		validationSchema: Yup.object({
			shippingMethod: Yup.string().required(),
			pickupDate: Yup.date().required(),
			pickupAddress: Yup.string().required(),
		}).when((values, schema) => {
			let validationData = {};
			if (values.shippingMethod?.value === "self-pickup" || values.shippingMethod?.value === "picked-up") {
				validationData.pickupDate = Yup.date().required();
				validationData.pickupAddress = Yup.string().required();
			}
			return schema.shape(validationData);
		}),
		onSubmit: (values) => {
			Swal.fire({
				title: "Updating",
				html: "Please wait...",
				allowEscapeKey: false,
				allowOutsideClick: false,
				didOpen: () => {
					Swal.showLoading();
				},
			});
			dispatch(
				updateOrderMethod({
					...values,
					pickupDate: values.pickupDate,
					orderId: selectedOrder._id,
					sendEmail: values.sendEmail?.[0] === "on" ? "yes" : "no",
				})
			);
		},
	};

	const validationChangeMethod = useFormik(useFormikOptionUpdateMethod);

	const useFormikOptionUpdateOrderStatus = {
		enableReinitialize: true,
		initialValues: {
			status: "",
			sn: "",
			// sendEmail: ["on"],
		},
		validationSchema: Yup.object({
			status: Yup.object().required(),
			// sn: Yup.string().required(),
			// sendEmail: Yup.array().required(),
		}).when((values, schema) => {
			let validationData = {};
			if (
				values.status?.value === "dispatched" ||
				values.status?.value === "self-pickup" ||
				values.status?.value === "picked-up"
			) {
				validationData.sn = Yup.string().required();
			}
			return schema.shape(validationData);
		}),
		onSubmit: (values) => {
			Swal.fire({
				title: "Updating",
				html: "Please wait...",
				allowEscapeKey: false,
				allowOutsideClick: false,
				didOpen: () => {
					Swal.showLoading();
				},
			});
			dispatch(
				updateOrderStatus({
					...values,
					orderId: selectedOrder._id,
					status: values.status?.label,
					// sendEmail: values.sendEmail?.[0] === "on" ? "yes" : "no",
				})
			);
		},
	};

	const validationChangeStatusMethod = useFormik(
		useFormikOptionUpdateOrderStatus
	);

	const useFormikOptionEditShippingDetails = {
		enableReinitialize: true,
		initialValues: {
			trackingNumber: "",
			sn: "",
			// sendEmail: ["on"],
		},
		validationSchema: Yup.object({
			// status: Yup.object().required(),
			// sn: Yup.string().required(),
			// sendEmail: Yup.array().required(),
		}),
		onSubmit: (values) => {
			console.log("🚀 ~ file: index.js:491 ~ Order ~ values:", values);
			Swal.fire({
				title: "Updating",
				html: "Please wait...",
				allowEscapeKey: false,
				allowOutsideClick: false,
				didOpen: () => {
					Swal.showLoading();
				},
			});
			dispatch(
				updateShippingDetails({
					...values,
					orderId: selectedOrder._id,
					// status: values.status?.label,
					// sendEmail: values.sendEmail?.[0] === "on" ? "yes" : "no",
				})
			);
		},
	};

	const validationEditShippedDetails = useFormik(
		useFormikOptionEditShippingDetails
	);

	// const formatOrderProduct = (products) => {
	// 	return products.map((product) => product[Object.keys(product)[0]]);
	// };

	const handleAddEditModal = (data = null) => {
		if (!isEmpty(data) && data?.id) {
			// const products = formatOrderProduct(data.details.line_items);
			setDispatchedDetails({});
			handleformData({
				...data,
				// details: { ...data.details, line_items: products },
			});
		} else {
			handleformData({});
			validation.resetForm();
		}
		toggleAddEditModal(!isOpenAddEditModal);
	};

	const handleEditShippingDetailsModal = (data = null) => {
		setSelectedOrder(data);
		if (!isEmpty(data) && data?.id) {
			dispatch(getOrder({ id: data?.id }));
		} else {
			handleShippingformData({});
			handleLinkDeviceData({});
			validationEditShippedDetails.resetForm();
		}
		toggleEditShippingDetailsModal(!isOpenEditShippingDetailsModal);
	};

	useEffect(() => {
		setAvailableMacAddresses(macAddresses);
	}, [macAddresses]);

	const getMacAddressOptions = useMemo(() => {
		const result = availableMacAddresses?.map((item, index) => ({
			label: `${item.macAddress}`,
			index: index,
			value: item._id,
			_id: item._id,
			macAddress: item.macAddress,
		}));
		return result ?? [];
	}, [availableMacAddresses]);

	const handleChangeMacAddress = (index, assignDetails) => {
		let updatedMacAddress = availableMacAddresses;
		if (dispatchedDetails?.[index]) {
			updatedMacAddress = [
				...updatedMacAddress,
				dispatchedDetails[index],
			];
			setAvailableMacAddresses([
				...availableMacAddresses,
				dispatchedDetails[index],
			]);
		} else {
			availableMacAddresses.splice(assignDetails.index, 1);
			setAvailableMacAddresses(availableMacAddresses);
		}
		setDispatchedDetails({
			...dispatchedDetails,
			[index]: assignDetails,
		});
	};

	const handleChange = (index) => (e) => {
		setDispatchedDetails({
			...dispatchedDetails,
			[index]: { imei: e.target.value },
		});
	};

	const randomAssignMacAddress = (index, productId) => {
		const macAddress =
			availableMacAddresses[
				Math.floor(Math.random() * availableMacAddresses.length)
			];
		handleChangeMacAddress(index, {
			...macAddress,
			label: macAddress.macAddress,
			value: macAddress._id,
			productId: productId,
		});
	};

	const columns = () => [
		{
			label: "OrderID",
			name: "_id",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (rowData) => {
					return `#${rowData}`;
				},
			},
		},
		{
			label: "Order Type",
			name: "orderType",
			options: {
				customBodyRender: (rowData) => {
					return `${rowData?.partner ? "CLOUDX" : "WEWE"}`;
				},
				filter: false,
				// customFilterListOptions: {
				// 	render: (v) => v.map((l) => orderType[l]),
				// },
				// filterType: "custom",
				// filterOptions: {
				// 	names: [],
				// 	display: (filterList, onChange, index, column) => {
				// 		return (
				// 			<div className="auto">
				// 				{/* <label
				// 					htmlFor="currency-transaction"
				// 					className="form-label font-size-13 text-muted"
				// 				></label> */}
				// 				<select
				// 					value={filterList[index][0] || ""}
				// 					onChange={(event) => {
				// 						filterList[index][0] =
				// 							event.target.value;
				// 						onChange(
				// 							filterList[index],
				// 							index,
				// 							column
				// 						);
				// 					}}
				// 					className="form-control"
				// 					name="currency-transaction"
				// 				>
				// 					<option disabled value="">
				// 						Order Type
				// 					</option>

				// 					{Object.entries(orderType).map(
				// 						([key, value]) => (
				// 							<option key={key} value={key}>
				// 								{value}
				// 							</option>
				// 						)
				// 					)}
				// 				</select>
				// 			</div>
				// 		);
				// 	},
				// },
				sort: false,
			},
		},
		{
			label: "Shipping Name",
			name: "name",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (rowData) => {
					return rowData ? (
						rowData
					) : (
						<div className="text-center">-</div>
					);
				},
				download: false,
			},
		},
		{
			label: "Email",
			name: "email",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (rowData) => {
					return rowData ? (
						rowData
					) : (
						<div className="text-center">-</div>
					);
				},
				download: false,
			},
		},
		{
			label: "Product Name",
			name: "productName",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (rowData) => {
					return rowData ? (
						rowData
					) : (
						<div className="text-center">-</div>
					);
				},
				download: false,
			},
		},
		{
			label: "LIC Number",
			name: "licNumber",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (rowData) => {
					return rowData ? (
						rowData
					) : (
						<div className="text-center">-</div>
					);
				},
			},
		},
		{
			label: "WP Order ID",
			name: "wpId",
			options: {
				filter: false,
				sort: false,
			},
		},
		{
			label: "Order Amount",
			name: "totalAmount",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (rowData) => {
					return `${rowData}`;
				},
			},
		},
		{
			label: "Paymet Method",
			name: "paymentMethod",
			options: {
				customFilterListOptions: {
					render: (v) => v.map((l) => paymentMethodoOptions[l]),
				},
				filterType: "custom",
				filterOptions: {
					names: [],
					display: (filterList, onChange, index, column) => {
						return (
							<div className="auto">
								{/* <label
									htmlFor="currency-transaction"
									className="form-label font-size-13 text-muted"
								></label> */}
								<select
									value={filterList[index][0] || ""}
									onChange={(event) => {
										filterList[index][0] =
											event.target.value;
										onChange(
											filterList[index],
											index,
											column
										);
									}}
									className="form-control"
									name="currency-transaction"
								>
									<option disabled value="">
										Payment Method
									</option>

									{Object.entries(paymentMethodoOptions).map(
										([key, value]) => (
											<option key={key} value={key}>
												{value}
											</option>
										)
									)}
								</select>
							</div>
						);
					},
				},
				sort: false,
			},
		},
		// {
		// 	label: "Currency",
		// 	name: "currency",
		// 	options: {
		// 		filter: false,
		// 		sort: false,
		// 		download: false,
		// 		customBodyRender: (rowData) => {
		// 			return `${rowData}`;
		// 		},
		// 	},
		// },

		{
			name: "status",
			label: "Status",
			options: {
				customFilterListOptions: {
					render: (v) =>
						v.map((l) => l.charAt(0).toUpperCase() + l.slice(1)),
				},
				filterType: "custom",
				filterOptions: {
					names: [],
					display: (filterList, onChange, index, column) => {
						return (
							<div className="auto">
								{/* <label
									htmlFor="currency-transaction"
									className="form-label font-size-13 text-muted"
								></label> */}
								<select
									value={filterList[index][0] || ""}
									onChange={(event) => {
										filterList[index][0] =
											event.target.value;
										onChange(
											filterList[index],
											index,
											column
										);
									}}
									className="form-control"
									name="currency-transaction"
								>
									<option disabled value="">
										Status
									</option>
									{Object.entries(orderStatusOptions).map(
										([key, value]) => (
											<option key={key} value={key}>
												{value}
											</option>
										)
									)}
								</select>
							</div>
						);
					},
				},
				sort: false,
				customBodyRender: (status) => {
					return status === "processing" ? (
						<div className="badge badge-soft-primary font-size-12">
							Processing
						</div>
					) : status === "pending" ? (
						<div className="badge badge-soft-warning font-size-12">
							Pending
						</div>
					) : status === "shipped" ? (
						<div className="badge badge-soft-success font-size-12">
							Shipped
						</div>
					) : status === "label-created" ? (
						<div className="badge badge-soft-success font-size-12">
							Label Created
						</div>
					) : status === "cancelled" ? (
						<div className="badge badge-soft-danger font-size-12">
							Cancelled
						</div>
					) : (
						<div className="badge badge-soft-success font-size-12 text-capitalize">
							{status}
						</div>
					);
				},
			},
		},

		{
			name: "UPSStatus",
			label: "UPS status",
			options: {
				// customFilterListOptions: {
				// 	render: (v) =>
				// 		v.map((l) => l.charAt(0).toUpperCase() + l.slice(1)),
				// },
				// filterType: "custom",
				// filterOptions: {
				// 	names: [],
				// 	display: (filterList, onChange, index, column) => {
				// 		return (
				// 			<div className="auto">
				// 				{/* <label
				// 					htmlFor="currency-transaction"
				// 					className="form-label font-size-13 text-muted"
				// 				></label> */}
				// 				<select
				// 					value={filterList[index][0] || ""}
				// 					onChange={(event) => {
				// 						filterList[index][0] =
				// 							event.target.value;
				// 						onChange(
				// 							filterList[index],
				// 							index,
				// 							column
				// 						);
				// 					}}
				// 					className="form-control"
				// 					name="currency-transaction"
				// 				>
				// 					<option disabled value="">
				// 						Status
				// 					</option>
				// 					{Object.entries(orderStatusOptions).map(
				// 						([key, value]) => (
				// 							<option key={key} value={key}>
				// 								{value}
				// 							</option>
				// 						)
				// 					)}
				// 				</select>
				// 			</div>
				// 		);
				// 	},
				// },
				sort: false,
				filter: false,
				customBodyRender: (status) => {
					return status ? status : "-";
				},
			},
		},
		{
			name: "shippingType",
			label: "Shipping Method",
			options: {
				sort: false,
				filter: false,
				customBodyRender: (shippingType) => {
					return shippingType === "local-pickup"
						? "Self Pickup"
						: shippingType;
				},
			},
		},

		{
			label: "Date",
			name: "createdAt",
			options: {
				customBodyRender: (date) => {
					return moment(date).format("LLL");
				},
				filterType: "custom",
				customFilterListOptions: {
					render: (v) => {
						if (v?.[0])
							return `From Date : ${moment(v[0]).format(
								"MM/DD/YYYY"
							)} - To Date : ${moment(v[1]).format(
								"MM/DD/YYYY"
							)}`;
						return [];
					},
					update: (filterList, filterPos, index) => {
						filterList[index] = [];
						return filterList;
					},
				},
				filterOptions: {
					display: (filterList, onChange, index, column) => {
						return (
							<div className="auto">
								{/* <label
									htmlFor="currency-transaction"
									className="form-label font-size-13 text-muted"
								></label> */}
								<div className="input-group">
									<Flatpickr
										className="form-control d-block"
										placeholder="Date Range"
										options={{
											mode: "range",
											dateFormat: "m/d/Y",
										}}
										value={
											filterList[index] || [
												new Date(),
												new Date(),
											]
										}
										onChange={(date) => {
											filterList[index] = date;
											onChange(
												filterList[index],
												index,
												column
											);
										}}
									/>
								</div>
							</div>
						);
					},
				},
				sort: true,
			},
		},

		{
			label: "Actions",
			name: "action",
			options: {
				filter: false,
				sort: false,
				empty: true,
				download: false,
				display: hasPermission(
					["order update", "order delete", "order view"],
					auth.currentUserRolePermissions
				),
				viewColumns: hasPermission(
					["order update", "order delete", "order view"],
					auth.currentUserRolePermissions
				),
				customBodyRender: (data) => {
					return (
						<div className="text-center">
							<HasAnyPermission permission={["order view"]}>
								<Link
									to={`/shop/order/${data._id}`}
									className="btn m-1 btn-soft-primary waves-effect waves-light"
								>
									View
								</Link>
							</HasAnyPermission>

							{(data.status === "processing" ||
								data.status === "hold") &&
							(data?.shippingType !== "local-pickup" ||
								data?.shippingType !== "self-pickup") ? (
								<HasAnyPermission
									permission={[
										"order shipping address update",
									]}
								>
									<button
										onClick={(e) => {
											e.preventDefault();
											handleUpdateOrder(data);
										}}
										type="button"
										className="btn m-1 btn-soft-success waves-effect waves-light"
									>
										Update Shippping Address
									</button>
								</HasAnyPermission>
							) : null}

							{(data.status === "processing" ||
								data.status === "hold") &&
							(data?.shippingType !== "local-pickup" ||
								data?.shippingType !== "self-pickup") ? (
								<HasAnyPermission
									permission={[
										"order shipping address update",
									]}
								>
									<button
										onClick={(e) => {
											e.preventDefault();
											handleUpdateBillingAddressModal(
												data
											);
										}}
										type="button"
										className="btn m-1 btn-soft-success waves-effect waves-light"
									>
										Update Billing Address
									</button>
								</HasAnyPermission>
							) : null}

							{data.status === "processing" &&
							["local-pickup", "self-pickup", "picked-up"].includes(
								data?.shippingType
							) === false ? (
								<HasAnyPermission
									permission={["order shipping method"]}
								>
									<button
										onClick={(e) => {
											e.preventDefault();
											handleUpdateMethodOrderModal(data);
										}}
										type="button"
										className="btn m-1 btn-soft-primary waves-effect waves-light"
									>
										Update Shipping Method
									</button>
								</HasAnyPermission>
							) : null}

							{data.status === "processing" ||
							data.status === "hold" ||
							(data.status === "shipped" &&
								(data?.shippingType !== "local-pickup" ||
									data?.shippingType !== "self-pickup")) ||
							data.status === "dispatched" ? (
								<HasAnyPermission
									permission={["order status update"]}
								>
									<button
										onClick={(e) => {
											e.preventDefault();
											handleUpdateOrderStatusModal(data);
										}}
										type="button"
										className="btn m-1 btn-soft-success waves-effect waves-light"
									>
										Update Status
									</button>
								</HasAnyPermission>
							) : null}

							{data.status === "shipped" && (
								<HasAnyPermission permission={["order update"]}>
									<button
										onClick={(e) => {
											e.preventDefault();
											handleEditShippingDetailsModal(
												data
											);
										}}
										type="button"
										className="btn m-1 btn-soft-primary waves-effect waves-light"
									>
										Edit Shipping Details
									</button>
								</HasAnyPermission>
							)}

							{["cancelled", "pending", "failed"].includes(
								data.status
							) === false ? (
								<HasAnyPermission
									permission={["order status update"]}
								>
									<button
										onClick={(e) => {
											e.preventDefault();
											getOrGenerateInvoice({
												...data,
												orderId: data._id,
											});
											// dispatch(
											// 	getExportOrderInvoice({
											// 		orderId: data._id,
											// 	})
											// );
										}}
										type="button"
										className="btn m-1 btn-soft-secondary waves-effect waves-light"
									>
										Download Invoice
									</button>
								</HasAnyPermission>
							) : null}
						</div>
					);
				},
			},
		},
	];

	const resultFormatter = (result) => {
		return result.docs.map((item) => {
			return {
				...item,
				name: `${
					item?.details?.shippingAddress?.firstName
						? item?.details?.shippingAddress?.firstName
						: item.userId.firstName
				} ${
					item?.details?.shippingAddress?.lastName
						? item?.details?.shippingAddress?.lastName
						: item.userId.lastName
				}`,
				email: `${item.userId?.email ? item.userId?.email : "-"}`,
				licNumber: `${
					item.userId?.licNumber ? item.userId.licNumber : "-"
				}`,
				wpId: `${item.wpOrderId ? item.wpOrderId : "-"}`,
				productName: `${
					item.products?.[0]?.productId?.name
						? item.products[0].productId.name
						: "-"
				}`,
				paymentMethod: item.paymentMethod?.name,
				shippingType:
					item?.shippingType === "self-pickup" ||
					item.shippingType === "local-pickup"
						? item?.shippingType
						: item?.shippingMethod?.type,
				action: item,
				orderType: { _id: item?._id, partner: item?.partner ?? null },
			};
		});
	};
	let paymentLink = null;
	if (response?.data?.upgradeDetails?.[0]) {
		paymentLink = response?.data?.upgradeDetails[0].paymentLink;
	}

	return (
		<React.Fragment>
			<div className="page-content">
				<Helmet>
					<title>Order | LYOTECHLABS</title>
				</Helmet>
				<Container fluid>
					{/* Render Breadcrumbs */}
					<Breadcrumbs title="Order" breadcrumbItem="List" />
					<Row>
						<Col lg="12">
							<Card>
								<CardBody>
									<Row>
										<Col xl="12">
											<div className="table-rep-plugin">
												<div className="table-responsive">
													<ReactDataTable
														url={`${apiUrl}/admin/store/orders/order-pagination`}
														columns={columns()}
														resultFormatter={
															resultFormatter
														}
														setRefresh={
															refreshTableData
														}
														disableFilterIcon={
															false
														}
														disableSearchIcon={
															false
														}
														filters={[
															{
																name: "orderType",
																value: ["wewe"],
															},
														]}
														origin={
															<div className="row">
																<div className="col-auto h4">
																	Orders
																	{/* &nbsp;
																	<button
																		onClick={() => {
																			generateCSV();
																		}}
																		type="button"
																		className="btn btn-primary waves-effect waves-light"
																	>
																		Export
																		Orders
																	</button> */}
																</div>
															</div>
														}
														rowsPerPage={10}
													/>
												</div>
											</div>
										</Col>
									</Row>
								</CardBody>
							</Card>
							<Modal
								isOpen={isOpenAddEditModal}
								toggle={handleAddEditModal}
								size="lg"
								centered={true}
							>
								<ModalHeader
									toggle={handleAddEditModal}
									tag="h4"
								>
									Assign details
								</ModalHeader>
								<ModalBody>
									<Form name="assignForm">
										<Row>
											{details?.details?.line_items
												? details.details.line_items.map(
														(product, index) => (
															<Col md="6">
																<Card
																	key={
																		product
																			.data
																			.product_id
																	}
																>
																	<CardHeader>
																		<h5 className="card-title">
																			{
																				product
																					.data
																					.name
																			}
																		</h5>
																		<p className="card-title-desc">
																			{`#${product.data.product_id}`}
																		</p>
																	</CardHeader>
																	<CardBody>
																		{product
																			.categories
																			.slug ===
																		"phone" ? (
																			<Row>
																				<div className="m-2">
																					<Label className="form-label">
																						IMEI
																					</Label>
																					<Input
																						name="imei"
																						type="text"
																						onChange={handleChange(
																							index
																						)}
																						value={
																							dispatchedDetails?.[
																								index
																							]
																								?.imei ||
																							""
																						}
																						required
																					/>
																				</div>
																			</Row>
																		) : (
																			<Row>
																				<div className="m-1">
																					<Select
																						name="macAddress"
																						value={
																							dispatchedDetails?.[
																								index
																							] ||
																							""
																						}
																						placeholder="Select mac address"
																						onChange={(
																							value,
																							action
																						) => {
																							handleChangeMacAddress(
																								index,
																								{
																									...value,
																									productId:
																										product
																											.data
																											.product_id,
																								}
																							);
																						}}
																						options={
																							getMacAddressOptions
																						}
																						classNamePrefix="select2-selection"
																						onSelectResetsInput={
																							true
																						}
																						closeMenuOnSelect={
																							true
																						}
																						required
																					/>
																				</div>

																				<div className="m-1">
																					<Button
																						type={
																							"submit"
																						}
																						className="btn-soft-primary waves-effect"
																						onClick={() => {
																							randomAssignMacAddress(
																								index,
																								product.product_id
																							);
																						}}
																					>
																						Random
																					</Button>
																				</div>
																			</Row>
																		)}
																	</CardBody>
																</Card>
															</Col>
														)
												  )
												: null}
										</Row>

										<Row className="text-sm-end">
											<Col>
												<Button
													className="btn-primary waves-effect"
													onClick={() => {
														dispatchOrder();
													}}
												>
													Dispatch
												</Button>
											</Col>
										</Row>
									</Form>
								</ModalBody>
							</Modal>
							<Modal
								isOpen={isOpenInvoiceModal}
								toggle={() => handleOrderInvoice()}
								size="xl"
								centered={true}
							>
								<ModalHeader
									toggle={() => handleOrderInvoice()}
									tag="h4"
								>
									Invoice Details
								</ModalHeader>
								<ModalBody style={{ height: "80vh" }}>
									<Row>
										<Col xl="12" md="12" lg="12">
											{invoice?.link && (
												<iframe
													src={invoice.link}
													title="PDF"
													style={{
														height: "75vh",
														width: "100%",
													}}
												/>
											)}
										</Col>
									</Row>
								</ModalBody>
							</Modal>

							<Modal
								isOpen={isOpenUpdateMethodOrderModal}
								toggle={() => handleUpdateMethodOrderModal()}
								size="md"
								centered={true}
							>
								<ModalHeader
									toggle={() =>
										handleUpdateMethodOrderModal()
									}
									tag="h4"
								>
									Update Shipping Method
								</ModalHeader>
								<ModalBody>
									<Row>
										<fieldset
										// disabled={
										// 	!couldHaveAddUpdatePermission()
										// }
										>
											<Form
												onSubmit={(e) => {
													e.preventDefault();
													validationChangeMethod.handleSubmit();
													return false;
												}}
											>
												<Row>
													<Col>
														<Card>
															<>
																<ul className="list-group list-group-flush">
																	<li className="list-group-item">
																		<div className="form-check ">
																			<input
																				className="form-check-input"
																				type="radio"
																				name="shippingMethod"
																				id="manualWaybillRadio"
																				value="picked-up"
																				onChange={
																					validationChangeMethod.handleChange
																				}
																				onClick={
																					validationChangeMethod.handleChange
																				}
																				// checked={
																				// 	validationChangeMethod
																				// 		.values
																				// 		.shippingMethod ===
																				// 	"local-pickup"
																				// 		? true
																				// 		: false
																				// }
																				checked
																				defaultChecked
																			/>
																			<label
																				className="form-check-label"
																				htmlFor="formRadios1"
																			>
																				Self
																				Pickup
																			</label>
																		</div>
																	</li>
																	{/* <li className="list-group-item">
																		<div className="form-check">
																			<input
																				className="form-check-input"
																				type="radio"
																				name="shippingMethod"
																				value="shipping"
																				checked={
																					validationChangeMethod
																						.values
																						.shippingMethod ===
																					"shipping"
																						? true
																						: false
																				}
																				onChange={
																					validationChangeMethod.handleChange
																				}
																				onClick={
																					validationChangeMethod.handleChange
																				}
																				id="generateWaybillRadio"
																			/>
																			<label
																				className="form-check-label"
																				htmlFor="formRadios1"
																			>
																				Shipping
																			</label>
																		</div>
																	</li> */}
																</ul>
															</>
														</Card>
													</Col>
												</Row>

												{validationChangeMethod?.values
													?.shippingMethod ===
													"local-pickup" ||
												validationChangeMethod?.values
													?.shippingMethod ===
													"self-pickup" ? (
													<Row form={"true"}>
														<Col xs={12}>
															<div className="mb-3">
																<Label className="form-label">
																	Pickup Date
																</Label>
																<div className="input-group">
																	<Flatpickr
																		className="form-control d-block"
																		placeholder="Pickup Date"
																		name="pickupDate"
																		options={{
																			dateFormat:
																				"m/d/Y",
																			altInput: true,
																			mode: "single",
																		}}
																		value={
																			validationChangeMethod
																				.values
																				.pickupDate ||
																			null
																		}
																		onChange={(
																			date
																		) => {
																			console.log(
																				"🚀 ~ file: index.js:1273 ~ Order ~ date:",
																				date
																			);
																			validationChangeMethod.setFieldValue(
																				"pickupDate",
																				date?.[0] ??
																					null
																			);
																		}}
																	/>
																</div>
																{validationChangeMethod
																	.touched
																	.pickupDate &&
																validationChangeMethod
																	.errors
																	.pickupDate ? (
																	<FormFeedback type="invalid">
																		{
																			validationChangeMethod
																				.errors
																				.pickupDate
																		}
																	</FormFeedback>
																) : null}
															</div>
														</Col>
													</Row>
												) : null}

												<Row>
													<Col>
														<div className="mb-3">
															<Label className="form-label">
																Select pickup
																address
															</Label>
															<Input
																name="pickupAddress"
																type="select"
																onChange={
																	validationChangeMethod.handleChange
																}
																onBlur={
																	validationChangeMethod.handleBlur
																}
																value={
																	validationChangeMethod
																		.values
																		.pickupAddress ||
																	""
																}
																invalid={
																	validationChangeMethod
																		.touched
																		.pickupAddress &&
																	validationChangeMethod
																		.errors
																		.pickupAddress
																		? true
																		: false
																}
															>
																<option
																	disabled
																	value=""
																>
																	Select
																	addresss
																</option>

																<option
																	key={1}
																	value={
																		"701, Emaar Square Building 1, Downtown, Dubai, U.A.E."
																	}
																>
																	{
																		"701, Emaar Square Building 1, Downtown, Dubai, U.A.E."
																	}
																</option>
																{/* <option
																	key={1}
																	value={
																		"701, Emaar Square Building 1, Downtown, Dubai, U.A.E."
																	}
																>
																	{
																		"701, Emaar Square Building 1, Downtown, Dubai, U.A.E."
																	}
																</option> */}
															</Input>

															{validationChangeMethod
																.touched
																.pickupAddress &&
															validationChangeMethod
																.errors
																.pickupAddress ? (
																<FormFeedback type="invalid">
																	{
																		validationChangeMethod
																			.errors
																			.pickupAddress
																	}
																</FormFeedback>
															) : null}
														</div>
													</Col>
												</Row>

												<Row form={"true"}>
													<div>
														<div
															className="form-check form-switch mb-3"
															dir="ltr"
														>
															<input
																type="checkbox"
																className="form-check-input"
																id="customSwitch2"
																name="sendEmail"
																onClick={
																	validation.handleChange
																}
																onChange={
																	validation.handleChange
																}
																defaultChecked
															/>
															<label
																className="form-check-label"
																htmlFor="customSwitch2"
															>
																Send email to
																customer
															</label>
														</div>
													</div>
												</Row>

												<Row>
													<Col>
														<div className="text-end mt-3">
															<button
																type="submit"
																className="btn btn-success save-user"
															>
																Save
															</button>
														</div>
													</Col>
												</Row>
											</Form>
										</fieldset>
									</Row>
								</ModalBody>
							</Modal>

							<Modal
								isOpen={isOpenUpdateAddressOrderModal}
								toggle={() => handleUpdateAddressOrderModal()}
								size="md"
								centered={true}
							>
								<ModalHeader
									toggle={() =>
										handleUpdateAddressOrderModal()
									}
									tag="h4"
								>
									Update Shipping Address
								</ModalHeader>
								<ModalBody>
									{selectedOrder?.details
										?.shippingAddress && (
										<Row>
											<Card className="card-h-100">
												<CardBody>
													<h5 className="card-title me-2">
														Current Address
													</h5>
													<p className="address">
														<span>
															{
																selectedOrder
																	?.details
																	?.shippingAddress
																	?.firstName
															}
														</span>
														&nbsp;{" "}
														<span>
															{
																selectedOrder
																	?.details
																	?.shippingAddress
																	?.lastName
															}
														</span>
														<br />
														<span>
															{
																selectedOrder
																	?.details
																	?.shippingAddress
																	?.address
															}
														</span>
														<br />
														<span>
															{
																selectedOrder
																	?.details
																	?.shippingAddress
																	?.city
															}
														</span>
														, &nbsp;
														<span>
															{
																selectedOrder
																	?.details
																	?.shippingAddress
																	?.country
															}
															, <br />
															<span>
																{" "}
																Postal Code:
																&nbsp;
																{
																	selectedOrder
																		?.details
																		?.shippingAddress
																		?.postalCode
																}
															</span>
														</span>
														<br />
														<span>
															{selectedOrder
																?.details
																?.shippingAddress
																?.countryCode
																? "+"
																: ""}
															{
																selectedOrder
																	?.details
																	?.shippingAddress
																	?.countryCode
															}{" "}
															{
																selectedOrder
																	?.details
																	?.shippingAddress
																	?.phone
															}
														</span>
													</p>
												</CardBody>
											</Card>
										</Row>
									)}

									<Row>
										<fieldset
										// disabled={
										// 	!couldHaveAddUpdatePermission()
										// }
										>
											<Form
												onSubmit={(e) => {
													e.preventDefault();
													validation.handleSubmit();
													return false;
												}}
											>
												<Row form={"true"}>
													<Col xs={6}>
														<div className="mb-3">
															<Label className="form-label">
																First Name
															</Label>
															<Input
																name="firstName"
																type="text"
																onChange={
																	validation.handleChange
																}
																onBlur={
																	validation.handleBlur
																}
																value={
																	validation
																		.values
																		.firstName ||
																	""
																}
																invalid={
																	validation
																		.touched
																		.firstName &&
																	validation
																		.errors
																		.firstName
																		? true
																		: false
																}
															/>
															{validation.touched
																.firstName &&
															validation.errors
																.firstName ? (
																<FormFeedback type="invalid">
																	{
																		validation
																			.errors
																			.firstName
																	}
																</FormFeedback>
															) : null}
														</div>
													</Col>
													<Col xs={6}>
														<div className="mb-3">
															<Label className="form-label">
																Last Name
															</Label>
															<Input
																name="lastName"
																type="text"
																onChange={
																	validation.handleChange
																}
																onBlur={
																	validation.handleBlur
																}
																value={
																	validation
																		.values
																		.lastName ||
																	""
																}
																invalid={
																	validation
																		.touched
																		.lastName &&
																	validation
																		.errors
																		.lastName
																		? true
																		: false
																}
															/>
															{validation.touched
																.lastName &&
															validation.errors
																.lastName ? (
																<FormFeedback type="invalid">
																	{
																		validation
																			.errors
																			.lastName
																	}
																</FormFeedback>
															) : null}
														</div>
													</Col>
												</Row>
												<Row form={"true"}>
													<div>
														<div
															className="form-check form-switch mb-3"
															dir="ltr"
														>
															<input
																type="checkbox"
																className="form-check-input"
																id="customSwitch1"
																name="sendEmailName"
																onClick={
																	validation.handleChange
																}
																onChange={
																	validation.handleChange
																}
															/>
															<label
																className="form-check-label"
																htmlFor="customSwitch1"
															>
																Send email to
																customer about
																Name update
															</label>
														</div>
													</div>
												</Row>
												<Row form={"true"}>
													<Col xs={12}>
														<div className="mb-3">
															<Label className="form-label">
																Phone
															</Label>
															<div className="d-flex gap-1">
																<div className="w-50">
																	<Select
																		name="countryCode"
																		isDisabled={
																			details?._id
																				? true
																				: false
																		}
																		value={
																			validation
																				.values
																				.countryCode ||
																			""
																		}
																		onChange={(
																			value,
																			action
																		) => {
																			validation.setFieldValue(
																				"countryCode",
																				value
																			);
																		}}
																		options={
																			countryCodeDropdown
																		}
																		classNamePrefix="select2-selection"
																		onSelectResetsInput={
																			true
																		}
																		closeMenuOnSelect={
																			true
																		}
																		components={
																			animatedComponents
																		}
																		required
																	/>
																</div>
																<Input
																	name="phone"
																	type="text"
																	onChange={
																		validation.handleChange
																	}
																	onBlur={
																		validation.handleBlur
																	}
																	value={
																		validation
																			.values
																			.phone ||
																		""
																	}
																	invalid={
																		validation
																			.touched
																			.phone &&
																		validation
																			.errors
																			.phone
																			? true
																			: false
																	}
																/>
															</div>
															{validation.touched
																.countryCode &&
															validation.errors
																.countryCode ? (
																<FormFeedback
																	className="error"
																	type="invalid"
																>
																	{
																		validation
																			.errors
																			.countryCode
																	}
																</FormFeedback>
															) : null}
															{validation.touched
																.phone &&
															validation.errors
																.phone ? (
																<FormFeedback type="invalid">
																	{
																		validation
																			.errors
																			.phone
																	}
																</FormFeedback>
															) : null}
														</div>
													</Col>
												</Row>
												<Row form={"true"}>
													<div>
														<div
															className="form-check form-switch mb-3"
															dir="ltr"
														>
															<input
																type="checkbox"
																className="form-check-input"
																id="customSwitch1"
																name="sendEmailPhone"
																onClick={
																	validation.handleChange
																}
																onChange={
																	validation.handleChange
																}
															/>
															<label
																className="form-check-label"
																htmlFor="customSwitch1"
															>
																Send email to
																customer about
																Phone update
															</label>
														</div>
													</div>
												</Row>

												<Row form={"true"}>
													<Col xs={12}>
														<div className="mb-3">
															<Label className="form-label">
																Address
															</Label>
															<Input
																name="address"
																type="textarea"
																onChange={
																	validation.handleChange
																}
																onBlur={
																	validation.handleBlur
																}
																value={
																	validation
																		.values
																		.address ||
																	""
																}
																invalid={
																	validation
																		.touched
																		.address &&
																	validation
																		.errors
																		.address
																		? true
																		: false
																}
															/>
															{validation.touched
																.address &&
															validation.errors
																.address ? (
																<FormFeedback type="invalid">
																	{
																		validation
																			.errors
																			.address
																	}
																</FormFeedback>
															) : null}
														</div>
													</Col>
												</Row>

												<Row form={"true"}>
													<Col xs={12}>
														<div className="mb-3">
															<Label className="form-label">
																City
															</Label>
															<Input
																name="city"
																type="text"
																onChange={
																	validation.handleChange
																}
																onBlur={
																	validation.handleBlur
																}
																value={
																	validation
																		.values
																		.city ||
																	""
																}
																invalid={
																	validation
																		.touched
																		.city &&
																	validation
																		.errors
																		.city
																		? true
																		: false
																}
															/>
															{validation.touched
																.city &&
															validation.errors
																.city ? (
																<FormFeedback type="invalid">
																	{
																		validation
																			.errors
																			.city
																	}
																</FormFeedback>
															) : null}
														</div>
													</Col>
												</Row>

												<Row form={"true"}>
													<Col xs={12}>
														<div className="mb-3">
															<Label className="form-label">
																Postal Code
															</Label>
															<Input
																name="postalCode"
																type="text"
																onChange={
																	validation.handleChange
																}
																onBlur={
																	validation.handleBlur
																}
																value={
																	validation
																		.values
																		.postalCode ||
																	""
																}
																invalid={
																	validation
																		.touched
																		.postalCode &&
																	validation
																		.errors
																		.postalCode
																		? true
																		: false
																}
															/>
															{validation.touched
																.postalCode &&
															validation.errors
																.postalCode ? (
																<FormFeedback type="invalid">
																	{
																		validation
																			.errors
																			.postalCode
																	}
																</FormFeedback>
															) : null}
														</div>
													</Col>
												</Row>

												<Row form={"true"}>
													<Col xs={12}>
														<div className="mb-3">
															<Label className="form-label">
																Select country
															</Label>
															<Select
																name="country"
																isDisabled={
																	details?._id
																		? true
																		: false
																}
																value={
																	validation
																		.values
																		.country ||
																	""
																}
																placeholder="Select country"
																onChange={(
																	value,
																	action
																) => {
																	validation.setFieldValue(
																		"country",
																		value
																	);
																}}
																options={
																	countryOptionDropdown
																}
																classNamePrefix="select2-selection"
																onSelectResetsInput={
																	true
																}
																closeMenuOnSelect={
																	true
																}
																components={
																	animatedComponents
																}
																required
															/>
															{validation.touched
																.country &&
															validation.errors
																.country ? (
																<FormFeedback
																	className="error"
																	type="invalid"
																>
																	{
																		validation
																			.errors
																			.country
																	}
																</FormFeedback>
															) : null}
														</div>
													</Col>
												</Row>

												<Row form={"true"}>
													<div>
														<div
															className="form-check form-switch mb-3"
															dir="ltr"
														>
															<input
																type="checkbox"
																className="form-check-input"
																id="customSwitch1"
																name="sendEmailAddress"
																onClick={
																	validation.handleChange
																}
																onChange={
																	validation.handleChange
																}
															/>
															<label
																className="form-check-label"
																htmlFor="customSwitch1"
															>
																Send email to
																customer about
																Address update
															</label>
														</div>
													</div>
												</Row>

												<Row>
													<Col>
														<div className="text-end mt-3">
															<button
																type="submit"
																className="btn btn-success save-user"
															>
																Save
															</button>
														</div>
													</Col>
												</Row>
											</Form>
										</fieldset>
									</Row>
								</ModalBody>
							</Modal>

							<Modal
								isOpen={isOpenUpdateBillingAddressModal}
								toggle={() => handleUpdateBillingAddressModal()}
								size="md"
								centered={true}
							>
								<ModalHeader
									toggle={() =>
										handleUpdateBillingAddressModal()
									}
									tag="h4"
								>
									Update Billing Address
								</ModalHeader>
								<ModalBody>
									{selectedOrder?.details?.billingAddress && (
										<Row>
											<Card className="card-h-100">
												<CardBody>
													<h5 className="card-title me-2">
														Current Address
													</h5>
													<p className="address">
														<span>
															{
																selectedOrder
																	?.details
																	?.billingAddress
																	?.firstName
															}
														</span>
														&nbsp;{" "}
														<span>
															{
																selectedOrder
																	?.details
																	?.billingAddress
																	?.lastName
															}
														</span>
														<br />
														<span>
															{
																selectedOrder
																	?.details
																	?.billingAddress
																	?.address
															}
														</span>
														<br />
														<span>
															{
																selectedOrder
																	?.details
																	?.billingAddress
																	?.city
															}
														</span>
														, &nbsp;
														<span>
															{
																selectedOrder
																	?.details
																	?.billingAddress
																	?.country
															}
															, <br />
															<span>
																{" "}
																Postal Code:
																&nbsp;
																{
																	selectedOrder
																		?.details
																		?.billingAddress
																		?.postalCode
																}
															</span>
														</span>
														<br />
														<span>
															{selectedOrder
																?.details
																?.billingAddress
																?.countryCode
																? "+"
																: ""}
															{
																selectedOrder
																	?.details
																	?.billingAddress
																	?.countryCode
															}{" "}
															{
																selectedOrder
																	?.details
																	?.billingAddress
																	?.phone
															}
														</span>
													</p>
												</CardBody>
											</Card>
										</Row>
									)}

									<Row>
										<fieldset
										// disabled={
										// 	!couldHaveAddUpdatePermission()
										// }
										>
											<Form
												onSubmit={(e) => {
													e.preventDefault();
													validationBillingAddress.handleSubmit();
													return false;
												}}
											>
												<Row form={"true"}>
													<Col xs={6}>
														<div className="mb-3">
															<Label className="form-label">
																First Name
															</Label>
															<Input
																name="firstName"
																type="text"
																onChange={
																	validationBillingAddress.handleChange
																}
																onBlur={
																	validationBillingAddress.handleBlur
																}
																value={
																	validationBillingAddress
																		.values
																		.firstName ||
																	""
																}
																invalid={
																	validationBillingAddress
																		.touched
																		.firstName &&
																	validationBillingAddress
																		.errors
																		.firstName
																		? true
																		: false
																}
															/>
															{validationBillingAddress
																.touched
																.firstName &&
															validationBillingAddress
																.errors
																.firstName ? (
																<FormFeedback type="invalid">
																	{
																		validationBillingAddress
																			.errors
																			.firstName
																	}
																</FormFeedback>
															) : null}
														</div>
													</Col>
													<Col xs={6}>
														<div className="mb-3">
															<Label className="form-label">
																Last Name
															</Label>
															<Input
																name="lastName"
																type="text"
																onChange={
																	validationBillingAddress.handleChange
																}
																onBlur={
																	validationBillingAddress.handleBlur
																}
																value={
																	validationBillingAddress
																		.values
																		.lastName ||
																	""
																}
																invalid={
																	validationBillingAddress
																		.touched
																		.lastName &&
																	validationBillingAddress
																		.errors
																		.lastName
																		? true
																		: false
																}
															/>
															{validationBillingAddress
																.touched
																.lastName &&
															validationBillingAddress
																.errors
																.lastName ? (
																<FormFeedback type="invalid">
																	{
																		validationBillingAddress
																			.errors
																			.lastName
																	}
																</FormFeedback>
															) : null}
														</div>
													</Col>
												</Row>
												<Row form={"true"}>
													<Col xs={12}>
														<div className="mb-3">
															<Label className="form-label">
																Phone
															</Label>
															<div className="d-flex gap-1">
																<div className="w-50">
																	<Select
																		name="countryCode"
																		isDisabled={
																			details?._id
																				? true
																				: false
																		}
																		value={
																			validationBillingAddress
																				.values
																				.countryCode ||
																			""
																		}
																		onChange={(
																			value,
																			action
																		) => {
																			validationBillingAddress.setFieldValue(
																				"countryCode",
																				value
																			);
																		}}
																		options={
																			countryCodeDropdown
																		}
																		classNamePrefix="select2-selection"
																		onSelectResetsInput={
																			true
																		}
																		closeMenuOnSelect={
																			true
																		}
																		components={
																			animatedComponents
																		}
																		required
																	/>
																</div>
																<Input
																	name="phone"
																	type="text"
																	onChange={
																		validationBillingAddress.handleChange
																	}
																	onBlur={
																		validationBillingAddress.handleBlur
																	}
																	value={
																		validationBillingAddress
																			.values
																			.phone ||
																		""
																	}
																	invalid={
																		validationBillingAddress
																			.touched
																			.phone &&
																		validationBillingAddress
																			.errors
																			.phone
																			? true
																			: false
																	}
																/>
															</div>
															{validationBillingAddress
																.touched
																.countryCode &&
															validationBillingAddress
																.errors
																.countryCode ? (
																<FormFeedback
																	className="error"
																	type="invalid"
																>
																	{
																		validationBillingAddress
																			.errors
																			.countryCode
																	}
																</FormFeedback>
															) : null}
															{validationBillingAddress
																.touched
																.phone &&
															validationBillingAddress
																.errors
																.phone ? (
																<FormFeedback type="invalid">
																	{
																		validationBillingAddress
																			.errors
																			.phone
																	}
																</FormFeedback>
															) : null}
														</div>
													</Col>
												</Row>

												<Row form={"true"}>
													<Col xs={12}>
														<div className="mb-3">
															<Label className="form-label">
																Address
															</Label>
															<Input
																name="address"
																type="textarea"
																onChange={
																	validationBillingAddress.handleChange
																}
																onBlur={
																	validationBillingAddress.handleBlur
																}
																value={
																	validationBillingAddress
																		.values
																		.address ||
																	""
																}
																invalid={
																	validationBillingAddress
																		.touched
																		.address &&
																	validationBillingAddress
																		.errors
																		.address
																		? true
																		: false
																}
															/>
															{validationBillingAddress
																.touched
																.address &&
															validationBillingAddress
																.errors
																.address ? (
																<FormFeedback type="invalid">
																	{
																		validationBillingAddress
																			.errors
																			.address
																	}
																</FormFeedback>
															) : null}
														</div>
													</Col>
												</Row>

												<Row form={"true"}>
													<Col xs={12}>
														<div className="mb-3">
															<Label className="form-label">
																City
															</Label>
															<Input
																name="city"
																type="text"
																onChange={
																	validationBillingAddress.handleChange
																}
																onBlur={
																	validationBillingAddress.handleBlur
																}
																value={
																	validationBillingAddress
																		.values
																		.city ||
																	""
																}
																invalid={
																	validationBillingAddress
																		.touched
																		.city &&
																	validationBillingAddress
																		.errors
																		.city
																		? true
																		: false
																}
															/>
															{validationBillingAddress
																.touched.city &&
															validationBillingAddress
																.errors.city ? (
																<FormFeedback type="invalid">
																	{
																		validationBillingAddress
																			.errors
																			.city
																	}
																</FormFeedback>
															) : null}
														</div>
													</Col>
												</Row>

												<Row form={"true"}>
													<Col xs={12}>
														<div className="mb-3">
															<Label className="form-label">
																Postal Code
															</Label>
															<Input
																name="postalCode"
																type="text"
																onChange={
																	validationBillingAddress.handleChange
																}
																onBlur={
																	validationBillingAddress.handleBlur
																}
																value={
																	validationBillingAddress
																		.values
																		.postalCode ||
																	""
																}
																invalid={
																	validationBillingAddress
																		.touched
																		.postalCode &&
																	validationBillingAddress
																		.errors
																		.postalCode
																		? true
																		: false
																}
															/>
															{validationBillingAddress
																.touched
																.postalCode &&
															validationBillingAddress
																.errors
																.postalCode ? (
																<FormFeedback type="invalid">
																	{
																		validationBillingAddress
																			.errors
																			.postalCode
																	}
																</FormFeedback>
															) : null}
														</div>
													</Col>
												</Row>

												<Row form={"true"}>
													<Col xs={12}>
														<div className="mb-3">
															<Label className="form-label">
																Select country
															</Label>
															<Select
																name="country"
																isDisabled={
																	details?._id
																		? true
																		: false
																}
																value={
																	validationBillingAddress
																		.values
																		.country ||
																	""
																}
																placeholder="Select country"
																onChange={(
																	value,
																	action
																) => {
																	validationBillingAddress.setFieldValue(
																		"country",
																		value
																	);
																}}
																options={
																	countryOptionDropdown
																}
																classNamePrefix="select2-selection"
																onSelectResetsInput={
																	true
																}
																closeMenuOnSelect={
																	true
																}
																components={
																	animatedComponents
																}
																required
															/>
															{validationBillingAddress
																.touched
																.country &&
															validationBillingAddress
																.errors
																.country ? (
																<FormFeedback
																	className="error"
																	type="invalid"
																>
																	{
																		validationBillingAddress
																			.errors
																			.country
																	}
																</FormFeedback>
															) : null}
														</div>
													</Col>
												</Row>

												<Row>
													<Col>
														<div className="text-end mt-3">
															<button
																type="submit"
																className="btn btn-success save-user"
															>
																Save
															</button>
														</div>
													</Col>
												</Row>
											</Form>
										</fieldset>
									</Row>
								</ModalBody>
							</Modal>

							<Modal
								isOpen={isOpenUpgradeOrderModal}
								toggle={() => handleUpgradeOrderModal()}
								size="lg"
								centered={true}
							>
								<ModalHeader
									toggle={() => handleUpgradeOrderModal()}
									tag="h4"
								>
									Payment Link
								</ModalHeader>
								<ModalBody>
									<Row>
										{paymentLink && (
											<div className="text-nowrap">
												<CopyToClipboard
													data-tooltip-content={
														paymentLink
													}
													id={"lastNineChara"}
													text={paymentLink}
													style={{
														cursor: "pointer",
													}}
													onCopy={() =>
														handleTooltipOpen()
													}
												>
													<h5
														style={{
															cursor: "pointer",
															texSize: "50",
														}}
													>
														{`${paymentLink}       `}
														<i className="mdi mdi-content-copy"></i>
													</h5>
												</CopyToClipboard>

												<ReactTooltip
													anchorId={"lastNineChara"}
													variant="info"
													place="bottom"
													style={{
														zIndex: 2147483647,
													}}
												/>
											</div>
										)}
									</Row>
								</ModalBody>
							</Modal>

							<Modal
								isOpen={isOpenUpdateOrderStatusModal}
								toggle={() => handleUpdateOrderStatusModal()}
								size="md"
								centered={true}
							>
								<ModalHeader
									toggle={() =>
										handleUpdateOrderStatusModal()
									}
									tag="h4"
								>
									Update Order Status
								</ModalHeader>
								<ModalBody>
									<Row>
										<fieldset>
											<Form
												onSubmit={(e) => {
													e.preventDefault();
													validationChangeStatusMethod.handleSubmit();
													return false;
												}}
											>
												<Row>
													<Col xs={12}>
														<div className="mb-3">
															<Label className="form-label">
																Select Status
															</Label>
															<Select
																name="status"
																className="text-capitalize"
																// value={
																// 	validationChangeStatusMethod
																// 		.values
																// 		.status ||
																// 	""
																// }
																placeholder="Select status"
																onChange={(
																	value,
																	action
																) => {
																	validationChangeStatusMethod.setFieldValue(
																		"status",
																		value
																	);
																}}
																options={
																	getStatuses
																}
																classNamePrefix="select2-selection"
																onSelectResetsInput={
																	false
																}
																closeMenuOnSelect={
																	true
																}
																components={
																	animatedComponents
																}
																required
															/>
															{validationChangeStatusMethod
																.touched
																.status &&
															validationChangeStatusMethod
																.errors
																.status ? (
																<FormFeedback
																	className="error"
																	type="invalid"
																>
																	{
																		validationChangeStatusMethod
																			.errors
																			.status
																	}
																</FormFeedback>
															) : null}
														</div>
													</Col>
												</Row>

												{selectedOrder?.status ===
													"processing" && (
													<Row>
														<Col xs={12}>
															<div className="mb-3">
																<Label className="form-label">
																	Serial
																	Number{" "}
																</Label>
																<Input
																	name="sn"
																	type="text"
																	onChange={
																		validationChangeStatusMethod.handleChange
																	}
																	onBlur={
																		validationChangeStatusMethod.handleBlur
																	}
																	value={
																		validationChangeStatusMethod
																			.values
																			.sn ||
																		""
																	}
																	invalid={
																		validationChangeStatusMethod
																			.touched
																			.sn &&
																		validationChangeStatusMethod
																			.errors
																			.sn
																			? true
																			: false
																	}
																/>
																{validationChangeStatusMethod
																	.touched
																	.sn &&
																validationChangeStatusMethod
																	.errors
																	.sn ? (
																	<FormFeedback type="invalid">
																		{
																			validationChangeStatusMethod
																				.errors
																				.sn
																		}
																	</FormFeedback>
																) : null}
															</div>
														</Col>
													</Row>
												)}
												{selectedOrder?.status ===
													"dispatched" && (
													<Row form={"true"}>
														<div>
															<div
																className="form-check form-switch mb-3"
																dir="ltr"
															>
																<input
																	type="checkbox"
																	className="form-check-input"
																	id="customSwitch2"
																	name="sendEmail"
																	onClick={
																		validationChangeStatusMethod.handleChange
																	}
																	onChange={
																		validationChangeStatusMethod.handleChange
																	}
																/>
																<label
																	className="form-check-label"
																	htmlFor="customSwitch2"
																>
																	Send email
																	to customer
																</label>
															</div>
														</div>
													</Row>
												)}
												<Row>
													<Col>
														<div className="text-end mt-3">
															<button
																type="submit"
																className="btn btn-success save-user"
															>
																Save
															</button>
														</div>
													</Col>
												</Row>
											</Form>
										</fieldset>
									</Row>
								</ModalBody>
							</Modal>

							<Modal
								isOpen={isOpenEditShippingDetailsModal}
								toggle={handleEditShippingDetailsModal}
								size="lg"
								centered={true}
							>
								<ModalHeader
									toggle={handleEditShippingDetailsModal}
									tag="h4"
								>
									Edit Shipping Details
								</ModalHeader>
								<ModalBody>
									<Form
										onSubmit={(e) => {
											e.preventDefault();
											validationEditShippedDetails.handleSubmit();
											return false;
										}}
										name="assignForm"
									>
										<Row>
											<Col xs={6}>
												<div className="mb-3">
													<Label className="form-label">
														Tracking Number{" "}
													</Label>
													<Input
														name="trackingNumber"
														type="text"
														onChange={
															validationEditShippedDetails.handleChange
														}
														onBlur={
															validationEditShippedDetails.handleBlur
														}
														value={
															validationEditShippedDetails
																.values
																.trackingNumber ||
															shippingDetails?.trackingNumber
														}
														invalid={
															validationEditShippedDetails
																.touched
																.trackingNumber &&
															validationEditShippedDetails
																.errors
																.trackingNumber
																? true
																: false
														}
													/>
													{validationEditShippedDetails
														.touched
														.trackingNumber &&
													validationEditShippedDetails
														.errors
														.trackingNumber ? (
														<FormFeedback type="invalid">
															{
																validationEditShippedDetails
																	.errors
																	.trackingNumber
															}
														</FormFeedback>
													) : null}
												</div>
											</Col>
											<Col xs={6}>
												<div className="mb-3">
													<Label className="form-label">
														Serial Number{" "}
													</Label>
													<Input
														name="sn"
														type="text"
														onChange={
															validationEditShippedDetails.handleChange
														}
														onBlur={
															validationEditShippedDetails.handleBlur
														}
														value={
															validationEditShippedDetails
																.values.sn ||
															linkedDeviceDetails
																?.deviceDetail
																?.sn
														}
														invalid={
															validationEditShippedDetails
																.touched.sn &&
															validationEditShippedDetails
																.errors.sn
																? true
																: false
														}
													/>
													{validationEditShippedDetails
														.touched.sn &&
													validationEditShippedDetails
														.errors.sn ? (
														<FormFeedback type="invalid">
															{
																validationEditShippedDetails
																	.errors.sn
															}
														</FormFeedback>
													) : null}
												</div>
											</Col>
										</Row>
										<Row className="text-sm-end">
											<Col>
												<Button className="btn-primary waves-effect">
													Save
												</Button>
											</Col>
										</Row>
									</Form>
								</ModalBody>
							</Modal>
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default Order;
