export const paymentMethodoOptions = {
	LYOMERCHANT: "LYOMERCHANT (Crypto)",
	stripe: "Stripe",
	Mastercard: "Mastercard",
};
export const orderStatusOptions = {
	cancelled: "Cancelled",
	pending: "Pending",
	hold: "Hold",
	processing: "Processing",
	"self-pickup": "Self-pickup",
	"picked-up": "Picked-up",
	scheduled: "Scheduled",
	shipped: "Shipped",
	dispatched: "Dispatched",
	delivered: "Delivered",
	delivered: "Delivered",
	"label-created": "Label Created",
};

export const presaleShippingStatusOptions = {
	processing: "Processing",
	"ready-to-ship": "Ready to ship",
	hold: "Hold",
	shipped: "Shipped",
	"label-created": "Label Created",
};
export const addressChangeStatusOptions = {
	pending: "Pending",
	completed: "Completed",
};

export const orderType = {
	cloudx: "CloudX",
	wewe: "WEWE",
};

export const performedActionsOptions = {
	Name: "Name",
	Phone: "Phone",
	Address: "Address",
};

export const addressTypes = {
	billing: "Billing",
	shipping: "Shipping",
};
